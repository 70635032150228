import { nodeInfo as outputNodeInfo } from './nodeOutput/NodeOutput';
import { nodeInfo as vdSearchNodeInfo } from './nodeVDSearch/NodeVDSearch';
import { nodeInfo as akFaceswapNodeInfo } from './nodeAkFaceswap/NodeAkFaceswap';
import { nodeInfo as oaiLLMNodeInfo } from './nodeOpenAILLM/NodeOpenAILLM';
import { nodeInfo as wenxinyiyanNodeInfo } from './nodeWenXinYiYanLLM/NodeWenXinYiYanLLM';
import { nodeInfo as resourceLoaderNodeInfo } from './nodeResourceLoader/NodeResourceLoader';
import { nodeInfo as textToAudioNodeInfo } from './nodeTextToAudio/NodeTextToAudio';
import { nodeInfo as audioToTextNodeInfo } from './nodeAudioToText/NodeAudioToText';
import { nodeInfo as openAIDALLENodeInfo } from './nodeOpenAIDALLE/NodeOpenAIDALLE';
import { nodeInfo as vdChatHistoryNodeInfo } from './nodeCHSearch/NodeCHSearch';
import { nodeInfo as callNodeInfo } from './nodeCall/NodeCall';
// import { nodeInfo as smsNodeInfo } from './nodeSMS/NodeSMS';
import { nodeInfo as googleSearchNodeInfo } from './nodeGoogleSearch/NodeGoogleSearch';
import { nodeInfo as vdYoutubeSearchNodeInfo } from './nodeYoutubeSearch/NodeYoutubeSearch';
import { nodeInfo as imageToTextNodeInfo } from './nodeImageToText/NodeImageToText';
// import { nodeInfo as botsquareNodeInfo } from './nodeBSLLM/NodeBSLLM';
import { nodeInfo as blandCallNodeInfo } from './nodeBlandCall/NodeBlandCall';
import { nodeInfo as baiduCallNodeInfo } from './nodeBaiduCall/NodeBaiduCall';
import { nodeInfo as logicNodeInfo } from './nodeLogic/NodeLogic';

import NodeCategory from './NodeCategory';

export enum NodeDataModal {
    text='text',
    image='image',
    audio='audio'
};

export interface NodeInfo {
    id: string,
    name: string,
    description: string,
    iconFile: string,
    color: string,
    docUrl: string,
    category: NodeCategory
}

const startNodeInfo: NodeInfo = {
    id: 'start',
    name: 'Start',
    description: '',
    iconFile: '',
    color: '#2B3674',
    docUrl: '',
    category: NodeCategory.input
};

// const NodeManifest: NodeInfo[] = [startNodeInfo, outputNodeInfo, vdSearchNodeInfo, akFaceswapNodeInfo, oaiLLMNodeInfo, wenxinyiyanNodeInfo, resourceLoaderNodeInfo, textToAudioNodeInfo, audioToTextNodeInfo, openAIDALLENodeInfo, vdChatHistoryNodeInfo, callNodeInfo, smsNodeInfo, googleSearchNodeInfo, vdYoutubeSearchNodeInfo, imageToTextNodeInfo, botsquareNodeInfo, blandCallNodeInfo, logicNodeInfo];
const NodeManifest: NodeInfo[] = [startNodeInfo, outputNodeInfo, vdSearchNodeInfo, akFaceswapNodeInfo, oaiLLMNodeInfo, wenxinyiyanNodeInfo, resourceLoaderNodeInfo, textToAudioNodeInfo, audioToTextNodeInfo, openAIDALLENodeInfo, vdChatHistoryNodeInfo, callNodeInfo, googleSearchNodeInfo, vdYoutubeSearchNodeInfo, imageToTextNodeInfo, blandCallNodeInfo, baiduCallNodeInfo, logicNodeInfo];

export enum NodeType {
    start = 'start',
    output = 'out',
    oaiLLM = 'oai-llm',
    vdSearch = 'vd-s',
    akFaceswap = 'ak-fs',
    resourceLoader = 'res-ld',
    textToAudio = 'txt-aud',
    audioToText = 'aud-txt',
    oaiDALLE = 'oai-dalle',
    vdChatHistory = 'vd-bc',
    call = 'tw-c',
    sms = 'tw-sms',
    googleSearch = 'google-search',
    youtubeSearch = 'vd-yt',
    imageToText = 'img-txt',
    bsLLM = 'bsllm',
    blC = 'bl-c',
    wenxinyiyanLLM = 'wenxinyiyanllm',
    logic = 'bs-logic'
}

export default NodeManifest;
