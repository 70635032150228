import React from 'react';

const TestRunLogo: React.FC = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
            <path d='M16.1901 13.4608C15.9575 13.2281 15.6611 13.0695 15.3385 13.005L13.3493 12.6075C12.259 12.3895 11.1271 12.5411 10.1326 13.0383L9.86762 13.17C8.87312 13.6673 7.74125 13.8189 6.65095 13.6008L5.04178 13.2792C4.77278 13.2254 4.49468 13.2389 4.23214 13.3184C3.96961 13.3979 3.73076 13.541 3.53678 13.735M6.66678 3.9375H13.3334L12.5001 4.77083V9.08083C12.5002 9.52282 12.6759 9.94668 12.9885 10.2592L17.1551 14.4258C18.2051 15.4758 17.4609 17.2708 15.9759 17.2708H4.02345C2.53845 17.2708 1.79512 15.4758 2.84512 14.4258L7.01178 10.2592C7.32436 9.94668 7.50002 9.52282 7.50012 9.08083V4.77083L6.66678 3.9375Z' stroke='#2B3674' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
    );
};

export default TestRunLogo;