import React, { useRef, useState } from 'react';
import ReactModal from 'react-modal';
import './AddDSStringForm.scss';
import { dataSourceAddString, dataSourceAddStringText, dataSourceAddStringURL, dataSourceAddFileWithAI } from '../../services/BSCore';
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import GeneralAlert, { AlertType } from '../dialog/GeneralAlert';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import BSToolTip from '../toolTip/TooTip';

interface Props {
    isOpen: boolean;
    uploading: (datasourceId: string, taskId: string, status: string) => void;
    onClose?: () => void;
}

enum StringChoice {
    none, file, url, text
}

const AddDSStringForm: React.FC<Props> = ({ isOpen, uploading, onClose }) => {
    const { ds_id, bot_id } = useParams();
    const [showLoading, setShowLoading] = useState(false);
    const [choice, setChoice] = useState<StringChoice>(StringChoice.file);
    const [text, setText] = useState('');
    const [url, setURL] = useState('');
    const [file, setFile] = useState<File>();
    const [showTokenAlert, setShowTokenAlert] = useState(false);
    const [enableAIProcessing, setEnableAIProcessing] = useState(false);
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileUpload = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            console.log('have file', file);
            setFile(file);
        }
    };

    const handleClose = () => {
        setFile(undefined);
        setURL('');
        setText('');
        setShowLoading(false);
        onClose?.();
    };

    const isEnable = () => {
        return (choice === StringChoice.url && url !== '') || (choice === StringChoice.file && file !== undefined) || (choice === StringChoice.text && text !== '');
    };

    const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            const type = event.dataTransfer.files[0].type;

            if (type === 'text/plain' || type === 'application/pdf') {
                // at least one file has been dropped so do something
                // handleFiles(e.dataTransfer.files);
                setFile(event.dataTransfer.files[0]);
            } else {
                alert('File type not supported, please choose a txt or PDF only.');
            }
        }
    };

    const removeFile = () => {
        setFile(undefined);
    };

    const handleURLChange = (event: { target: { value: any; }; }) => {
        setURL(event.target.value);
    };

    const handleTextChange = (event: { target: { value: any; }; }) => {
        setText(event.target.value);
    };

    const submitString = async () => {
        try {
            console.log('submit ?', ds_id, bot_id);

            if (!isEnable()) {
                console.log('not enabled ');
                return;
            }

            if (ds_id) {
                if (choice === StringChoice.file && file) {
                    setShowLoading(true);
                    let data;
                    if (enableAIProcessing) {
                        data = await dataSourceAddFileWithAI(ds_id, file);
                    } else {
                        data = await dataSourceAddString(ds_id, file);
                    }
                    console.log(data);
                    handleClose();

                    uploading(ds_id, data.id, 'INIT');
                }

                if (choice === StringChoice.url && url !== '') {
                    setShowLoading(true);
                    const data = await dataSourceAddStringURL(ds_id, url);
                    console.log(data);
                    handleClose();

                    uploading(ds_id, data.id, 'INIT');
                }

                if (choice === StringChoice.text && text !== '') {
                    setShowLoading(true);
                    const data = await dataSourceAddStringText(ds_id, text);
                    console.log(data);
                    handleClose();

                    uploading(ds_id, data.id, 'INIT');
                }
            }
        } catch (e: any) {
            if (e?.response?.status === 402) {
                console.error('Insufficient token balance');
                handleClose();
                setShowTokenAlert(true);
            }
        }
    };

    return (
        <>
            <ReactModal className='add-ds-sting-container' overlayClassName='add-ds-sting-overlay' isOpen={isOpen} onRequestClose={handleClose}>
                <div className='add-ds-sting-back-container'>
                    <i className='bi bi-x-lg wsForm-back' onClick={handleClose}></i>
                </div>
                <div className='add-ds-sting-form'>
                    <h1 className='text-main'>Add String</h1>

                    <label className='auth-text text-main'>Please select a method to upload a string...</label>

                    <form>
                        <div className='radio'>
                            <label className={`${choice === StringChoice.file ? 'selected' : 'unselected'}`}>
                                <input type='radio' onClick={() => setChoice(StringChoice.file)} checked={choice === StringChoice.file} disabled={file !== undefined} />
                                <h1>Upload a File</h1>
                            </label>
                        </div>
                        <div className='radio'>
                            <label className={`${choice === StringChoice.url ? 'selected' : 'unselected'}`}>
                                <input type='radio' onClick={() => setChoice(StringChoice.url)} checked={choice === StringChoice.url} disabled={file !== undefined} />
                                <h1>URL</h1>
                            </label>
                        </div>
                        <div className='radio'>
                            <label className={`${choice === StringChoice.text ? 'selected' : 'unselected'}`}>
                                <input type='radio' onClick={() => setChoice(StringChoice.text)} checked={choice === StringChoice.text} disabled={file !== undefined} />
                                <h1>Text</h1>
                            </label>
                        </div>
                    </form>

                    {file === undefined &&
                        <>
                            {choice === StringChoice.file &&
                                <>
                                    <div
                                        className='add-ds-string-drag-area'
                                        onClick={handleFileUpload}
                                        onDragEnter={handleDrag}
                                        onDragLeave={handleDrag}
                                        onDragOver={handleDrag}
                                        onDrop={handleDrop}
                                    >
                                        <div className='add-ds-string-drag-area-empty'>
                                            <i className='bi bi-cloud-upload' />
                                            <h1>Click or drag a file to upload here...</h1>
                                            <h1 className='add-ds-string-drag-area-file-types'>{
                                                enableAIProcessing ?
                                                '.pdf' :
                                                '.jpg .png .csv .doc .docx .epub .odt .pdf .ppt .pptx .tsv .xlsx .xls .eml, .html .json .md .msg .rst .rtf .txt .xml'
                                            }</h1>
                                        </div>
                                    </div>
                                    <input
                                        type='file'
                                        accept={enableAIProcessing ? '.pdf' : '.jpg, .png, .csv, .doc, .docx, .epub, .odt, .pdf, .ppt, .pptx, .tsv, .xlsx, .xls, .eml,, .html, .json, .md, .msg, .rst, .rtf, .txt, .xml'}
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    <div>
                                        <Checkbox checked={enableAIProcessing} aria-label='AI enhanced processing' className='mb-1' onChange={e => setEnableAIProcessing(e.target.checked)}/>
                                        AI enhanced processing
                                        <BSToolTip marginTop={-2} title={`
                                        Enable AI processing mode (1200 token/page)
                                        `}>
                                            <IconButton className='enhance-btn-help'>
                                                <i className='bi bi-question-circle' />
                                            </IconButton>
                                        </BSToolTip>

                                    </div>
                                </>
                            }

                            {choice === StringChoice.url &&
                                <input className='add-ds-string-url' value={url} placeholder='Enter URL here...' onChange={handleURLChange} />
                            }

                            {choice === StringChoice.text &&
                                <textarea className='add-ds-string-textarea' value={text} onChange={handleTextChange} />
                            }
                        </>
                    }

                    {/* <div className='add-ds-string-choices'>
                    <button>Upload a File</button>
                    <button>URL</button>
                </div> */}

                    {file !== undefined &&
                        <div className='add-ds-string-selected'>
                            <div className='add-ds-string-info'>
                                <i className='bi bi-file-earmark-arrow-up' />
                                <h1>{file?.name}</h1>
                            </div>
                            <button onClick={removeFile}>Remove</button>
                        </div>
                    }

                    <div className='add-ds-string-divider' />

                    <div className='add-ds-string-buttons'>
                        <button className='add-ds-string-cancel' onClick={handleClose}>Cancel</button>
                        <button className={`add-ds-string-button ${isEnable() ? 'enable' : 'disable'}`} onClick={submitString}>Confirm to Add</button>
                    </div>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={showLoading}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </div>
            </ReactModal>
            <GeneralAlert
                isOpen={showTokenAlert}
                type={AlertType.insufficientCredit}
                title={'Insufficient Tokens'}
                message={'There are not enough tokens available to deploy a bot'}
                secondaryAction={() => navigate('/profile/credits')}
                onClose={() => setShowTokenAlert(false)}
            />
        </>
    );
};

export default AddDSStringForm;
