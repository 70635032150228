import React from 'react';
import { Position, Handle } from 'reactflow';

import './TrueHandle.scss';

const TrueHandle: React.FC = () => {

    return (
        <Handle id='true-handle' className='true-handle' type='source' position={Position.Right} />
    );
};

export default TrueHandle;
