import React from 'react';

const AlertSuccess: React.FC = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='136' height='136' viewBox='0 0 136 136' fill='none'>
            <path d='M67.9986 16.6826C81.3534 28.6654 98.9165 34.8608 116.834 33.9093C118.245 39.372 118.999 45.0953 118.999 50.9999C118.999 82.688 97.3292 109.31 67.9986 116.858C38.6679 109.31 16.9986 82.6823 16.9986 50.9999C16.9939 45.2344 17.7212 39.4916 19.1632 33.9093C37.0806 34.8608 54.6437 28.6654 67.9986 16.6826Z' fill='white' />
            <path d='M50.9986 67.9999L62.3319 79.3333L84.9986 56.6666M116.834 33.9093C98.9165 34.8608 81.3534 28.6654 67.9986 16.6826C54.6437 28.6654 37.0806 34.8608 19.1632 33.9093C17.7212 39.4916 16.9939 45.2344 16.9986 50.9999C16.9986 82.6823 38.6679 109.31 67.9986 116.858C97.3292 109.31 118.999 82.6879 118.999 50.9999C118.999 45.0953 118.245 39.372 116.834 33.9093Z' stroke='#14B8A6' stroke-width='10' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
    );
};

export default AlertSuccess;