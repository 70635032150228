import React, { Dispatch, SetStateAction } from 'react';
import { Bot, disableChatHistory, enableChatHistory } from '../../../services/BSCore';
import './Settings.scss';

interface SettingsProps {
    bot: Bot;
    onBotSaved: () => Promise<void>;
    setShowLoading: Dispatch<SetStateAction<boolean>>;
}

const Settings: React.FC<SettingsProps> = ({ bot, onBotSaved, setShowLoading }) => {
    const handleToggleChatHistory = async () => {
        if (!bot?.id) { console.error('Invalid bot, unable to toggle chat history'); return; }

        try {
            setShowLoading(true);
            if (bot.config.enableHistory) {
                await disableChatHistory(bot.id);
                await onBotSaved();
            } else {
                await enableChatHistory(bot.id);
                await onBotSaved();
            }
            setShowLoading(false);
        } catch (e) {
            console.log('Error occurred while toggling chat history', e);
            setShowLoading(false);
        }
    };

    return (
        <div>
            {bot && (

            <div className='bg-white row settings'>
                <div className='col-9'>
                    <div className='text-main pb-3'>Chat History</div>
                    <div className='text-grey'>Enable History</div>
                </div>
                <div className='form-check form-switch col-3 d-flex justify-content-end'>
                    <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={bot.config.enableHistory}
                    onChange={handleToggleChatHistory}
                />
                    <label className='form-check-label px-2' htmlFor='flexSwitchCheckDefault '>
                        {bot.config.enableHistory ? 'ON' : 'OFF'}
                    </label>
                </div>
            </div>
    )}
        </div>
    );
};

export default Settings;
