import React, { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { Bot, editBot, uploadIconUrl } from '../../../services/BSCore';
import { getFormattedDate, getFormattedTimeAgo } from '../../../util/main';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Collapse from '@mui/material/Collapse';
import ImageCropper from '../../imageCrop/ImageCrop';
import { Area } from 'react-easy-crop';
import CircularProgress from '@mui/material/CircularProgress';
import './BotProfile.scss';
import BSToolTip from '../../toolTip/TooTip';

interface Props {
    botDetail?: Bot;
    onBotSaved: () => Promise<void>;
    setShowLoading: Dispatch<SetStateAction<boolean>>;
}

const BotProfile: React.FC<Props> = ({ botDetail, onBotSaved, setShowLoading }) => {
    const [profileImage, setProfileImage] = useState('');
    const [projectName, setProjectName] = useState('');
    const [description, setDescription] = useState('');
    const [unsaveData, setUnsaveData] = useState(false);
    const [image, setImage] = useState<File>();
    const [imageURL, setImageURL] = useState('');
    const [imageType, setImageType] = useState('');
    const [showCrop, setShowCrop] = useState(false);
    const imageRef = useRef<File>();
    imageRef.current = image;
    
    useEffect(() => {
        if (botDetail) {
            setProfileImage(botDetail.icon);
            setProjectName(botDetail.name);
            setDescription(botDetail.config?.description || '');
        }
        return () => {
            setUnsaveData(false);
        };
    }, [botDetail]);

    const [imageUploading, setImageUploading] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showImageUploadSuccessAlert, setShowImageUploadSuccessAlert] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const reader = new FileReader();
        const file = event.target.files?.[0];
        event.target.value = '';

        if (file) {
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                if (reader.result) {
                    setImageURL(reader.result.toString());
                    setImageType(file.type);
                    setShowCrop(true);
                }
            };
        }
    };

    const onCropDone = async (croppedArea?: Area) => {
        if (croppedArea) {
            const canvasEle = document.createElement('canvas');
            canvasEle.width = croppedArea.width;
            canvasEle.height = croppedArea.height;

            console.log('area', croppedArea);
            const context = canvasEle.getContext('2d');

            let imageObj1 = new Image();
            imageObj1.src = imageURL;
            imageObj1.onload = async () => {
                if (context) {
                    context.drawImage(
                        imageObj1,
                        croppedArea.x,
                        croppedArea.y,
                        croppedArea.width,
                        croppedArea.height,
                        0,
                        0,
                        croppedArea.width,
                        croppedArea.height
                    );

                    const dataURL = canvasEle.toDataURL(imageType);
                    const response = await fetch(dataURL);
                    const blob = await response.blob();
                    const type = imageType.split('/')[1];
                    const file = new File([blob], 'image', { type: type });

                    setImage(file);
                    setShowCrop(false);
                    setImageUploading(true);
                    await uploadImage();
                }
            };
        }
    };

    const uploadImage = async () => {
        let counter = 0;

        while(!imageRef.current) {
            if (counter > 10) {
                setShowCrop(false);
                setImage(undefined);
                setImageUploading(false);
                await delay(200);
                alert('Something went wrong, please try again later');
                return;
            }

            await delay(2000);
            counter += 1;
            console.log('counter', counter);
        }

        if (imageRef.current) {
            try {
                setImageUploading(true);
                const imageUrl = await uploadIconUrl(imageRef.current);
                await editBot(botDetail?.id || 0, {
                    icon: imageUrl.url,
                    name: botDetail?.name || '',
                    config: botDetail?.config || {}
                });
                setShowImageUploadSuccessAlert(true);
                setImage(undefined);
                setImageUploading(false);
                setProfileImage(imageUrl.url);
                onBotSaved();
                setTimeout(() => {
                    setShowImageUploadSuccessAlert(false);
                }, 5000);
            } catch (error) {
                setImageUploading(false);
                console.error('Error uploading image:', error);
            }
        }
    };

    const onCropCancel = () => {
        setShowCrop(false);
        setImage(undefined);
        setImageURL('');
    };

    const handleCancel = () => {
        setProfileImage(botDetail?.icon || '');
        setProjectName(botDetail?.name || '');
        setDescription(botDetail?.config?.description || '');
        setUnsaveData(false);
    };

    const handleSave = async () => {
        try {
            setShowLoading(true);
            const updatedBotDetail = {
                icon: profileImage,
                name: projectName,
                config: {
                    ...botDetail?.config,
                    description: description,
                }
            };

            await editBot(botDetail?.id || 0, updatedBotDetail);
            setShowSuccessAlert(true);
            await onBotSaved();
            setTimeout(() => {
                setShowSuccessAlert(false);
            }, 5000);
            setShowLoading(false);
        } catch (error) {
            console.error(error);
            setShowLoading(false);
        }
        setUnsaveData(false);
    };

    return (
        <div className='py-4 container-fluid bg-white'>
            <Collapse in={showSuccessAlert}>
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                    <i className='bi bi-check-circle pe-2'></i>
                    <div>
                        Bot information saved successfully.
                    </div>
                </div>
            </Collapse>
            <Collapse in={showImageUploadSuccessAlert}>
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                    <i className='bi bi-check-circle pe-2'></i>
                    <div>
                        Upload successful.
                    </div>
                </div>
            </Collapse>
            <Collapse in={unsaveData}>
                <div className='alert alert-warning d-flex align-items-center' role='alert'>
                    <i className='bi bi-exclamation-circle pe-2'></i>
                    <div>
                        You have unsaved changes. Please save or cancel.
                    </div>
                </div>
            </Collapse>
            <div className='py-4 d-flex align-items-center'>
                <div className='bot-image-upload-spinner-container'>
                    <img style={{ objectFit: 'cover', borderRadius: '20%', position: 'relative' }} src={profileImage} width={'100px'} height={'100px'} alt='Avatar' />

                    {imageUploading &&
                        <div className='bot-image-upload-spinner' >
                            <CircularProgress color='inherit' />
                        </div>
                    }
                </div>
                <div>
                    <BSToolTip marginTop={1} title={`Setup the app's avatar`}>
                        <button className='btn btn-link text-main' onClick={() => fileInputRef.current?.click()}>
                            Upload image..
                        </button>
                    </BSToolTip>
                    <input
                        type='file'
                        accept='image/*'
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <div className='text-secondary ps-3'>
                        Format JPG, PNG, SVG, PDF are accepted, and ensure the uploaded file is less than 2MB.
                    </div>
                </div>
            </div>

            <div className='row py-4 border-bottom'>
                <div className='col-md-4 py-2 text-main'>
                    <BSToolTip marginTop={8} title={'This name will also be displayed as the app name to end users.'}>
                        <h6 className='bot-profile-project-name-title'>Project name</h6>
                    </BSToolTip>
                    <div className='input-group'>
                        <input
                            type='text'
                            className='form-control rounded text-default border-grey02'
                            placeholder='Input field'
                            value={projectName}
                            maxLength={16}
                            onChange={(event) => {
                                const inputText = event.target.value;
                                if (inputText.length <= 16) {
                                    setUnsaveData(true);
                                    setProjectName(inputText);
                                }
                            }}
                        />
                        {projectName.length === 16 && (
                            <p className='text-danger mt-1'>
                                You have reached 16 characters maximum
                            </p>
                        )}
                    </div>
                </div>
                <div className='col-md-4 py-2 text-main'>
                    <h6>Project ID #</h6>
                    <div className='p-2 text-default'>
                        {botDetail?.id}
                    </div>
                </div>
                <div className='col-md-4 py-2 text-main'>
                    <h6>Creation date</h6>
                    <div className='p-2 text-default'>
                        {botDetail && getFormattedDate(botDetail?.created_at)}
                    </div>
                </div>
                <div className='col-md-4 py-2 text-main'>
                    <h6>Project Owner</h6>
                    <div className='p-2 text-default'>
                        {botDetail?.config?.owner}
                    </div>
                </div>
                <div className='col-md-4 py-2 text-main'>
                    <h6>Last update</h6>
                    <div className='p-2 text-default'>
                        {botDetail && getFormattedTimeAgo(botDetail?.updated_at)}
                    </div>
                </div>
                <div className='col-md-8 py-2'>
                    <BSToolTip placement='bottom-start' title={'Details visible to workspace members only.'}>
                        <h6 className='text-main bot-profile-description-title'>Description</h6>
                    </BSToolTip>
                    <div className='input-group'>
                        <TextareaAutosize
                            className='form-control rounded text-default border-grey02'
                            placeholder='Input field'
                            minRows={3}
                            value={description}
                            onChange={(event) => {
                                setUnsaveData(true);
                                setDescription(event.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='row justify-content-end py-4'>
                <button className='col-1 btn btn-link text-main' onClick={handleCancel}>
                    Cancel
                </button>
                <button className='col-1 btn btn-primary' onClick={handleSave}>
                    Save
                </button>
            </div>

            {showCrop &&
                <ImageCropper image={imageURL} onCropDone={onCropDone} onCropCancel={onCropCancel} />
            }
        </div>
    );
};

export default BotProfile;
