import { Auth as AmplifyAuth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { createMember } from './BSCore';

class AuthService {
    async sendCode(email: string, password: string, name: string) {
        try {
            const { user } = await AmplifyAuth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                    name: name,
                },
                autoSignIn: { // optional - enables auto sign in after user is confirmed
                    enabled: false,
                }
            });
            console.log(user);
        } catch (error) {
            console.error('Error signing up: ', error);
            throw error;
        }
    }

    async confirmSignUp(email: string, password: string, code: string) {
        try {
            const result = await AmplifyAuth.confirmSignUp(email, code, { forceAliasCreation: false });
            console.log(result);
            const { uid, token } = await this.signIn(email, password);
            console.log(uid, token);
            const { data } = await createMember();
            console.log(data);
            return data;
        } catch (error) {
            console.error('Error confirming sign up: ', error);
            throw error;
        }
    }

    async signIn(email: string, password: string) {
        try {
            const credential = await AmplifyAuth.signIn(email, password);
            const token = credential.getSignInUserSession().accessToken.jwtToken;
            const uid = credential.userSub;
            return { uid, token };
        } catch (error) {
            console.error('Error signing in: ', error);
            throw error;
        }
    }


    async signInWithGoogle() {
        try {
            await AmplifyAuth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
            // const credentials = await AmplifyAuth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
            // const uid = credentials.identityId;
            // const token = credentials.sessionToken;

            // // Create member if not exist
            // try {
            //     console.log('fetching member data');
            //     const data = await fetchMemberData();
            //     console.log('Fetched member data', data);
            // } catch (error) {
            //     console.error(error);
            //     console.log('member doesn\'t exist, create new member');
            //     const { data } = await createMember();
            //     console.log(data);
            // }

            // return { uid, token };
        } catch (error) {
            console.error('Error signing in with Google: ', error);
            throw error;
        }
    }

    async getCurrentUser() {
        try {
            const user = await AmplifyAuth.currentAuthenticatedUser();
            return user;
        } catch (error) {
            console.error('Error getting current user: ', error);
            throw error;
        }
    }

    async getCurrentUserTokenAndId() {
        try {
            const user = await this.getCurrentUser();
            const session = await AmplifyAuth.currentSession();

            const userId = user.username;
            const idToken = session.getIdToken().getJwtToken();

            return { userId, idToken };
        } catch (error) {
            console.error('Error getting user token and id: ', error);
            throw error;
        }
    }

    async signOut() {
        try {
            await AmplifyAuth.signOut();
            console.log('User has been signed out');
        } catch (error) {
            console.error('Error signing out user: ', error);
        }
    }

    async forgotPassword(email: string) {
        try {
            const data = await AmplifyAuth.forgotPassword(email);
            console.log(data);
            return data;
        } catch (error) {
            console.error('Error sending forgot password', error);
            throw error;
        }
    }

    async forgotPasswordConfirm(email: string, code: string, newPassword: string) {
        try {
            const data = await AmplifyAuth.forgotPasswordSubmit(email, code, newPassword);
            console.log(data);
            return data;
        } catch (error) {
            console.error('Error finishing forgot password flow', error);
            throw error;
        }
    }
}

const authService = new AuthService();
export default authService;