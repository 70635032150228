import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
// import TextField from '../textfield/TextField';
import TextField from '@mui/material/TextField';
import './BotForm.scss';
import { Bot, createBot, createBotUsingTemplate, Template } from '../../services/BSCore';
import { Auth } from 'aws-amplify';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress, Divider } from '@mui/material';
import { fetchWorkspaceAsync, getSelectedWorkspace } from '../../features/workspace/workspaceSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { NetworkError } from '../../services/BSCore';
import GeneralAlert, { AlertType } from './GeneralAlert';

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    template?: Template;
}

const BotForm: React.FC<Props> = ({ isOpen, onClose, template }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const workspaceId = useAppSelector(getSelectedWorkspace)?.id;

    useEffect(() => {
        if (template) {
            setName(template.name);
            setDescription(template.description);
        }
    }
        , [template]);

    const handleClose = () => {
        setName('');
        setDescription('');
        onClose?.();
    };

    const isEnable = () => {
        return name !== '';
    };

    const create = async () => {
        const user = await Auth.currentUserInfo();
        if (workspaceId) {
            if (template) {
                const bot = {
                    workspaceId: workspaceId,
                    name: name,
                    config: {
                        description: description
                    }
                };
                try {
                    const data = await createBotUsingTemplate(template.id, bot);
                    dispatch(fetchWorkspaceAsync());
                    navigate(`/bot/edit/${data.id}`);
                    return data as Bot;
                } catch (error) {
                    const err = error as NetworkError;

                    if (err.response.status === 402) {
                        handleClose();
                        setShowAlert(true);
                    }
                }
            } else {
                const bot = {
                    workspaceId: workspaceId,
                    name: name,
                    icon: '',
                    config: {
                        owner: user.attributes.name,
                        description: description
                    }
                };

                try {
                    const data = await createBot(bot);
                    dispatch(fetchWorkspaceAsync());
                    return data as Bot;
                } catch (error) {
                    const err = error as NetworkError;

                    if (err.response.status === 402) {
                        handleClose();
                        setShowAlert(true);
                    }
                }
            }
        }
    };

    const dialogAction = async () => {
        if (!isEnable()) { return; }

        setShowLoading(true);

        console.log('Create new workspace');
        const data = await create();
        console.log(data);
        handleClose();

        setShowLoading(false);
    };



    return (
        <>
            <ReactModal className='botForm-container' overlayClassName='botForm-overlay' isOpen={isOpen} onRequestClose={handleClose}>
                <div className='botForm-form'>
                    <div className='botForm-back-container'>
                        <i className='bi bi-x-lg wsForm-back p-2' onClick={handleClose}></i>
                    </div>
                    {template ?
                        <h1 className='text-main'>Create a new AI app using template</h1>
                        :
                        <h1 className='text-main'>Create a new AI app</h1>
                    }

                    <label className='auth-text text-main'>Provide the basic information about your new project</label>
                    <label className='auth-text text-secondary font-weight-bold'>*Required</label>
                    <label className='botForm-title py-2'>Name<span className='text-danger'>*</span></label>

                    <TextField
                        className='text-secondary'
                        type='standard'
                        value={name}
                        placeholder='Name the AI agent...'
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />

                    {name.length >= 16 && (
                        <p className='text-warning mt-1'>
                            You have reached 16 characters maximum.
                        </p>
                    )}

                    <label className='botForm-title py-2'>Description</label>

                    <TextField
                        className='text-secondary'
                        multiline
                        rows={4}
                        variant='outlined'
                        fullWidth
                        value={description}
                        placeholder='Please describe what the AI agent can do...'
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <Divider className='my-4' />
                    <div className='row justify-content-end'>
                        <button className='col-3 btn btn-link text-main' onClick={handleClose}>
                            Cancel
                        </button>
                        <div className={`col-3 btn btn-primary ${name.length >= 16 ? 'disabled' : ''}`} onClick={dialogAction}>
                            Create
                        </div>
                    </div>
                </div>
            </ReactModal>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showLoading}>
                <CircularProgress color='inherit' />
            </Backdrop>
            <GeneralAlert
                isOpen={showAlert}
                type={AlertType.insufficientCredit}
                title={'Insufficient Tokens'}
                message={'There are not enough tokens available to create a bot'}
                secondaryAction={() => navigate('/profile/credits')}
                onClose={() => setShowAlert(false)}
            />
        </>

    );
};

export default BotForm;
