import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Authentication from './pages/auth/Authentication';
// import Dashboard from './pages/dashboard/Dashboard';
import EditBots from './pages/editBots/EditBots';
import BotsDetails from './pages/botsDetails/BotsDetails';
import CreateWorkspace from './pages/createWorkspace/CreateWorkspace';
import Home from './pages/home/Home';
import DetailPages from './pages/detailPages/DetailPages';
import NewProfile from './pages/profile/NewProfile';

const App = createBrowserRouter([
    // { path: '/', element: <Dashboard /> },
    { path: '/auth', element: <Authentication /> },
    { path: '/profile', element: <NewProfile /> },
    { path: '/datasource', element: <BotsDetails /> },
    { path: '/profile/:profile_id', element: <NewProfile /> },
    { path: '/profile/:profile_id/:sub_id', element: <NewProfile /> },
    { path: '/datasource/:ds_id', element: <BotsDetails /> },
    { path: '/bot/:bot_id', element: <DetailPages /> },
    { path: '/bot/edit/:bot_id', element: <EditBots /> },
    { path: '/workspace/new', element: <CreateWorkspace /> },
    { path: '/', element: <Home /> },
]);

export default <RouterProvider router={App} />;
