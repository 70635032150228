import React from 'react';

interface Props {
    color?: string;
}

const YoutubeIcon: React.FC<Props> = ({ color }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='21' height='15' viewBox='0 0 21 15' fill='none'>
            <path fill-rule='evenodd' clip-rule='evenodd' d='M1.86209 13.7776C2.6737 14.5484 3.74991 14.9789 4.86925 14.9804H14.8693C15.6409 14.9812 16.4045 14.8231 17.1125 14.5162C17.8205 14.2092 18.4578 13.7599 18.9847 13.1961C19.5116 12.6323 19.9168 11.9661 20.1752 11.2389C20.4337 10.5118 20.5397 9.7393 20.4867 8.96943C20.4338 8.19957 20.223 7.44883 19.8675 6.76392C19.512 6.07901 19.0193 5.47458 18.4202 4.98822C17.8211 4.50186 17.1283 4.14397 16.3849 3.93682C15.6416 3.72968 14.8636 3.6777 14.0993 3.78412C13.7715 2.49898 12.9467 1.39666 11.8063 0.719649C10.6658 0.0426427 9.30314 -0.153591 8.018 0.174117C6.73286 0.501826 5.63054 1.32663 4.95353 2.46709C4.27653 3.60754 4.08029 4.97023 4.408 6.25537C3.29505 6.37485 2.27023 6.91637 1.54442 7.7685C0.818606 8.62063 0.447014 9.71855 0.506106 10.8363C0.565198 11.9541 1.05048 13.0067 1.86209 13.7776ZM13.8802 8.97309C14.2274 8.77262 14.2274 8.27144 13.8802 8.07098L9.19271 5.36465C8.84549 5.16418 8.41146 5.41476 8.41146 5.8157L8.41146 11.2284C8.41146 11.6293 8.84549 11.8799 9.19271 11.6794L13.8802 8.97309Z' fill={color} />
        </svg>
    );
};

export default YoutubeIcon;