import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Team, Workspace, Bot, DataSource, DSString, fetchBots, fetchMemberData, fetchDataSources } from '../../services/BSCore';
import { findLastSelectedWorkspace } from '../../util/logic';

export interface WorkspaceState {
    workspaces: Team[] | null;
    selectedWorkspace: Workspace | null;
    bots: Bot[] | null;
    selectedBotInfo: Bot | null;
    selectedBot: string | null;
    datasources: DataSource[] | null;
    selectedDataSource: string | null;
    dsStrings: DSString[] | null;
    status: 'idle' | 'loading' | 'succeed' | 'failed';
    error?: string;
}

const initialState: WorkspaceState = {
    workspaces: null,
    selectedWorkspace: null,
    bots: null,
    selectedBotInfo:null,
    selectedBot: null,
    datasources: null,
    selectedDataSource: null,
    dsStrings: null,
    status: 'idle',
    error: undefined,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchWorkspaceAsync = createAsyncThunk(
    'workspace/fetchWorkspaceAsync',
    async (_, { rejectWithValue }) => {
        // // Fetch/create member
        // try {
        //     console.log('fetching member data');
        //     await fetchMemberData();
        // } catch (error) {
        //     console.error(error);
        //     console.log('member doesn\'t exist, create new member');
        //     await createMember();
        // }

        try {
            const member = await fetchMemberData();

            if (member.teamList?.length > 0) {
                const lastedSelectedWorkspace = findLastSelectedWorkspace(member.teamList);
                const workspaceId = lastedSelectedWorkspace.Workspace.id;
                const bots = await fetchBots(workspaceId);
                const datasources = await fetchDataSources(workspaceId);

                return { member, bots, datasources };
            } else {
                return { member, bots: null, datasources: null };
            }
        } catch (e) {
            return rejectWithValue('Error fetching workspace data');
        }
    }
);

export const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        setWorkspaces: (state, action: PayloadAction<Team[]>) => {
            state.workspaces = action.payload;
        },
        setBots: (state, action: PayloadAction<Bot[]>) => {
            state.bots = action.payload;
        },
        setSelectedBot: (state, action: PayloadAction<string>) => {
            state.selectedBot = action.payload;
        },
        setSelectedBotInfo: (state, action: PayloadAction<Bot>) => {
            state.selectedBotInfo = action.payload;
        },
        setDatasources: (state, action: PayloadAction<DataSource[]>) => {
            state.datasources = action.payload;
        },
        setSelectedDataSource: (state, action: PayloadAction<string>) => {
            state.selectedDataSource = action.payload;
        },
        setDSStrings: (state, action: PayloadAction<DSString[]>) => {
            state.dsStrings = action.payload;
        },
        clearData: (state) => {
            state.workspaces = null;
            state.selectedWorkspace = null;
            state.bots = [];
            state.selectedBot = null;
            state.selectedBotInfo =null;
            state.datasources = [];
            state.dsStrings = [];
            state.status = 'idle';
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWorkspaceAsync.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchWorkspaceAsync.fulfilled, (state, action) => {
            state.status = 'succeed';
            state.workspaces = action.payload.member.teamList;
            state.bots = action.payload.bots;
            state.datasources = action.payload.datasources;
            if (action.payload.member.teamList.length > 0) {
                const lastedSelectedWorkspace = findLastSelectedWorkspace(action.payload.member.teamList);
                state.selectedWorkspace = lastedSelectedWorkspace.Workspace;
            } else {
                state.selectedWorkspace = null;
            }
        });
        builder.addCase(fetchWorkspaceAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    },
});

export const { setWorkspaces, setBots, setSelectedBotInfo, setSelectedBot, setDatasources, setSelectedDataSource, setDSStrings, clearData } = workspaceSlice.actions;
export const getWorkspaces = (state: RootState) => state.workspace.workspaces;
export const getSelectedWorkspace = (state: RootState) => state.workspace.selectedWorkspace;
export const getBots = (state: RootState) => state.workspace.bots;
export const getSelectedBot = (state: RootState) => state.workspace.selectedBot;
export const getSelectedBotInfo = (state: RootState) => state.workspace.selectedBotInfo;
export const getDatasources = (state: RootState) => state.workspace.datasources;
export const getSelectedDataSource = (state: RootState) => state.workspace.selectedDataSource;
export const getDSStrings = (state: RootState) => state.workspace.dsStrings;
export const getStatus = (state: RootState) => state.workspace.status;
export default workspaceSlice.reducer;