import './EditorSidePanel.scss';
import React from 'react';
import { BotEditorSidePanelProps, hideEditorSidePanel, setCurrentlySelectedNode } from '../../../features/botEditor/botEditorSlice';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useOnSelectionChange } from 'reactflow';
import { useAppDispatch } from '../../../app/hooks';

type Props = BotEditorSidePanelProps & { show: boolean, shouldShow: React.Dispatch<React.SetStateAction<boolean>>, unselectAllNodes: ()=>void };

const EditorSidePanel: React.FC<Props> = ({ show, title, subtitle, panelComponent, shouldShow, unselectAllNodes}) => {
    const dispatch = useAppDispatch();
    const withPanelNodeList = ['oai-llm', 'bs-logic', 'wenxinyiyan-llm', 'bd-c'];

    useOnSelectionChange({
        onChange: ({ nodes }) => {
            if (nodes.length > 0 && withPanelNodeList.includes(nodes[0].id.replace(/-\d+$/, ''))) {
                dispatch(setCurrentlySelectedNode(nodes[0].id));
            } else {
                dispatch(hideEditorSidePanel());
                dispatch(setCurrentlySelectedNode(''));
            }
        },
    });
    
    return (
        <Slide direction='left' in={show}>
            <div className='editorsidepanel-container'>
                <div className='editorsidepanel-title-container'>
                    <h1>{title}</h1>
                    <IconButton disabled={false} onClick={() => { shouldShow(false); unselectAllNodes(); }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <h2 className='editorsidepanel-subtitle'>
                    {subtitle}
                </h2>
                {panelComponent}
            </div>
        </Slide>
    );
};

export default EditorSidePanel;