import React from 'react';
import './HomepageFootnote.scss';
// import Box from '@mui/material/Box';
// import Popper from '@mui/material/Popper';
// import Fade from '@mui/material/Fade';

// import { useTranslation } from 'react-i18next';

// import config from '../../config';
// const CONTACT_EMAIL = config.CONTACT_EMAIL;
// const COMPANY_LANDING_URL = config.COMPANY_LANDING_URL;

interface Props {
    roundCorners?: boolean;
}

const HomepageFootnote: React.FC<Props> = ({ roundCorners }) => {
    // const { t } = useTranslation();
    // const [shouldShowContactPop, setShouldShowContactPop] = useState(false);
    // const [contactPopAnchorEl, setContactPopAnchorEl] = useState<null | HTMLElement>(null);


    return (
        <div className={`homepage-footnote ${roundCorners ? 'rounded' : ''}`}>
            <div className='homepage-footnote-texts-container container'>
                <p className='homepage-footnote-copyright'>©2024 by BotSquare.ai</p>
                <div>
                    <p className='homepage-footnote-terms'>
                        <a className='homepage-footnote-terms' target='_blank' rel='noreferrer' href='https://botsquare.gitbook.io/botsquare/'>{'Feedback'}</a>
                        <span>&nbsp; | &nbsp;</span>
                        <a className='homepage-footnote-terms' target='_blank' rel='noreferrer' href='https://info380575.wixsite.com/arclight-software-ll/termsandconditions'>{'Terms of conditions'}</a>
                        <span>&nbsp; | &nbsp;</span>
                    </p>
                </div>
            </div>
            {/* <Popper open={shouldShowContactPop} anchorEl={contactPopAnchorEl} transition sx={{ zIndex: 999999 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box sx={{ border:1, p: 2, bgcolor: 'background.paper'}}>
                            <strong>Email</strong>
                            <br />
                            <a href='mailto:info@arclightsoftware.com' className=''>
                                info@arclightsoftware.com
                            </a>
                            <br />
                            <br />
                            <strong>China Mainland Customer?</strong>
                            <br />
                            <a href='tel:+8615951675015' className='btn btn-link text-light-primary  p-0'>
                                +86 15951675015
                            </a>
                            <br />
                            <br />
                            <strong>On WeChat?</strong>
                            <br />
                            <img className='qr-wechat-img' src='https://bs-core-user-icons.s3.us-west-2.amazonaws.com/ContactWeChat.jpg' alt='wechat qr' />
                        </Box>
                    </Fade>
                )}
            </Popper> */}
        </div>
    );
};

export default HomepageFootnote;