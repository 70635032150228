import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeImageToText.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import NodeCategory from '../NodeCategory';
import { NodeDataModal } from '../NodeManifest';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';

const NodeImageToText: React.FC<NodeProps> = ({ id, data }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [imageData, setImageData] = useState('');
    const dispatch = useDispatch();
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const acceptModal = NodeDataModal.image;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            text: data?.imageData || '',
            outputModal: NodeDataModal.text
        });

        // Init UI
        setImageData(data?.imageData || '');
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setImageData(data?.imageData || '');

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.imageData) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.imageData || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.imageData, validVariables]);

    const imageDataOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { imageData: value, moduleDataDidChange: true });

        // Update UI
        setImageData(value);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeTextArea
                        className={`nodeimagetotext-imagedata-input-${id}`}
                        title='Image data'
                        toolTipText='Source image data'
                        validVariables={validVariables}
                        onChange={imageDataOnChange}
                        value={imageData}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                        oneLine
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodeimagetotext-imagedata-input-${id}`, text: imageData, textOnChangeHandler: imageDataOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'img-txt',
    name: 'Text Recognition',
    description: 'Extracts text from images, converting it to text format',
    iconFile: 'node-icon-img-txt.svg',
    color: '#26B89E',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/modal-conversion#image-to-text',
    category: NodeCategory.modalCnv
};

export default NodeImageToText;
export { nodeInfo };