import React, { useState } from 'react';
import './CopyTextField.scss';
// import { Clipboard } from 'react-bootstrap-icons';

interface CopyTextFieldProps {
    title: string;
    value: string;
}

const CopyTextField: React.FC<CopyTextFieldProps> = ({ title, value }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
        });
    };

    return (
        <div className='copy-text-field'>
            <label className='copy-text-field-title'>{title}</label>
            <div className='input-group'>
                <input
                    type='text'
                    className='form-control'
                    value={value}
                    readOnly
                />
                <button className={`button-copy ${copied ? 'show-copied' : ''}`} onClick={handleCopy}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-copy icon' viewBox='0 0 16 16'>
                        <path fill-rule='evenodd' d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z' />
                    </svg>
                    <span className='copied-text'>Copied!</span>
                </button>
            </div>
        </div>
    );
};

export default CopyTextField;