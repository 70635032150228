import React, { useEffect, useState } from 'react';
import { Panel } from 'reactflow';
import './PreviewPanel.scss';
import { TestRunCredentials } from '../../../pages/editBots/EditBots';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, MessageInput, MessageList, Thread, Window, ChannelHeader } from 'stream-chat-react';
import Grow from '@mui/material/Grow';
import config from '../../../config';
const { STREAM_API_KEY } = config;
const chatClient = StreamChat.getInstance(STREAM_API_KEY);

interface Props {
    testRunCredentials: TestRunCredentials
}

const PreviewPanel: React.FC<Props> = ({ testRunCredentials }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [shouldShowChat, setShouldShowChat] = useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setIsLoading(true);
            await chatClient.connectUser({ id: testRunCredentials.userId }, testRunCredentials.userToken);
            setIsLoading(false);
        } catch (e) {
            console.error('Error occurred while init preview channel', e);
        }
    };

    const onChatWindowAnimationEnd = () => {
        console.log('onChatWindowAnimationEnd', onChatWindowAnimationEnd);
    };

    return (
        <Panel position='bottom-right'>
            {isLoading ?
                <></> :
                (
                    <div className='previewpanel-chat-container'>
                        <Grow in={shouldShowChat} style={{ transformOrigin: 'bottom right 0' }} onAnimationEnd={onChatWindowAnimationEnd}>
                            <div className={`previewpanel-chat-wrapper ${shouldShowChat ? '' : 'previewpanel-chat-wrapper-hide'}`}>
                                <Chat client={chatClient}>
                                    <Channel channel={isLoading ? undefined : chatClient.channel('messaging', testRunCredentials.channelId)}>
                                        <Window >
                                            <ChannelHeader />
                                            <MessageList />
                                            <MessageInput grow={true} disableMentions={true} />
                                        </Window>
                                        <Thread />
                                    </Channel>
                                </Chat>
                            </div>
                        </Grow>
                        <button className='previewpanel-chat-button' onClick={() => setShouldShowChat(!shouldShowChat)}><i className='bi bi-robot'/></button>
                    </div>
                )
            }
        </Panel >
    );
};

export default PreviewPanel;
