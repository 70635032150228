import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeResourceLoader.scss';
import { uploadPrivateFile } from '../../../services/BSCore';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { setIsDraggable, recalculateNodesMeta } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import { NodeDataModal } from '../NodeManifest';
import Spinner from 'react-bootstrap/Spinner';

import FileUploader from '../../../components/fileUploader/FileUploader';
import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';
import NodeSelect from '../../components/nodeSelect/NodeSelect';

export enum NodeResourceLoaderTypes {
    text = 'text',
    image = 'image',
    // document = 'document'
}

const NodeResourceLoaderDefaults = {
    resourceType: NodeResourceLoaderTypes.text,
    outputModal: 'text'
};

const NodeResourceLoader: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [resourceType, setResourceType] = useState(NodeResourceLoaderDefaults.resourceType);
    const [resourceBody, setResourceBody] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [shouldShowUploadFailed, setShouldShowUploadFailed] = useState(false);
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const [acceptModal, setAcceptModal] = useState<NodeDataModal | ''>(NodeDataModal.text);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables, acceptModal]);

    const uploadFile = async (fileToUpload: File) => {
        try {
            // Upload file
            setIsLoading(true);

            const { mediaKey } = await uploadPrivateFile(fileToUpload);

            updateNodeData(id, setNodes, {
                resourceKey: mediaKey || '',
                resourceFileName: fileToUpload?.name || '',
                moduleDataDidChange: true
            });

            setIsLoading(false);
        } catch (e) {
            console.error('Error thrown while uploading file for NodeResourceLoader', e);

            updateNodeData(id, setNodes, {
                resourceKey: '',
                resourceFileName: '',
            });

            setFile(null);
            setIsLoading(false);
            setShouldShowUploadFailed(true);
        }
    };

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            resourceType: data?.resourceType || NodeResourceLoaderDefaults.resourceType,
            resourceBody: data?.resourceBody || '',
            resourceKey: data?.resourceKey || '',
            resourceFileName: data?.resourceFileName || '',
            outputModal: data?.outputModal || NodeResourceLoaderDefaults.outputModal
        });

        // Init UI
        setResourceType(data?.resourceType || NodeResourceLoaderDefaults.resourceType);
        setResourceBody(data?.resourceBody || '');

        // Create representative file
        if (data?.resourceKey && data?.resourceFileName) setFile(new File([''], data?.resourceFileName, { type: 'text/plain' }));
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setResourceType(data?.resourceType || NodeResourceLoaderDefaults.resourceType);
        setResourceBody(data?.resourceBody || '');

        // Create representative file
        if (!data?.resourceFileName) setFile(null);
        if (data?.resourceKey && data?.resourceFileName) setFile(new File([''], data?.resourceFileName, { type: 'text/plain' }));

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if(data?.resourceType === NodeResourceLoaderTypes.text) {
            if (!data?.resourceBody) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
            if (hasInvalidVariables(data?.resourceBody || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        // } else if (data?.resourceType === NodeResourceLoaderTypes.image || data?.resourceType === NodeResourceLoaderTypes.document) {
        } else if (data?.resourceType === NodeResourceLoaderTypes.image) {
            if (!data?.resourceFileName) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        }
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.resourceBody, data.resourceType, data.resourceFileName, validVariables]);

    const removeFileData = () => {
        updateNodeData(id, setNodes, { resourceKey: undefined, resourceFileName: undefined });
    };

    const resourceTypeOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { resourceType: event.target.value, moduleDataDidChange: true, outputModal: event.target.value as NodeDataModal });

        // Update UI
        setResourceType(event.target.value as NodeResourceLoaderTypes);
        setAcceptModal(event.target.value === 'text' ? NodeDataModal.text : '');

        // Trigger nodes metadata update
        dispatch(recalculateNodesMeta());
    };

    const resourceBodyOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { resourceBody: value });

        // Update UI
        setResourceBody(value);
    };

    const getNodeRenderBody = (): JSX.Element => {
        if (shouldShowUploadFailed) {
            return (
                <div className='node-resource-loading-upload-failed-container'>
                    <p>Upload failed, please try again</p>
                    <i className='bi bi-cloud-slash'></i>
                    <button onClick={() => setShouldShowUploadFailed(false)}>Retry</button>
                </div>
            );
        } else if (isLoading) {
            return (
                <div className='node-resource-loading-uploading-container'>
                    <Spinner animation='border' />
                    <p>Uploading...</p>
                </div>
            );
        } else {
            return (
                <>
                    <NodeSelect
                        onChange={resourceTypeOnChange}
                        value={resourceType}
                        title='Type'
                        toolTipText='Resource data type'
                        bottomMargin
                    >
                        {Object.values(NodeResourceLoaderTypes).map(t => <option value={t}>{t}</option>)}
                    </NodeSelect>
                    {
                        resourceType === NodeResourceLoaderTypes.text ?
                            <>
                                <NodeTextArea
                                    className={`nodersld-body-input-${id}`}
                                    title='Text'
                                    toolTipText='Text resource to load'
                                    validVariables={validVariables}
                                    onChange={resourceBodyOnChange}
                                    value={resourceBody}
                                    onPointerEnter={() => dispatch(setIsDraggable(false))}
                                    onPointerLeave={() => dispatch(setIsDraggable(true))}
                                />
                                <NodeValidVariables
                                    validVariables={validVariables}
                                    inputTargets={[{ inputElementClass: `nodersld-body-input-${id}`, text: resourceBody, textOnChangeHandler: resourceBodyOnChange }]}
                                /></> :
                            <FileUploader
                                file={file}
                                setFile={setFile}
                                uploadFile={uploadFile}
                                onFileDelete={removeFileData}
                            />
                    }
                </>
            );
        }
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    {getNodeRenderBody()}
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'res-ld',
    name: 'Resource Loader',
    description: 'Helps you create any text, image, or document inputs',
    iconFile: 'node-icon-res-ld.svg',
    color: '#576882',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/data#resource-loader',
    category: NodeCategory.data
};

export default NodeResourceLoader;
export { nodeInfo };