import React, { useEffect } from 'react';
import './DetailPages.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import BotDetail from '../../components/botDetail/BotDetail';
import { deleteBot, fetchBots } from '../../services/BSCore';
import { setBots, getSelectedWorkspace } from '../../features/workspace/workspaceSlice';
import { useAppSelector } from '../../app/hooks';
import NavbarComponent from '../../components/shared/main/Navbar/NavbarComponent';
import HomepageFootnote from '../../components/homepageFootnote/HomepageFootnote';

const DetailPages: React.FC = () => {
    const navigate = useNavigate();
    const { bot_id } = useParams();
    const workspaceId = useAppSelector(getSelectedWorkspace)?.id;

    useEffect(() => {
        // Fetch bots data when the component mounts
        fetchBots(workspaceId!)
            .then(bots => {
                setBots(bots); // Update the bots state
            })
            .catch(error => {
                console.log(error);
            });
    }, [workspaceId]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, [navigate]);

    const handleBotDelete = () => {
        deleteBot(Number(bot_id))
            .then(() => {
                fetchBots(workspaceId!)
                    .then(bots => {
                        if (bots.length > 0) {
                            setBots(bots);
                            navigate(`/bot/${bots[0].id}`);
                        } else {
                            navigate(`/`);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <div className='main'>
            <NavbarComponent/>
            <BotDetail handleBotDelete={handleBotDelete}/>
            <HomepageFootnote/>
        </div>
    );
};

export default DetailPages;