import React from 'react';

const AlertError: React.FC = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='136' height='136' viewBox='0 0 136 136' fill='none'>
            <path d='M107.26 107.667H28.7429C20.0162 107.667 14.5649 98.2206 18.9282 90.667L58.1869 22.667C62.5502 15.1133 73.4529 15.1133 77.8162 22.667L117.075 90.667C121.438 98.2206 115.987 107.667 107.26 107.667Z' fill='white' />
            <path d='M68.0015 51.0003V62.3336M68.0015 85.0003H68.0582M28.7429 107.667H107.26C115.987 107.667 121.438 98.2206 117.075 90.667L77.8162 22.667C73.4529 15.1133 62.5502 15.1133 58.1869 22.667L18.9282 90.667C14.5649 98.2206 20.0162 107.667 28.7429 107.667Z' stroke='#F43F5E' stroke-width='10' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
    );
};

export default AlertError;

