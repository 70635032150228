import React, { useEffect, useState } from 'react';
import './SecurityCard.scss';
import { Auth } from 'aws-amplify';
import TextField from '../../textfield/TextField';
import { useNavigate } from 'react-router-dom';
import ConfirmationBanner from '../../confirmationBanner/ConfirmationBanner';

const SecurityCard: React.FC = () => {
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(error => {
                console.log(error);
                navigate('/');
            });
    }, []);

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    const updatePassword = async () => {

        if (newPassword === confirmPassword) {
            setShowPasswordError(false);

            try {
                const user = await Auth.currentAuthenticatedUser();
                const data = await Auth.changePassword(user, currentPassword, newPassword);
                console.log(data);

                setShowConfirmation(true);
                resetFields();

                await delay(3000);

                setShowConfirmation(false);
            } catch (error) {
                let err = error as Error;
                console.log(err.message);
                setShowPasswordError(true);
                setPasswordErrorMessage(err.message);
            }
        } else {
            setShowPasswordError(true);
            setPasswordErrorMessage('The new passwords must match');
        }
    };

    const resetFields = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setShowPasswordError(false);
        setPasswordErrorMessage('');
    };

    return (
        <div className='security-right-content-container'>
            <ConfirmationBanner showUpdateMessage={showConfirmation} updated='Password' />
            <div className='security-right-content-main'>
                <div className='security-right-content pt-4'>
                    <h1>Change Password</h1>

                    {showPasswordError &&
                        <h3>{passwordErrorMessage}</h3>
                    }

                    <h2>Current password</h2>

                    <TextField className='security-textfield' icon={''} type={'password'} input={currentPassword} placeholder={''} hide={true} onChange={setCurrentPassword} />

                    <h2>New password</h2>

                    <TextField className='security-textfield' icon={''} type={'password'} input={newPassword} placeholder={''} hide={true} onChange={setNewPassword} />

                    <h2>Confirm new password</h2>

                    <TextField className='security-textfield' icon={''} type={'password'} input={confirmPassword} placeholder={''} hide={true} onChange={setConfirmPassword} />

                    <button onClick={updatePassword}>Update</button>
                </div>
            </div>
        </div>
    );
};

export default SecurityCard;