import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeBSLLM.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import { NodeDataModal } from '../NodeManifest';

import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';
import NodeSlider from '../../components/nodeSlider/NodeSlider';
import NodeInput from '../../components/nodeInput/NodeInput';

const NodeBSLLMDefaults = {
    temperature: 0.6,
    maxTokens: 3072
};

const NodeBSLLM: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [prompt, setPrompt] = useState('');
    const [temperature, setTemperature] = useState('' + NodeBSLLMDefaults.temperature);
    const [maxTokens, setMaxTokens] = useState('' + NodeBSLLMDefaults.maxTokens);
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const acceptModal = NodeDataModal.text;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            prompt: data.prompt || '',
            temperature: data.temperature || NodeBSLLMDefaults.temperature,
            maxTokens: data.maxTokens || NodeBSLLMDefaults.maxTokens,
            outputModal: NodeDataModal.text
        });

        // Init UI
        setPrompt(data.prompt || '');
        setTemperature(data.temperature || NodeBSLLMDefaults.temperature);
        setMaxTokens(data.maxTokens || NodeBSLLMDefaults.maxTokens);
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setPrompt(data.prompt || '');
        setTemperature(data.temperature === undefined ? '' : data.temperature);
        setMaxTokens(data.maxTokens === undefined ? '' : data.maxTokens);

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.prompt) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.prompt || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.prompt, validVariables]);


    const promptOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { prompt: value });

        // Update UI
        setPrompt(value);
    };

    const tempOnChange = (_: Event, number: number | number[]) => {
        const value = typeof number === 'number' ? number : 0;

        // Update data
        updateNodeData(id, setNodes, { temperature: `${value}` });

        // Update UI
        setTemperature(`${value}`);
    };

    const tkOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        // Update data
        updateNodeData(id, setNodes, { maxTokens: event.target.value ? +event.target.value : undefined });

        // Update UI
        setMaxTokens(event.target.value);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container node-bsllm-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeSlider
                        onChange={tempOnChange}
                        value={+temperature}
                        step={0.1}
                        min={0}
                        max={1}
                        title='Temperature'
                        toolTipText={`Randomness of the model's output. Higher values produce more varied results`}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeInput
                        onChange={tkOnChange}
                        value={maxTokens}
                        title='Max tokens'
                        toolTipText='Maximum number of tokens in the generated output'
                        bottomMargin
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}                   
                    />
                    <NodeTextArea
                        className={`nodebsllm-prompt-input-${id}`}
                        title='Prompt'
                        toolTipText='Instructions to LLM'
                        validVariables={validVariables}
                        onChange={promptOnChange}
                        value={prompt}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodebsllm-prompt-input-${id}`, text: prompt, textOnChangeHandler: promptOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'bs-llm',
    name: 'BotSquare LLM',
    description: 'By Botsquare, a orignial powerful AI for natural language processing and generation',
    iconFile: 'node-icon-bs-llm.svg',
    color: '#3C49CB',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/generative',
    category: NodeCategory.generative
};

export default NodeBSLLM;
export { nodeInfo };
