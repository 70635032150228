import React, { Dispatch, SetStateAction } from 'react';
import './ProjectCard.scss';

interface Props {
    setShowProjectForm:Dispatch<SetStateAction<any>>;
}
const ProjectEmptyCard: React.FC<Props> = ({setShowProjectForm}) => {
    return (
        <button className='dataSource-card-empty bg-white d-flex justify-content-center align-items-center fw-bold text-grey02' 
        onClick={() => setShowProjectForm(true)} >
            <i className='bi bi-plus fw-bold text-grey02 pe-2'></i>
            <div>Create a new project</div>
        </button>
    );
};
export default ProjectEmptyCard;