import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from 'reactflow';
import './FalseHandleEdgeDelete.scss';

export default function FalseHandleEdgeDelete({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd
}: EdgeProps) {
    const { setEdges } = useReactFlow();
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition
    });

    const onEdgeClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        evt.stopPropagation();
        setEdges(edge => edge.filter(e => e.id !== id));
    };

    const style = {
        stroke: '#C2410C'
    };

    // Text label position
    const dx = targetX - sourceX;
    const dy = targetY - sourceY;
    const slope = dy / dx;
    const angle = Math.atan(slope);
    const distance = 20;
    let textLabelX = sourceX + distance * Math.cos(angle);
    let textLabelY = sourceY + distance * Math.sin(angle);
    if (dx < 0) {
        textLabelY = sourceY - distance * Math.sin(angle);
    }

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        left: textLabelX,
                        top: textLabelY - 11,
                        pointerEvents: 'all',
                    }}
                    className='nodrag nopan'
                >
                    <div className='edge-delete-false-label'>
                        False
                    </div>
                </div>
            </EdgeLabelRenderer>
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        pointerEvents: 'all',
                    }}
                    className='nodrag nopan'
                >
                    <button className='edge-delete-btn' onClick={(event) => onEdgeClick(event, id)}>
                        <i className='bi bi-x' />
                    </button>
                </div>
            </EdgeLabelRenderer>
        </>
    );
}
