
import {Team} from '../services/BSCore';
const workspaceLocalKey = 'bs-workspace-selected-id';

export const persistSelectedWorkspace = (workspaceId: number) => {
  localStorage.setItem(workspaceLocalKey, ''+workspaceId);
};

export const findLastSelectedWorkspace = (teamList:Team[]): Team => {
    if (!teamList || teamList.length === 0) {
      throw new Error('Team list is empty');
    }

    // Return persisted workspace
    const selectedWorkspaceId = localStorage.getItem(workspaceLocalKey);
    if (selectedWorkspaceId && teamList.some(t => t.Workspace.id === +selectedWorkspaceId)) {
      return teamList.find(t => t.Workspace.id === +selectedWorkspaceId)!;
    } else {
      localStorage.removeItem(workspaceLocalKey);
    }
  
    // No persisted workspace, return last workspace
    const lastSelectedWorkspace = teamList.sort((teamA, teamB) => {
      if (teamA.selectedAt && teamB.selectedAt) {
        return new Date(teamB.selectedAt).getTime() - new Date(teamA.selectedAt).getTime();
      }
      if (teamA.selectedAt) {
        return -1;
      }
      if (teamB.selectedAt) {
        return 1;
      }
      return new Date(teamB.created_at).getTime() - new Date(teamA.created_at).getTime();
    })[0];
    localStorage.setItem(workspaceLocalKey, ''+lastSelectedWorkspace.id);

    return lastSelectedWorkspace;
  };
  