import React, { useRef, useState, useEffect } from 'react';
import { RichTextarea, createRegexRenderer, StyleOrRender, RichTextareaHandle } from 'rich-textarea';
import NodeManifest from '../../nodes/NodeManifest';
import { blendWithWhite } from '../../utils';
import './NodeTextArea.scss';
import NodeConfigTitle from '../nodeConfigTitle/NodeConfigTitle';

const textAreaStyle = {
    width: '100%',
    height: '126px',
    padding: '4px 8px',
    backgroundColor: 'white',

    border: 'solid 1px lightgrey',
    borderRadius: '4px',

    color: 'black',
    fontSize: 'small',
    '&:hover, &:focus': {
        background: '#707070',
        borderColor: '#707070'
    }
};

interface Props {
    validVariables: string[]
    margin?: NodeTextAreaMargin
    className?: string
    value?: string | number | readonly string[] | undefined
    title: string
    toolTipText?: string 
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
    onPointerEnter?: React.PointerEventHandler<HTMLTextAreaElement>
    onPointerLeave?: React.PointerEventHandler<HTMLTextAreaElement>
    oneLine?: boolean
    bottomMargin?: boolean
    defaultValue?: string
}

export enum NodeTextAreaMargin {
    standard='standard',
    dense='dense'
}

const NodeTextArea: React.FC<Props> = ({ validVariables, value, title, toolTipText, onChange, onPointerEnter, onPointerLeave, className, margin = NodeTextAreaMargin.dense, oneLine, bottomMargin, defaultValue }) => {
    const textAreaRef = useRef<RichTextareaHandle>(null);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);

    const regexps: [RegExp, StyleOrRender][] = validVariables.map(v => {
        const nodeInfo = NodeManifest.find(n => n.id === v.substring(0, v.lastIndexOf('-')))!;

        return [new RegExp(`{${v}}`, 'g'),
        {
            borderRadius: '4px',
            border: `1px solid ${nodeInfo.color}`,
            margin: '-1px',
            backgroundColor: `${blendWithWhite(nodeInfo.color, 0.15)}`,
            color: nodeInfo.color,
            width: '100px',
            boxSizing: 'border-box',
            paddingBottom: '2px'
        }];
    });
    regexps.push([new RegExp('\\{[^}]*\\}', 'g'), { borderRadius: '3px', backgroundColor: 'pink', paddingBottom: '2px' }]);
    const renderer = createRegexRenderer(regexps);

    const onChangeOverride: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        setCursorPosition(event.target.selectionStart);
        if (onChange) onChange(event);
    };

    useEffect(() => {
        if (textAreaRef.current && cursorPosition !== null) {
            textAreaRef.current.selectionStart = cursorPosition;
            textAreaRef.current.selectionEnd = cursorPosition;
        }
    }, [value]);

    return (
        <div className={bottomMargin ? 'node-bottom-margin' : ''}>
            <div className='node-text-title'>
                <NodeConfigTitle 
                    title={title}
                    toolTipText={toolTipText}
                />
            </div>

            <RichTextarea
                ref={textAreaRef}
                value={value}
                className={`nodetextarea-textarea ${margin === NodeTextAreaMargin.standard ? 'nodetextarea-standard-margin' : ''} ${className}`}
                onChange={onChangeOverride}
                style={textAreaStyle}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                rows={1}
                autoHeight={!!oneLine}
                defaultValue={defaultValue}
            >
                {renderer}
            </RichTextarea>
        </div>
    );
};

export default NodeTextArea;