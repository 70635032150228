import React from 'react';
import './Templates.scss';
import TemplateCard from '../../components/cards/templateCard/TemplateCard';
import { Template } from '../../services/BSCore';
import config from '../../config';
const { TEMPLATE_BLACK } = config;

interface TemplateProps {
    templates?: Template[] | undefined | null;
}
const TemplatesPage: React.FC<TemplateProps> = ({templates}) => {
    return (
        <div className='content'>
            <div className='templates-container'>
                <div className='templates-container-title-container'>
                    <div className='templates-container-title-header'>
                        Template
                    </div>
                    <div className='templates-container-title-description'>
                        Browse all our templates here and quickly start a project by selecting one to work on.
                    </div>
                    <hr className='templates-container-title-line'></hr>
                </div>
                <div className='templates-cards-container'>
                    {templates?.filter(t => !TEMPLATE_BLACK.includes(t.id)).map((card) => (
                        <TemplateCard card = {card}/>
                    ))}

                </div >
            </div>
        </div>
    );
};

export default TemplatesPage;