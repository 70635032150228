import React, { ChangeEvent, useEffect, useState } from 'react';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import NodeCategory from '../NodeCategory';
import { NodeDataModal } from '../NodeManifest';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';

const NodeGoogleSearch: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [query, setQuery] = useState('');
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const acceptModal = NodeDataModal.text;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            query: data.query || '',
            outputModal: NodeDataModal.text
        });

        // Init UI
        setQuery(data.query || '');
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setQuery(data.query || '');

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.query) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.query || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.query, validVariables]);

    const queryOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { query: value });

        // Update UI
        setQuery(value);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeTextArea
                        className={`google-search-query-input-${id}`}
                        title='Query'
                        toolTipText='Keywords you want to search'
                        validVariables={validVariables}
                        onChange={queryOnChange}
                        value={query}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `google-search-query-input-${id}`, text: query, textOnChangeHandler: queryOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'google-search',
    name: 'Google Search',
    description: 'Queries Google and returns the top result from the first three searches',
    iconFile: 'node-icon-google-search.svg',
    color: '#FBBC05',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/data#google-search',
    category: NodeCategory.data
};

export default NodeGoogleSearch;
export { nodeInfo };
