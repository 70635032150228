import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const OrganizationProfileSkeleton: React.FC = () => {
    return (
        <div className='py-4 container-fluid'>
            <div className='d-flex justify-content-center align-items-center'>
                <Skeleton animation='wave' height={150} width={100}  style={{margin: 0, padding: 0, flexShrink: 0 }} />
                <div>
                    <div className='text-secondary ps-3'>
                        <Skeleton animation='wave' height={41} width={150} />
                    </div>
                    <div className='text-secondary ps-3'>
                        <Skeleton animation='wave' height={41} width={650} />
                    </div>
                </div>
            </div>

            <div className='row py-4 border-bottom'>
                <div className='col-md-6 py-2 text-main'>
                    <h6>Project name</h6>
                    <Skeleton animation='wave' height={41} width={450} />
                </div>
                <div className='col-md-6 py-2 text-main'>
                    <h6>Project ID #</h6>
                    <Skeleton animation='wave' height={41} width={150} />
                </div>
                <div className='col-md-6 py-2 text-main'>
                    <h6>Creation date</h6>
                    <Skeleton animation='wave' height={41} width={150} />
                </div>
                <div className='col-md-6 py-2 text-main'>
                    <h6>Project Owner</h6>
                    <Skeleton animation='wave' height={41} width={150} />
                </div>
                <div className='col-md-6 py-2 text-main'>
                    <h6>Last update</h6>
                    <Skeleton animation='wave' height={41} width={150} />
                </div>
                <div className='col-md-6 py-2 text-main'>
                    <h6>Chat History</h6>
                    <Skeleton animation='wave' height={95} width={150} />
                </div>
                <div className='col-md-12 py-2'>
                    <h6 className='text-main'>Description</h6>
                    <Skeleton animation='wave' height={120} />
                </div>
            </div>
            <div className='row justify-content-end py-4'>
                <Skeleton animation='wave' height={60} width={150} style={{marginRight: 16}}/>
                <Skeleton animation='wave' height={60} width={150} />
            </div>
        </div>
    );
};

export default OrganizationProfileSkeleton;
