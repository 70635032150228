import React from 'react';

interface Props {
    color?: string;
}

const QdrantIcon: React.FC<Props> = ({ color }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path d='M4.20044 14.2285V17.5714C4.20044 19.4177 7.69261 20.9142 12.0004 20.9142C16.3083 20.9142 19.8004 19.4177 19.8004 17.5714V14.2285C19.8004 16.0749 16.3083 17.5714 12.0004 17.5714C7.69261 17.5714 4.20044 16.0749 4.20044 14.2285Z' fill={color} />
            <path d='M4.20044 8.65674V11.9996C4.20044 13.846 7.69261 15.3425 12.0004 15.3425C16.3083 15.3425 19.8004 13.846 19.8004 11.9996V8.65674C19.8004 10.5031 16.3083 11.9996 12.0004 11.9996C7.69261 11.9996 4.20044 10.5031 4.20044 8.65674Z' fill={color} />
            <path d='M19.8004 6.42831C19.8004 8.27468 16.3083 9.77116 12.0004 9.77116C7.69261 9.77116 4.20044 8.27468 4.20044 6.42831C4.20044 4.58193 7.69261 3.08545 12.0004 3.08545C16.3083 3.08545 19.8004 4.58193 19.8004 6.42831Z' fill={color} />
        </svg>
    );
};

export default QdrantIcon;