import moment from 'moment';
export function getFormattedDate(dateStr: string , format='MM/DD/YYYY') {
    if(!dateStr) {
        return 'Not Entered';
    }
    else if(!moment.utc(dateStr).isValid()){
        return 'Not Entered';
    }
    return moment.utc(dateStr).local().format(format);
}

export function getFormattedTimeAgo(dateStr: string) {
    const now = moment();
    const date = moment(dateStr);
    
    if (date.isSame(now, 'day')) {
      return `${date.fromNow()}`;
    } else {
      return date.format('MM/DD/YYYY');
    }
  }