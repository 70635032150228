import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import './AddYTStringForm.scss';
import { dataSourceAddStringVideo, searchVideo } from '../../services/BSCore';
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import TextField from '../textfield/TextField';
import GeneralAlert, { AlertType } from '../dialog/GeneralAlert';
import { useNavigate } from 'react-router-dom';

interface Props {
    isOpen: boolean;
    uploading: (datasourceId: string, taskId: string, status: string) => void;
    onClose?: () => void;
}

const AddYTStringForm: React.FC<Props> = ({ isOpen, uploading, onClose }) => {
    const { ds_id, bot_id } = useParams();
    
    const [showLoading, setShowLoading] = useState(false);
    const [url, setURL] = useState('');
    const [videoId, setVideoId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showTokenAlert, setShowTokenAlert] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (url !== '') {
            searchVideo(url)
                .then(video => {
                    console.log('search video id', video.videoId);
                    if (video.videoId) {
                        if (video.duration < 6000) {
                            setErrorMessage('');
                            setVideoId(video.videoId);
                        } else {
                            setErrorMessage('Video too long: max video length allowed is 1 hour.');
                        }
                    } else {
                        setVideoId('');
                    }
                })
                .catch(error => {
                    console.log(error);
                    setVideoId('');
                });
        }
    }, [url]);

    const handleClose = () => {
        setURL('');
        setShowLoading(false);
        onClose?.();
    };

    const isEnable = () => {
        return videoId !== '' && errorMessage === '';
    };

    const submitString = async () => {
        try {
            console.log('submit ?', ds_id, bot_id);

            if (!isEnable()) {
                console.log('not enabled ');
                return;
            }

            if (ds_id && videoId !== '') {
                setShowLoading(true);
                const data = await dataSourceAddStringVideo(ds_id, videoId);
                console.log(data);

                handleClose();

                uploading(ds_id, data.id, 'INIT');
            }
        } catch (e: any) {
            if (e?.response?.status === 402) {
                console.error('Insufficient token balance');
                handleClose();
                setShowTokenAlert(true);
            }
        }
    };

    return (
        <>
            <ReactModal className='add-yt-string-container' overlayClassName='add-yt-string-overlay' isOpen={isOpen} onRequestClose={handleClose}>
                <div className='add-yt-string-back-container'>
                    <i className='bi bi-x-lg wsForm-back' onClick={handleClose}></i>
                </div>
                <div className='add-yt-string-form'>
                    <h1>Add Video</h1>

                    <label className='auth-text'>Please provide the YouTube URL of your video...</label>

                    <TextField icon={''} type={'url'} input={url} placeholder={'https://www.youtube.com/watch?v=id'} hide={false} onChange={setURL} />

                    {videoId !== '' &&
                        <div className='add-yt-string-player'>
                            <iframe width='100%' height='300' src={`https://www.youtube.com/embed/${videoId}`} title='YouTube video player' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;' />
                        </div>
                    }

                    {errorMessage !== '' &&
                        <h2 className='add-yt-string-error-message'>
                            {errorMessage}
                        </h2>
                    }

                    <div className='add-yt-string-divider' />

                    <div className='add-yt-string-buttons'>
                        <button className='add-yt-string-cancel' onClick={handleClose}>Cancel</button>
                        <button className={`add-yt-string-button ${isEnable() ? 'enable' : 'disable'}`} onClick={submitString}>Confirm to Add</button>
                    </div>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={showLoading}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </div>
            </ReactModal>
            <GeneralAlert
                isOpen={showTokenAlert}
                type={AlertType.insufficientCredit}
                title={'Insufficient Tokens'}
                message={'There are not enough tokens available to deploy a bot'}
                secondaryAction={() => navigate('/profile/credits')}
                onClose={() => setShowTokenAlert(false)}
            />
        </>
    );
};

export default AddYTStringForm;
