import React, { useState, useEffect } from 'react';
import './TokenUsageCard.scss';
import { useAppSelector } from '../../../app/hooks';
import { getSelectedWorkspace } from '../../../features/workspace/workspaceSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { TokenUsageTableData, fetchTokenUsageList } from '../../../services/BSCore';
import { Backdrop, CircularProgress } from '@mui/material';
import { formatISODateTim } from '../../../utils';

const TokenUsageCard: React.FC = () => {
    const workspace = useAppSelector(getSelectedWorkspace);
    const [tokenUsageList, setTokenUsageList] = useState<TokenUsageTableData[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const refetchTokenUsageData = () => {
        if (!workspace) return;
        fetchTokenUsageList(workspace.id)
            .then(t => {
                setTokenUsageList(t);
                setIsLoading(false);
            })
            .catch(e => { console.error('Fetch tokenUsageList failed', e); setIsLoading(false); });
    };

    useEffect(() => {
        refetchTokenUsageData();
    }, [workspace]);


    const getSkeletonComponent = () => {
        return <><div className='token-usage-card-skeleton-wrapper'>
            <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
        </div>
            <div className='token-usage-card-skeleton-wrapper'>
                <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation='wave' height={10} width='80%' />
            </div>
            <div className='token-usage-card-skeleton-wrapper'>
                <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation='wave' height={10} width='80%' />
            </div>
            <div className='token-usage-card-skeleton-wrapper'>
                <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation='wave' height={10} width='80%' />
            </div>
            <div className='token-usage-card-skeleton-wrapper'>
                <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation='wave' height={10} width='80%' />
            </div>
        </>;
    };

    return (
        <div className='token-usage-container content'>
            <div className='token-usage-view'>
                <div className='token-usage-header-management'>
                    <div>
                        <h3>Token usage history</h3>
                    </div>
                </div>
                <div className='token-usage-card-container'>
                    {
                        isLoading ? getSkeletonComponent() : <TableContainer component={Paper}>
                            <Table className='token-usage-card-table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='token-usage-card-title' align='left'>Transaction Id</TableCell>
                                        <TableCell className='token-usage-card-title' align='left'>Item</TableCell>
                                        <TableCell className='token-usage-card-title' align='left'>Tokens Spent</TableCell>
                                        <TableCell className='token-usage-card-title' align='left'>Transaction Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tokenUsageList.map((tokenUsageItem) => (
                                        <TableRow
                                            key={tokenUsageItem.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            className='token-usage-card-row'
                                        >
                                            <TableCell align='left'>{tokenUsageItem.id}</TableCell>
                                            <TableCell align='left'>{tokenUsageItem.itemName}</TableCell>
                                            <TableCell align='left'>{tokenUsageItem.tokensSpent}</TableCell>
                                            <TableCell align='left'>{formatISODateTim(tokenUsageItem.created_at)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color='inherit' />
                </Backdrop>
            </div>
        </div>
    );
};

export default TokenUsageCard;