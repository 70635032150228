import React from 'react';
import './NodeSlider.scss';
import NodeConfigTitle from '../nodeConfigTitle/NodeConfigTitle';
import Slider from '@mui/material/Slider';

interface Props {
    onChange?: (event: Event, value: number | number[]) => void
    value: number
    step: number
    min: number
    max: number
    onPointerEnter?: React.PointerEventHandler<HTMLTextAreaElement>
    onPointerLeave?: React.PointerEventHandler<HTMLTextAreaElement>
    title: string
    toolTipText?: string
    bottomMargin?: boolean
}

const NodeSlider: React.FC<Props> = ({ onChange, value, title, step, min, max, toolTipText, bottomMargin, onPointerEnter, onPointerLeave }) => {
    const incrementOnClick = () => {
        const newValue = Math.min(value + step, max);
        onChange?.(new Event('new'), parseFloat(newValue.toFixed(10)));
    };
    
    const decrementOnClick = () => {
        const newValue = Math.max(value - step, min);
        onChange?.(new Event('new'), parseFloat(newValue.toFixed(10)));
    };

    return (
        <div className={`${bottomMargin ? 'node-bottom-margin' : ''}`}>
            <div className='node-slider-container'>
                <div className='node-slider-title'>
                    <NodeConfigTitle
                        title={title}
                        toolTipText={toolTipText}
                    />
                </div>

                <Slider
                    className='node-slider-slider'
                    size='small'
                    aria-label={title}
                    value={value}
                    onChange={onChange}
                    getAriaValueText={(v) => `${v}`}
                    valueLabelDisplay='auto'
                    step={step}
                    min={min}
                    max={max}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                />
                <div className='node-slider-controller'>
                    <p>{value}</p>
                    <div>
                        <i className='bi bi-dash node-slider-step-btn' onClick={decrementOnClick} ></i>
                        <i className='bi bi-plus node-slider-step-btn' onClick={incrementOnClick} ></i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NodeSlider;
