import { useState } from 'react';
import ReactModal from 'react-modal';
import TextField from '../textfield/TextField';
import './ConfirmationForm.scss';
import { useTranslation } from 'react-i18next';
import AuthService from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import StyledLoadingButton from '../styledLoadingButton/StyledLoadingButton';

interface Props {
    isOpen: boolean;
    email?: string;
    password?: string;
    onClose?: () => void;
}

const Dialog: React.FC<Props> = ({ isOpen, email, password, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [input, setInput] = useState('');
    const [shouldShowInvalid, setShouldShowInvalid] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const isEnabled = () => {
        return email !== undefined && password !== undefined && input.length === 6;
    };

    const handleClose = () => {
        setInput('');
        onClose?.();
    };

    const confirmCode = async () => {
        setIsProcessing(true);
        if (email !== undefined && password !== undefined && input.length === 6) {
            try {
                setShouldShowInvalid(false);
                await AuthService.confirmSignUp(email, password, input);
                setIsProcessing(false);
                onClose?.();
                navigate('/');
            } catch (e) {
                setShouldShowInvalid(true);
                setIsProcessing(false);
            }
        }
    };

    return (
        <>
            <ReactModal className='reset-container' overlayClassName='reset-overlay' isOpen={isOpen} onRequestClose={handleClose}>
                <div className='reset-back-container'>
                    <i className='bi bi-x-lg reset-back' onClick={handleClose}></i>
                </div>
                <div className='reset-form'>
                    <h1 className='text-main'>Confirm Security Code</h1>
                    <label className='auth-text text-main'>{t('auth.resetPasswordMessage')}</label>
                    <div className='text-main form-title p-3'>Email</div>
                    <TextField icon='bi bi-lock icon' type='normal' input={input} placeholder='Security Code' hide={false} onChange={setInput} />
                    {shouldShowInvalid && <div className='reset-invalid-text'>Code invalid, please try again</div>}
                    <StyledLoadingButton
                        loading={isProcessing}
                        onClick={confirmCode}
                        loadingIndicator='Processing...'
                        disabled={!isEnabled()}
                    >
                        <span>{('Send')}</span>
                    </StyledLoadingButton>
                </div>
            </ReactModal>
        </>
    );
};

export default Dialog;
