import React, { Dispatch, SetStateAction } from 'react';
import './DataSourceCard.scss';

interface Props {
    setShowDataSourceForm:Dispatch<SetStateAction<any>>;
}
const DataSourceEmptyCard: React.FC<Props>= ({setShowDataSourceForm}) => {

    return (
        <button className='no-datasource dataSource-card-empty bg-white d-flex justify-content-center align-items-center fw-bold text-grey02'
        onClick={() => setShowDataSourceForm (true)} >
            <i className='bi bi-plus fw-bold text-grey02 pe-2'></i>
            <div>Create a new datasource</div>
        </button>
    );
};

export default DataSourceEmptyCard;
