import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchWorkspaceAsync, getDSStrings, getSelectedWorkspace, setDSStrings } from '../../../features/workspace/workspaceSlice';
import { DataSource, deleteDataSource, deleteStrings, DSString, fetchDataSource, fetchDataSourceRecordCount, fetchDataSourceRecords, fetchDataSources, fetchDataSourceUploadProgress, fetchMoreDataSourceRecords, updateDataSourceName } from '../../../services/BSCore';
import AddDSStringForm from '../../dialog/AddDSStringForm';
import DataSourceStringCard from '../dataSourceStringCard/DataSourceStringCard';
import './DataSourceContainerCard.scss';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import TextField from '../../textfield/TextField';
import { DataSourceType } from '../../dialog/DataSourceForm';
import DSYoutubeStringCard from '../dataSourceStringCard/DSYoutubeStringCard';
import AddYTStringForm from '../../dialog/AddYTStringForm';
import DeleteModel from '../../models/DeleteModel';
import GeneralAlert, { AlertType } from '../../dialog/GeneralAlert';

// enum SearchType {
//     comprehensive = 'Comprehensive',
//     exactly = 'Exactly'
// }

enum UploadState {
    none, preparing, uploading, success, failed
}

const DataSourceContainerCard: React.FC = () => {
    const { ds_id } = useParams();
    const dsIdRef = useRef<string>();
    dsIdRef.current = ds_id;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const workspace = useAppSelector(getSelectedWorkspace);
    const [datasource, setDatasource] = useState<DataSource>();
    const [showAddString, setShowAddString] = useState(false);
    const [showAddVideo, setShowAddVideo] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [uploading, setUploading] = useState<UploadState>(UploadState.none);
    const [progress, setProgress] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [newName, setNewName] = useState('');

    const [selectedStrings, setSelectedStrings] = useState(() => new Set<string>());

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.dsSuccess);

    // const [searched, setSearched] = useState<DSString[]>([]);
    // const [showSearch, setShowSearch] = useState(false);
    // const [searchType] = useState<SearchType>(SearchType.comprehensive);

    const [searchText, setSearchText] = useState('');
    const [stringCount, setStringCount] = useState('0');
    const [nextCursor, setNextCursor] = useState('');
    const dsStrings = useAppSelector(getDSStrings);

    useEffect(() => {
        dispatch(setDSStrings([]));
    }, [ds_id]);

    useEffect(() => {
        console.log('checking records');

        if (ds_id) {
            console.log('checking records 2');

            fetchDataSource(ds_id)
                .then(datasource => {
                    setDatasource(datasource);
                    setNewName(datasource.name);
                    
                    if (datasource.currentTask && datasource.currentTask.id) {
                        uploadCheck(''+datasource.id, datasource.currentTask.id, datasource.currentTask.state);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            fetchDataSourceRecords(ds_id, searchText)
                .then(records => {
                    console.log(records);
                    dispatch(setDSStrings(records.strings));
                    setNextCursor(records.next);
                    console.log('dsStrings ', dsStrings);
                    console.log('next', records.next);
                })
                .catch(error => {
                    console.log(error);
                });

            fetchRecordCount(ds_id);
        }
    }, [uploading, ds_id]);

    // useEffect(() => {
    //     if (searchText === '') {
    //         setShowSearch(false);
    //     }
    // }, [searchText]);

    useEffect(() => {
        if (ds_id) {
            fetchRecordCount(ds_id);
        }
    }, [dsStrings]);

    const formatedDate = () => {
        if (datasource) {
            const utc = new Date(datasource.updated_at);
            const offset = utc.getTimezoneOffset();
            const local = new Date(utc.getTime() + offset * 60000);

            const [date, time] = local.toISOString().split('T');
            const [year, month, day] = date.split('-');
            const newDate = `${month}/${day}/${year.substring(2, 4)}`;
            const [hour, minute] = time.split(':');
            
            let newTime = +hour > 12 ? `${+hour - 12}:${minute}pm` : `${hour}:${minute}am`;
            if (+hour > 12 && +hour - 12 < 10) { newTime = '0' + newTime; }

            return `${newDate} ${newTime}`;
        }
    };

    const searchStrings = () => {
        // if (dsStrings) {
        //     const results = dsStrings.filter(string => string.id.includes(searchText) || string.payload.text?.includes(searchText));
        //     setSearched(results);
        //     setShowSearch(true);
        // }
        refetchStrings();
    };

    const handleChange = (event: { target: { value: any; }; }) => {
        setSearchText(event.target.value);
    };

    const showDeleteDS = () => {
        setShowConfirmation(true);
    };

    const deleteDS = async () => {
        if (ds_id && workspace) {
            setShowConfirmation(false);
            setShowLoading(true);
            const data = await deleteDataSource(ds_id);

            console.log(data);
            dispatch(fetchWorkspaceAsync());

            const datasources = await fetchDataSources(workspace.id);

            setShowLoading(false);

            if (datasources && datasources.length > 0) {
                navigate(`/datasource/${datasources[0].id}`);
            }
        }
    };

    const appendStrings = async () => {
        console.log(nextCursor);
        if (ds_id && dsStrings && nextCursor !== '') {
            const list: DSString[] = [];
            dsStrings.map(string => (
                list.push(string)
            ));
            const moreStrings = await fetchMoreDataSourceRecords(ds_id, nextCursor, searchText);
            moreStrings.strings.map(string => (
                list.push(string)
            ));
            console.log('more strings', moreStrings);
            dispatch(setDSStrings(list));
            setNextCursor(moreStrings.next);
        }
    };

    const refetchStrings = async () => {
        if (ds_id) {
            console.log('checking records 2');

            const records = await fetchDataSourceRecords(ds_id, searchText);
            console.log(records);
            dispatch(setDSStrings(records.strings));
            setNextCursor(records.next);
            console.log('dsStrings ', dsStrings);
        }
    };

    const fetchRecordCount = async (ds_id: string) => {
        const { count } = await fetchDataSourceRecordCount(ds_id);
        setStringCount(count);
    };

    const updateDataSource = async () => {
        if (ds_id && newName !== datasource?.name) {
            const data = await updateDataSourceName(ds_id, newName);
            console.log(data);

            dispatch(fetchWorkspaceAsync());
            const datasource = await fetchDataSource(ds_id);
            setDatasource(datasource);
            setNewName(datasource.name);
        }

        setEditMode(false);
    };

    const selectString = (string: string) => {
        const strings = new Set(selectedStrings);
        strings.add(string);
        setSelectedStrings(strings);

        console.log('select test', strings, string);
    };

    const unselectString = (string: string) => {
        const strings = new Set(selectedStrings);
        strings.delete(string);
        setSelectedStrings(strings);

        console.log('unselect test', strings, string);
    };

    const resetSelection = () => {
        setSelectedStrings(new Set<string>());
    };

    const selectAllStrings = () => {
        const set = new Set<string>();
        dsStrings?.forEach(string => {
            set.add(string.id);
        });
        setSelectedStrings(set);

        console.log('select all test', selectedStrings);
    };

    const deleteSelectedStrings = async () => {
        if (ds_id && workspace) {
            setShowConfirmation(false);
            setShowLoading(true);

            const strings = Array.from(selectedStrings);
            console.log('ready to delete: ', ds_id, strings);
            const data = await deleteStrings(ds_id, strings);

            console.log(data);
            refetchStrings();

            setSelectedStrings(new Set<string>());
            setShowLoading(false);
        }    
    };

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    const uploadCheck = async (datasourceId: string, taskId: string, status: string) => {
        if (dsIdRef.current !== datasourceId) { 
            setUploading(UploadState.none);
        } else if (status === 'SUCCESS') {
            setAlertType(AlertType.dsSuccess);
            setShowAlert(true);

            await delay(3000);
            setUploading(UploadState.none);
            await delay(500);
            setProgress(0);
        } else if (status === 'FAILED') {
            setUploading(UploadState.none);
            setProgress(0);
            console.log('Upload failed');
            setAlertType(AlertType.dsError);
            setShowAlert(true);
        } else {
            const progress = await fetchDataSourceUploadProgress(taskId);
            console.log('progress ', progress.current, progress.total);
            console.log('state ', progress.state);

            if (progress.current && progress.total) {
                const fraction = progress.current / progress.total;
                const percentage = fraction * 100;
                const rounded = Math.round((percentage + Number.EPSILON) * 100) / 100;
                
                setUploading(UploadState.uploading);
                setProgress(rounded);
            } else {
                setUploading(UploadState.preparing);
            }

            await delay(500);
            uploadCheck(datasourceId, taskId, progress.state);
        }
    };

    return (
        <div className='ds-cc-Container'>
            <div className='ds-cc-topbar-container'>
                {editMode ?
                    <div className='ds-cc-topbar-edit' >
                        <TextField className='textfield' icon='' type='standard' input={newName} placeholder='' hide={false} onChange={setNewName} />
                        <button onClick={() => setEditMode(false)}>Cancel</button>
                        <button onClick={updateDataSource}>Save</button>
                    </div> :
                    <div className='ds-cc-topbar-info'>
                        <div className='ds-cc-topbar-info-title'>
                            <h1 className='text-main'>{datasource?.name}</h1>
                            <button onClick={() => setEditMode(true)}><div className = 'edit-btn'></div></button>
                        </div>
                        <h2 className='text-secondary'>ID# {datasource?.id}</h2>
                    </div>
                }
                <div className='ds-cc-topnar-action'>
                    {uploading === UploadState.uploading || uploading === UploadState.preparing ?
                        <>
                            {/* <button className='ds-cc-upload-delete'>
                                Delete
                            </button> */}

                            <div className='ds-cc-progress-bg'>
                                <div className={`ds-cc-progress-loading ${progress === 100 ? 'finish' : ''}`} style={{width: `${progress}%`}}/>
                                
                                {uploading === UploadState.preparing &&
                                    <div className='ds-cc-progress-preparing' >
                                        <i className='' />
                                        Processing...
                                    </div>
                                }
                                
                                {uploading === UploadState.uploading &&
                                    <div className={`ds-cc-progress ${progress === 100 ? 'finish' : ''}`}>
                                        <i className='bi bi-cloud-upload '/>
                                        <h1>uploading...{progress}% </h1>
                                    </div>
                                }
                            </div>
                        </> :
                        <>
                            <button className='btn btn-link text-danger' onClick={showDeleteDS}>
                                Delete
                            </button>

                            {datasource?.type === DataSourceType.youtube ?
                                <div>
                                    <button className='btn btn-primary font-weight-bold' onClick={() => setShowAddVideo(true)}>
                                        <i className='bi bi-pencil-square me-2'></i> Add Video
                                    </button>
                                </div> :
                                <div>
                                    <button className='btn btn-primary font-weight-bold' onClick={() => setShowAddString(true)}>
                                        + Add String
                                    </button>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>

            <div className='ds-cc-main-container'>
                <div className='ds-cc-main-info'>
                    <h1>Last Updated:&nbsp;</h1><h2>{formatedDate()}</h2>
                </div>
                <div className='ds-cc-main-page-container'>
                    <div className='ds-cc-main-page-info'>
                        <h1>Total&nbsp;</h1>
                        <h2>{stringCount}</h2>
                        <h1>&nbsp;strings</h1>
                        <h1>&nbsp;&nbsp;|&nbsp;&nbsp;</h1>
                        <h2>150</h2><h1>&nbsp;per page</h1>
                    </div>
                </div>
                <div className='ds-cc-main-page-search'>
                    {/* <button className='ds-search-button-left'>{searchType} <i className='bi bi-chevron-down' /></button> */}
                    <input value={searchText} placeholder='Enter Keyword "father" or "family" ' onChange={handleChange} />
                    <button className='ds-search-button-right' onClick={searchStrings}>Search</button>
                </div>

                { selectedStrings.size > 0 &&
                    <div className='ds-cc-main-page-select-strings-container'>
                        <h1>Selected {selectedStrings.size} strings</h1>

                        <div className='ds-cc-main-page-select-strings-content'>
                            <button className='select-strings-normal-button' onClick={ resetSelection }>Reset</button>
                            <button className='select-strings-normal-button' onClick={ selectAllStrings }>Select All</button>
                            <button className='select-strings-styled-button' onClick={ deleteSelectedStrings }><i className='bi bi-trash'/> Remove All Selected</button>
                        </div>
                    </div>
                }

                <div className='ds-sc-content-container'>
                    {/* {!showSearch && dsStrings ? */}
                    {dsStrings &&
                        dsStrings.map(string =>
                            datasource?.type === DataSourceType.youtube ?
                                <DSYoutubeStringCard 
                                    record={string} 
                                    isSelected={selectedStrings.has(string.id)}
                                    refetch={refetchStrings} 
                                    select={selectString}
                                    unselect={unselectString}
                                /> :
                                <DataSourceStringCard 
                                    record={string} 
                                    isSelected={selectedStrings.has(string.id)}
                                    refetch={refetchStrings} 
                                    select={selectString} 
                                    unselect={unselectString}
                                />
                        )
                    }
                    {/* :
                        searched.map(string =>
                            datasource?.type === DataSourceType.youtube ?
                                <DSYoutubeStringCard record={string} refetch={refetchStrings} /> :
                                <DataSourceStringCard record={string} refetch={refetchStrings} />
                        )
                    } */}
                </div>
            </div>

            {nextCursor && nextCursor !== '' &&
                <button className='ds-sc-content-load-more' onClick={appendStrings}>Load more</button>
            }

            <AddDSStringForm isOpen={showAddString} uploading={uploadCheck} onClose={() => setShowAddString(false)} />

            <AddYTStringForm isOpen={showAddVideo} uploading={uploadCheck} onClose={() => setShowAddVideo(false)} />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>

            <DeleteModel
                showDeleteModal={showConfirmation}
                title='Delete Data Source?'
                content={`All the data associated with this data source will be erased forever if you delete it. Are you sure you want to delete data source ${datasource?.name}?`}
                onClose={() => setShowConfirmation(false)}
                handleDelete={deleteDS}
            />

            <GeneralAlert isOpen={showAlert} type={alertType} onClose={() => setShowAlert(false)} />
        </div>
    );
};

export default DataSourceContainerCard;