import { useState } from 'react';
import ReactModal from 'react-modal';
import TextField from '../textfield/TextField';
import './ForgotPasswordForm.scss';
import { useTranslation } from 'react-i18next';
import AuthService from '../../services/AuthService';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';

interface Props {
    isOpen: boolean;
    onClose?: () => void;
}

const ForgotPasswordForm: React.FC<Props> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState('');

    const handleClose = () => {
        setEmail('');
        setCode('');
        setPassword('');
        setShowForm(false);
        onClose?.();
    };

    const isEnable = () => {
        if (showForm) {
            return code.length === 6 && password !== '';
        } else {
            return email !== '';
        }
    };
 
    const dialogAction = async () => {
        if (!isEnable()) { return; }

        setShowLoading(true);
        try {
            if (showForm) {
                await AuthService.forgotPasswordConfirm(email, code, password);
                onClose?.();
                setShowLoading(false);
            } else {
                await AuthService.forgotPassword(email);
                setShowForm(true);
                setShowLoading(false);
            }
        } catch (error) {
            console.log(error);
            const errorCode = (error as Error).message;
            setShowErrorMessage(errorCode);
            setShowLoading(false);
        }
        
    };
    
    return (
        <>
            <ReactModal className='fpForm-container' overlayClassName='fpForm-overlay' isOpen={ isOpen } onRequestClose={ handleClose }>
                <div className='fpForm-back-container'>
                    <i className='bi bi-x-lg fpForm-back' onClick={ handleClose }></i>
                </div>
                <div className='fpForm-form'>
                    <h1 className='text-main'>{t('auth.resetPassword')}</h1>

                    <label className='auth-text text-main'>{t('auth.resetPasswordMessage')}</label>
                    {
                        showErrorMessage !== '' &&
                        <div className='text-danger'>{showErrorMessage}</div>
                    }
                    <div className='text-main form-title p-3'>Email</div>
                    {showForm ?
                        <div>
                            <TextField icon='bi bi-lock icon' type='code' input= { code } placeholder={t('auth.code')} hide={false} onChange={ setCode } />
                            <TextField icon='bi bi-lock icon' type='password' input= { password } placeholder={t('auth.newPassword')} hide={true} onChange={ setPassword } />
                        </div> :
                        <TextField icon='bi bi-envelope icon' type='email' input= { email } placeholder={t('auth.email')} hide={false} onChange={ setEmail } />
                    }
                    <div className={`form-button ${ isEnable() ? 'enable' : 'disable'}`} onClick={ dialogAction }>{t('auth.send')}</div>
                </div>
            </ReactModal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </>
    );
};

export default ForgotPasswordForm;
