import React, { useState, useEffect, ChangeEvent, Dispatch, SetStateAction } from 'react';
import './OrganizationTeam.scss';
import { useAppSelector } from '../../../app/hooks';
import { getSelectedWorkspace } from '../../../features/workspace/workspaceSlice';
import { fetchWorkspace } from '../../../services/BSCore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { addWorkspaceMember, deleteWorkspaceMember } from '../../../services/BSCore';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { Backdrop, CircularProgress, NativeSelect } from '@mui/material';
import { formatISODateTim } from '../../../utils';

interface MemberTableData {
    id: number,
    imageUrl: string,
    name: string,
    email: string,
    created_at:string,
    roleOriginal: 'owner' | 'admin' | 'developer',
    role: 'owner' | 'admin' | 'developer',
    editing: boolean
}
interface OrganizationTeamProps {
    setActiveTab?:Dispatch<SetStateAction<any>>;
}

const OrganizationTeam: React.FC<OrganizationTeamProps> = ({setActiveTab}) => {
    const workspace = useAppSelector(getSelectedWorkspace);
    const [memberList, setMemberList] = useState<MemberTableData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);
    const [addMemberDialogAddDisabled, setAddMemberDialogAddDisabled] = useState(true);
    const [addMemberEmail, setAddMemberEmail] = useState('');
    const [addMemberRole, setAddMemberRole] = useState('admin');
    const [showMemberNotFoundError, setShowMemberNotFoundError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const workspaceId = useAppSelector(getSelectedWorkspace)?.id;

    useEffect(() => {
        setAddMemberDialogAddDisabled(!!!addMemberEmail);
        setShowMemberNotFoundError(false);
        refetchMembersData();
        console.log('asdfkasljdhfawekjfhaslkdjfhasdkjfhasjdfhaskdjfaskdfjkashdfkasdjhf',memberList);
    }, [workspace,addMemberEmail]);

    const handleAddMemberAddOnClick = async () => {
        if (!workspaceId) return;

        try {
            setShowLoading(true);

            // Cant add member that already exist in WS
            if (memberList.find(m => m.email === addMemberEmail)) throw new Error('Member exist in WS');

            await addWorkspaceMember(workspaceId, addMemberEmail, addMemberRole);
            refetchMembersData();
            setShowAddMemberDialog(false);
            setShowLoading(false);
        } catch (e) {
            console.error('Adding member failed!', e);
            setShowMemberNotFoundError(true);
            setShowLoading(false);
        }
    };

    const handleDeleteMemberOnClick = async (memberId: number) => {
        if (!workspaceId) return;

        try {
            setShowLoading(true);
            await deleteWorkspaceMember(workspaceId, memberId);
            refetchMembersData();
            setShowLoading(false);
        } catch (e) {
            console.error('Remove member failed!', e);
            setShowLoading(false);
        }
    };

    const refetchMembersData = () => {
        if (!workspace) return;
        fetchWorkspace(workspace.id)
            .then(w => {
                console.log('here we get w',w);
                setMemberList(
                    w.memberList.map(m => {
                        return {
                            id: m.id || 0,
                            imageUrl: m.Member?.info?.imageUrl || '',
                            name: m.Member?.name || '',
                            email: m.Member?.email,
                            created_at: m.created_at,
                            roleOriginal: m.teamMemberRole || '',
                            role: m.teamMemberRole || '',
                            editing: false
                        };
                    }));
                setIsLoading(false);
            })
            .catch(e => { console.error('Fetch workspace failed', e); setIsLoading(false); });
    };

    const getSkeletonComponent = () => {
        return <><div className='workspace-mg-card-skeleton-wrapper'>
            <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
        </div>
            <div className='workspace-mg-card-skeleton-wrapper'>
                <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation='wave' height={10} width='80%' />
            </div>
            <div className='workspace-mg-card-skeleton-wrapper'>
                <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation='wave' height={10} width='80%' />
            </div>
            <div className='workspace-mg-card-skeleton-wrapper'>
                <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation='wave' height={10} width='80%' />
            </div>
            <div className='workspace-mg-card-skeleton-wrapper'>
                <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation='wave' height={10} width='80%' />
            </div>
        </>;
    };

    //editSingleUser
    interface MemberMenuAnchorElState {
        [key: number]: HTMLElement | null;
    }
    const [memberMenuAnchorEls, setMemberMenuAnchorEls] = useState<MemberMenuAnchorElState>({});
    const handleEditCurrentUserClick = (event: React.MouseEvent<HTMLButtonElement>, memberId: number) => {
        setMemberMenuAnchorEls({
            ...memberMenuAnchorEls,
            [memberId]: event.currentTarget
        });
    };
    const handleEditCurrentUserClose = (memberId: number) => {
        setMemberMenuAnchorEls({
            ...memberMenuAnchorEls,
            [memberId]: null
        });
    };

    const handleEditUsersClose = () => {
        // setAnchorEditUsers(null);
    };
    // const leaveWorkspace=()=>{
    //     setActiveTab && setActiveTab('home');
    // };
    
    return (
        <div>
            <div className='d-flex justify-content-end'>
                {/* <button className='btn btn-link text-decoration-none' onClick={()=>leaveWorkspace()}>
                    <i className='bi bi-box-arrow-right pe-2'></i> Leave
                </button> */}
                <button className='btn btn-link text-decoration-none' onClick={() => { setShowAddMemberDialog(true); handleEditUsersClose(); }}>
                    <i className='bi bi-person-add pe-2' ></i>Invite
                </button>

            </div>
            <div className='workspace-mg-card-container'>
                {
                    isLoading ? getSkeletonComponent() : <TableContainer component={Paper}>
                        <Table className='workspace-mg-card-table'>
                            <TableHead>
                                <TableRow sx={{
                                    '& th': {
                                        backgroundColor: '#64748B',
                                        color: 'white'
                                    }
                                    }}>
                                    <TableCell className='fw-bold' align='left'>Member ID#</TableCell>
                                    <TableCell className='fw-bold' align='left'>Full name</TableCell>
                                    <TableCell className='fw-bold' align='left'>Email Address</TableCell>
                                    <TableCell className='fw-bold' align='left'>Join date</TableCell>
                                    <TableCell className='fw-bold' align='left'>Role</TableCell>
                                    <TableCell className='fw-bold' align='left'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {memberList.map((member) => (
                                    <TableRow
                                        key={member.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        className='workspace-mg-card-row'
                                    >
                                        <TableCell align='left'>{member.id}</TableCell>
                                        <TableCell align='left'>{member.name}</TableCell>
                                        <TableCell align='left'>{member.email}</TableCell>
                                        <TableCell align='left'>{member?.created_at ? formatISODateTim(member?.created_at):''}</TableCell>
                                        <TableCell align='left'>{member.role}</TableCell>
                                        <TableCell align='left'>{member.role !== 'owner' && <div>
                                            <Button
                                                id={`edit-user-button-${member.id}`}
                                                aria-controls={memberMenuAnchorEls[member.id] ? 'edit-user-menu' : undefined}
                                                aria-haspopup='true'
                                                aria-expanded={memberMenuAnchorEls[member.id] ? 'true' : undefined}
                                                onClick={event => handleEditCurrentUserClick(event, member.id)}
                                            ><MoreHorizIcon className='edit-user-button-icon' /></Button>
                                            <Menu
                                                anchorEl={memberMenuAnchorEls[member.id]}
                                                open={Boolean(memberMenuAnchorEls[member.id])}
                                                onClose={() => handleEditCurrentUserClose(member.id)}
                                                MenuListProps={{
                                                    'aria-labelledby': 'edit-user-button',
                                                }}
                                            >
                                                <MenuItem className='menu-item-container' onClick={() => { handleDeleteMemberOnClick(member.id); handleEditCurrentUserClose(member.id); }}><PersonRemoveIcon /><div>Remove this user</div></MenuItem>
                                            </Menu>
                                        </div >}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div>
            <Dialog
                open={showAddMemberDialog}
                onClose={() => setShowAddMemberDialog(false)}
            >
                <DialogTitle>
                    Add member
                </DialogTitle>
                <DialogContent
                    className='workspace-mg-add-dialog-content'
                >
                    <TextField
                        autoFocus
                        margin='dense'
                        id='name'
                        label='Invitee Email'
                        type='email'
                        fullWidth
                        variant='standard'
                        value={addMemberEmail}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setAddMemberEmail(event.target.value);
                        }}
                    />
                    <NativeSelect
                        value={addMemberRole}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            setAddMemberRole(event.target.value);
                        }}
                        variant='outlined'
                        className='workspace-mg-add-dialog-role'
                        disableUnderline
                    >
                        <option value='admin'>Admin</option>
                        <option value='developer'>Developer</option>
                    </NativeSelect>
                </DialogContent>
                {showMemberNotFoundError && <p className='workspace-mg-add-dialog-email-error'>You are not permitted to add this user</p>}
                <DialogActions>
                    <Button onClick={() => setShowAddMemberDialog(false)}>Cancel</Button>
                    <Button onClick={handleAddMemberAddOnClick} disabled={addMemberDialogAddDisabled} autoFocus>Add</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </div>

    );
};

export default OrganizationTeam;