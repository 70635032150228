import React, { useEffect, useState } from 'react';
import './BotsDetails.scss';
import SidebarComponent from '../../components/shared/main/Sidebar/SidebarComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import BSNav, { NavType } from '../../components/navbar/BSNav';
import DataSourceContainerCard from '../../components/cards/dataSourceContainerCard/DataSourceContainerCard';
import HomepageFootnote from '../../components/homepageFootnote/HomepageFootnote';
import BotsDetailContainer from '../../components/botsDetailContainer/BotsDetailContainer';
import { deleteBot, fetchBots } from '../../services/BSCore';
import { setBots, getSelectedWorkspace, getDatasources, getBots } from '../../features/workspace/workspaceSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DataSourceEmptyCard from '../../components/cards/dataSourceContainerCard/DatasourceEmptyCard';
import MyProjectEmptyCard from '../../components/botsDetailContainer/MyProjectEmptyCard';

const BotsDetail: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { ds_id, bot_id } = useParams();
    const [activeTab, setActiveTab] = useState('my-project');
    const workspaceId = useAppSelector(getSelectedWorkspace)?.id;
    const datasources = useAppSelector(getDatasources);
    // const botId = useAppSelector(getSelectedBot);
    const bots = useAppSelector(getBots);

    useEffect(() => {
        // Fetch bots data when the component mounts
        fetchBots(workspaceId!)
            .then(bots => {
                setBots(bots); // Update the bots state
            })
            .catch(error => {
                console.log(error);
            });
    }, [workspaceId]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, [navigate]);

    useEffect(() => {
        if (bot_id) {
            setActiveTab('my-project');
        } else {
            setActiveTab('data-source');
        }
    }, [bot_id, ds_id]);

    const handleBotDelete = () => {
        deleteBot(Number(bot_id))
            .then(() => {
                fetchBots(workspaceId!)
                    .then(bots => {
                        console.log(bots);
                        if (bots.length > 0) {
                            dispatch(bots);
                            navigate(`/bot/${bots[0].id}`);
                        } else {
                            navigate(`/`);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    };

    const [leftSideContainerVisible, setLeftSideContainerVisible] = useState(
        window.innerWidth > 768
    );

    const toggleLeftSideContainer = () => {
        setLeftSideContainerVisible(!leftSideContainerVisible);
    };

    const handleMenuIconClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        toggleLeftSideContainer();
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setLeftSideContainerVisible(false);
            } else {
                setLeftSideContainerVisible(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleScreenClick = () => {
        if (window.innerWidth <= 768 && leftSideContainerVisible) {
            setLeftSideContainerVisible(false);
        }
    };

    return (
        <div className='details-content-bg' onClick={handleScreenClick}>
            <div className='details-content-container container'>
                <div
                    className={`details-left-side-container ${
                        leftSideContainerVisible ? 'visible' : 'hidden'
                    }`}
                >
                    <SidebarComponent activeTab={activeTab} setActiveTab={setActiveTab} />
                </div>

                <div className='details-right-side-container'>
                    <div
                        className={`details-nav ${
                            window.innerWidth <= 768 ? 'small-screen' : 'large-screen'
                        }`}
                    >
                        {window.innerWidth <= 768 && (
                            <div className='menu-toggle' onClick={handleMenuIconClick}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                >
                                    <path d='M0 0h24v24H0z' fill='none' />
                                    <path d='M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z' />
                                </svg>
                            </div>
                        )}
                        <BSNav type={NavType.standard} />
                    </div>
                    <div className='details-right-side-content'>
                        <div>
                            {activeTab === 'my-project' ? (
                                 bots && bots.length > 0 ? (
                                     <BotsDetailContainer handleBotDelete={handleBotDelete} />
                                    ) : (
                                        <MyProjectEmptyCard />
                                    )
                            ) : (
                                <>
                                    {datasources && datasources.length > 0 ? (
                                        <DataSourceContainerCard />
                                    ) : (
                                        <DataSourceEmptyCard />
                                    )}
                                </>
                            )}
                        </div>
                        <div className='details-right-side-footnote'>
                            <HomepageFootnote roundCorners={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BotsDetail;