import { ChangeEvent, useState } from 'react';
import ReactModal from 'react-modal';
import TextField from '../textfield/TextField';
import './DataSourceForm.scss';
import { createDataSource, DataSource, NetworkError } from '../../services/BSCore';
import { Auth } from 'aws-amplify';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import { fetchWorkspaceAsync, getSelectedWorkspace } from '../../features/workspace/workspaceSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import GeneralAlert, { AlertType } from './GeneralAlert';
import BSToolTip from '../toolTip/TooTip';

interface Props {
    isOpen: boolean;
    onClose?: () => void;
}

export enum DataSourceType {
    qdrant = 'qdrant',
    youtube = 'youtube',
    chatHistory = 'bot_chat'
}

const DataSourceForm: React.FC<Props> = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [name, setName] = useState('');
    const [dsType, setDSType] = useState<DataSourceType>(DataSourceType.qdrant);
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const workspaceId = useAppSelector(getSelectedWorkspace)?.id;

    const handleClose = () => {
        setName('');
        setDSType(DataSourceType.qdrant);
        onClose?.();
    };

    const isEnable = () => {
        return name !== '';
    };

    const create = async () => {
        await Auth.currentUserInfo();

        if (workspaceId) {
            const dataSource = {
                workspaceId: workspaceId,
                name: name,
                type: dsType
            };

            try {
                const data = await createDataSource(dataSource);
                dispatch(fetchWorkspaceAsync());  
                return data as DataSource;   
            } catch (error) {
                const err = error as NetworkError;

                if (err.response.status === 402) {
                    handleClose();
                    setShowAlert(true);
                }
            }       
        }
    };

    const setType = (event: ChangeEvent<HTMLSelectElement>) => {
        console.log('event ', event);
        const choice = event.target.value;
        const type: DataSourceType = DataSourceType[choice as keyof typeof DataSourceType];
        setDSType(type);
    };

    const dialogAction = async () => {
        if (!isEnable()) { return; }

        setShowLoading(true);

        const data = await create();
        console.log('create ds', data);
        handleClose();

        setShowLoading(false);
    };

    return (
        <>
            <ReactModal className='dataSourceForm-container' overlayClassName='dataSourceForm-overlay' isOpen={isOpen} onRequestClose={handleClose}>
                <div className='dataSourceForm-back-container'>
                    <i className='bi bi-x-lg wsForm-back p-2' onClick={handleClose}></i>
                </div>
                <div className='dataSourceForm-form'>
                    <div className='dataSourceForm-back-container'>
                        <i className='bi bi-x-lg wsForm-back p-2' onClick={handleClose}></i>
                    </div>
                    <h1 className='text-main'>Create a new Data Source</h1>

                    <label className='auth-text text-main'>This is going to be amazing!</label>

                    <TextField  icon='' type='standard' input={name} placeholder='Name' hide={false} onChange={setName} />

                    <BSToolTip marginTop={-6} title='Connect to Qdrant (a vector search engine) or connect to YouTube video content'>
                        <div className='dataSourceForm-Type-Choices-Container'>
                            <select className='form-select' onChange={ setType } aria-label='Select Data Source'>
                                {
                                    Object.values(DataSourceType).filter(type => type !== DataSourceType.chatHistory).map(type =>
                                        <option value={type} >{ type }</option>
                                    )
                                }
                            </select>
                        </div>
                    </BSToolTip>
                    
                    <div className={`form-button ${isEnable() ? 'enable' : 'disable'}`} onClick={dialogAction}>Create</div>
                </div>
            </ReactModal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
            <GeneralAlert 
                isOpen={showAlert} 
                type={AlertType.insufficientCredit} 
                title={'Insufficient Tokens'} 
                message={'There are not enough tokens available to create a data source'} 
                secondaryAction={ () => navigate('/profile/credits') }
                onClose={() => setShowAlert(false)}
            />
        </>
    );
};

export default DataSourceForm;
