import React, { useEffect, useState } from 'react';
import './LeftPanelComponent.scss';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Divider } from '@mui/material';

import home from '../../../../assets/icon/home.svg';
import homeGrey from '../../../../assets/icon/home-grey.svg';

import dataSource from '../../../../assets/icon/dataSource.svg';
import dataSourceGrey from '../../../../assets/icon/dataSource-grey.svg';

import project from '../../../../assets/icon/project.svg';
import projectGrey from '../../../../assets/icon/project-grey.svg';

import organization from '../../../../assets/icon/organization.svg';
import organizationGrey from '../../../../assets/icon/organization-grey.svg';

import wechat from '../../../../assets/icon/wechat.svg';
import wechatGrey from '../../../../assets/icon/wechat-grey.svg';

import email from '../../../../assets/icon/email.svg';
import emailGrey from '../../../../assets/icon/email-grey.svg';

import templates from '../../../../assets/icon/templates.svg';
import templatesGrey from '../../../../assets/icon/templates-grey.svg';

import share from '../../../../assets/icon/share.svg';
import shareGrey from '../../../../assets/icon/share-grey.svg';
import Modal from 'react-bootstrap/Modal';

interface LeftPanelComponentProps {
    activeTab: string;
    setActiveTab: (tabId: string) => void;
}

const LeftPanelComponent: React.FC<LeftPanelComponentProps> = ({ activeTab, setActiveTab }) => {
    const [emailActive, setEmailActive] = useState(false);
    const [shareActive, setShareActive] = useState(false);
    const [wechatActive, setWechatActive] = useState(false);
    const [showWechatModal, setShowWechatModal] = useState(false);

    const navigate = useNavigate();
    const handleMenuItemClick = (tabId: string) => {
        setActiveTab(tabId); 
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(result => {
                if (result) {
                    console.log('authenticated');
                } else {
                    console.log('not authenticated');
                }
            })
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, []);

    return (
        <div className='side-bar-content-container border-end'>
            <ul className = 'main-menu'>
                <li className={`d-flex align-items-center menu-item ${activeTab==='home'?'active':''}`}
                onClick={() => handleMenuItemClick('home')}>
                    {activeTab==='home'?<img className = 'menu-icon' alt='' src ={home}/>:<img className = 'menu-icon' alt='' src ={homeGrey}/>}
                    <div>Home</div>
                </li>
                <li className={`d-flex align-items-center menu-item ${activeTab==='my-project'?'active':''}`}
                onClick={() => handleMenuItemClick('my-project')}>
                    {activeTab==='my-project'?<img className = 'menu-icon' alt='' src ={project}/>:<img className = 'menu-icon' alt='' src ={projectGrey}/>}
                    <div>My Project</div>
                </li>
                <li className={`d-flex align-items-center menu-item ${activeTab==='data-source'?'active':''}`}
                onClick={() => handleMenuItemClick('data-source')}>
                    {activeTab==='data-source'?<img className = 'menu-icon' alt='' src ={dataSource}/>:<img className = 'menu-icon' alt='' src ={dataSourceGrey}/>}
                    <div>Data source</div>
                </li>
                <li className={`d-flex align-items-center menu-item ${activeTab==='templates'?'active':''}`}
                onClick={() => handleMenuItemClick('templates')}>
                    {activeTab==='templates'?<img className = 'menu-icon' alt='' src ={templates}/>:<img className = 'menu-icon' alt='' src ={templatesGrey}/>}
                    <div>Templates</div>
                </li>
                <Divider sx={{borderColor:'#E4E4E4', marginTop:'16px'}}/>
                <li className={`d-flex align-items-center menu-item ${activeTab==='organization'?'active':''}`}
                onClick={() => handleMenuItemClick('organization')}>
                    {activeTab==='organization'?<img className = 'menu-icon' alt='' src ={organization}/>:<img className = 'menu-icon' alt='' src ={organizationGrey}/>}
                    <div>Organization</div>
                </li>
            </ul>
            
            

            <ul className='question'>
                {/* <ul className='qr-code justify-content-center text-center pb-4'>
                    <img className='w-100' src='https://botsqure-service-resources.s3.us-west-2.amazonaws.com/quanyong-bs-chinese-contact-wechat-qr.png' alt=''></img>
                    <div className='text-main'>Ready to talk? Let's connect on WeChat!</div>
                </ul> */}
                <div className='fw-bold text-main pt-4'>Any Question?</div>
                <li>
                    <button className={`question-button bg-white ${wechatActive? 'text-main':'text-grey'} border d-flex align-items-center justify-content-between text-decoration-none`}
                    onClick={()=>setShowWechatModal(true)}
                    onMouseEnter={() => setWechatActive(true)}
                    onMouseLeave={() => setWechatActive(false)}>
                        <div>Wechat</div>
                        <img className='menu-icon p-0' alt='' src={wechatActive ? wechat : wechatGrey} />
                    </button>
                </li>
                <li>
                    <a className='question-button border d-flex align-items-center justify-content-between text-decoration-none' href='mailto:support@botsquare.com'
                    onMouseEnter={() => setEmailActive(true)}
                    onMouseLeave={() => setEmailActive(false)}>
                        <div>Email us</div>
                        <img className='menu-icon' alt='' src={emailActive ? email : emailGrey} />
                    </a>
                </li>
                <li>
                    <a className='question-button border d-flex align-items-center justify-content-between text-decoration-none' href='https://botsquare.gitbook.io/botsquare' target='_blank' rel='noopener noreferrer'
                    onMouseEnter={() => setShareActive(true)}
                    onMouseLeave={() => setShareActive(false)}>
                        <div>Documentation</div>
                        <img className='menu-icon' alt='' src={shareActive ? share : shareGrey} />
                    </a>
                </li>
            </ul>
            <>
                <Modal centered show={showWechatModal}>
                    <Modal.Body>
                        <div className='text-center text-main d-flex flex-column justify-content-center align-items-center p-4'>
                            <img className='w-50 pb-2' src='https://botsqure-service-resources.s3.us-west-2.amazonaws.com/quanyong-bs-chinese-contact-wechat-qr.png' alt='' />
                            <h4>Talk to us on Wechat</h4>
                            <p>Scan QR code and talk to us on WeChat</p>
                            <button type='button' className='btn btn-primary' onClick={() => setShowWechatModal(false)}>Close</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        </div>
    );
};

export default LeftPanelComponent;