export enum BSENV {
    prod = 'prod',
    qa = 'qa',
    local = 'local'
}

interface Config {
    BS_CORE_URL: string
    COMPANY_LANDING_URL: string
    CONTACT_EMAIL: string
    STREAM_API_KEY: string
    GALLERY_URL: string
    TEMPLATE_BLACK: [number]
}

// Current ENV
// export let env: BSENV = BSENV.local;
// export let env: BSENV = BSENV.qa;
export let env: BSENV = BSENV.prod;

// Build flag override
if (process.env.REACT_APP_STAGE === 'qa') {
    env = BSENV.qa;
} else if (process.env.REACT_APP_STAGE === 'prod') {
    env = BSENV.prod;
} 

const config_local: Config = {
    BS_CORE_URL: 'http://localhost:4010',
    COMPANY_LANDING_URL: 'https://www.botsquare.ai/',
    CONTACT_EMAIL: 'info@botsquare.ai',
    STREAM_API_KEY: 'nth7dgef8bw5',
    GALLERY_URL: 'https://d1domwlgv3o98m.cloudfront.net',
    TEMPLATE_BLACK: [11]
};

const config_qa: Config = {
    BS_CORE_URL: 'https://d21u05om9g7lvp.cloudfront.net',
    COMPANY_LANDING_URL: 'https://www.botsquare.ai/',
    CONTACT_EMAIL: 'info@botsquare.ai',
    STREAM_API_KEY: 'nth7dgef8bw5',
    GALLERY_URL: 'https://d1domwlgv3o98m.cloudfront.net',
    TEMPLATE_BLACK: [11]
};

const config_prod: Config = {
    BS_CORE_URL: 'https://api.botsquare.net',
    COMPANY_LANDING_URL: 'https://www.botsquare.ai/',
    CONTACT_EMAIL: 'info@botsquare.ai',
    STREAM_API_KEY: 'xubfgzjxt635',
    GALLERY_URL: 'https://bot.botsquare.ai',
    TEMPLATE_BLACK: [11]
};

let selected_config: Config;
switch (env as BSENV) {
    case BSENV.local:
        selected_config = config_local;
        break;
    case BSENV.qa:
        selected_config = config_qa;
        break; 
    case BSENV.prod:
        selected_config = config_prod;
        break; 
}

export default selected_config;
