import './ConfirmationBanner.scss';

interface Props {
    showUpdateMessage: boolean;
    updated: string;
}

const ConfirmationBanner: React.FC<Props> = ({ showUpdateMessage, updated }) => {
    return(
        <>
            { showUpdateMessage &&
                <div className='confirmation-message'>
                    <i className='bi bi-check-circle' /> {updated} updated successfully!
                </div>
            }
        </>
    );
};

export default ConfirmationBanner;