import React from 'react';
import './SubscriptionCard.scss';
import SubscriptionChoiceCard from '../subscriptionChoiceCard/SubscriptionChoiceCard';
import { purchaseCredit } from '../../../services/BSCore';
import { useAppSelector } from '../../../app/hooks';
import { getSelectedWorkspace } from '../../../features/workspace/workspaceSlice';
import config from '../../../config';
const { CONTACT_EMAIL } = config;

enum BSPurchaseAmount {
    tier1 = 500,
    tier2 = 1000,
    tier3 = 1500
}

const SubscriptionCard: React.FC = () => {
    const workspace = useAppSelector(getSelectedWorkspace);

    const haneleRefill = async (amount: BSPurchaseAmount) => {
        try {
            if (!workspace?.id) throw new Error('Invalid workspace info');

            // Redirect to payment page
            const { paymentUrl } = await purchaseCredit(workspace?.id, amount);
            window.location.href = paymentUrl;
        } catch (e) {
            console.error('Failed to refill', e);
        }
    };

    return (
        <div className='subscription-right-content-container'>
            <SubscriptionChoiceCard
                current={false}
                title={'500K Credits'}
                description={''}
                price={'4.99'}
                features={
                    [
                        'Access to all BotSquare features',
                        'Enough credits to explore Bots, AI Modules, DataSources and many more'
                    ]
                }
                actionTitle='Refill now'
                action={() => haneleRefill(BSPurchaseAmount.tier1)}
            />

            <SubscriptionChoiceCard
                current={false}
                title={'1000K Credits'}
                description={''}
                price={'9.99'}
                features={
                    [
                        'Access to all BotSquare features',
                        'Enough credits to explore Bots, AI Modules, DataSources and many more'
                    ]
                }
                actionTitle='Refill now'
                action={() => haneleRefill(BSPurchaseAmount.tier2)}
            />

            <SubscriptionChoiceCard
                current={false}
                title={'1500K Credits'}
                description={''}
                price={'14.99'}
                features={
                    [
                        'Access to all BotSquare features',
                        'Enough credits to explore Bots, AI Modules, DataSources and many more'
                    ]
                }
                actionTitle='Refill now'
                action={() => haneleRefill(BSPurchaseAmount.tier3)}
            />

            <SubscriptionChoiceCard
                current={false}
                title={'Enterprise'}
                description={'The enterprise version is a one-stop solution, providing you with everything you need to design, build, and implement AI solutions seamlessly.'}
                features={
                    [
                        'Everything from the Standard version',
                        'Priority access to new features & updates',
                        'Custom training module',
                        'Advanced debugging & optimization tools',
                        'Priority API integration support',
                        'Priority customer support',
                        'Exclusive access to premium content (Webinars, tutorials, etc.)',
                        'Access to high-performance compute',
                        'resources for faster training times'
                    ]
                }
                actionTitle='Contact us'
                action={function (): void {
                    window.location.href = `mailto:${CONTACT_EMAIL}`;
                }}
            />
        </div>
    );
};

export default SubscriptionCard;