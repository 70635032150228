import React, { useState } from 'react';
import ProjectCard from '../../cards/home/projectCard/ProjectCard';
import Skeleton from '@mui/material/Skeleton';
import { Bot, deleteBot, fetchBots } from '../../../services/BSCore';
import BotForm from '../../dialog/BotForm';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchWorkspaceAsync, getSelectedWorkspace } from '../../../features/workspace/workspaceSlice';
import { useNavigate } from 'react-router-dom';
import DeleteModel from '../../models/DeleteModel';
import ProjectEmptyCard from '../../cards/home/projectCard/ProjectEmptyCard';
interface MyProjectsContentProps {
    bots?: Bot[] | undefined | null;
    isLoading?: boolean;
}
const MyProjectsContent: React.FC<MyProjectsContentProps> = ({bots, isLoading}) => {
    const [showProjectForm, setShowProjectForm] = useState(false); // Add state for showing project form
    const [deleteBotInfo, setDeleteBotInfo] = useState<Bot>();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const renderCard = (item: Bot, index: number) => {
        // Adjust this code to render your project cards
        const key = `card-${index}`;
        return <div className='col-12 col-md-4 col-lg-3'><ProjectCard key={key} bot={item} onDeleteClick = {onDeleteCardClick}/></div>;
    };

    const getProjectCards = () => {
        const skeletonHeight = '174px';

        if (isLoading) {
            return (
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)', // Adjust as needed
                        gap: '24px',
                        justifyContent: 'center',
                    }}
                >
                    {/* Skeleton loading cards */}
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Skeleton
                            key={`skeleton-${index}`}
                            variant='rectangular'
                            animation='wave'
                            style={{
                                borderRadius: '8px',
                                width: '100%',
                                height: skeletonHeight,
                            }}
                        />
                    ))}
                </div>
            );
        } else {
            // Render your project cards based on the fetched data
            return (
                <>
                    {bots?.length === 0
                        ?
                            <div className='col-4'>
                                <ProjectEmptyCard setShowProjectForm ={setShowProjectForm}/>
                            </div>
                        :
                            <>
                                {bots?.map((item, index) => renderCard(item, index))}
                            </>
                }
                </>
            );
        }
    };

    const workspace = useAppSelector(getSelectedWorkspace);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onDeleteCardClick = (bot:Bot)=>{
        setShowConfirmation(true);
        setDeleteBotInfo(bot);
    };
    
    const handleDelete = async () => {
        if (deleteBotInfo?.id && workspace) {
            await deleteBot(deleteBotInfo?.id);
            dispatch(fetchWorkspaceAsync());
            const bots = await fetchBots(workspace.id);
            setShowConfirmation(false);
            if (bots && bots.length > 0) {
                navigate(`/bot/${bots[0].id}`);
            }
        }
    };
    return (
        <div className='content'>
            <h4>My project</h4>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='text-grey m-0'>Continue the project you left off, or build something new.</p>
                <div>
                    {/* <button className='btn btn-text-main mx-2'>
                        All templates
                    </button>  */}
                    {
                    bots?.length !== 0 &&<button className='btn btn-outline-main mx-2' onClick={() => setShowProjectForm(true)} >
                        <i className='bi bi-plus-lg px-2'></i>
                        Create a new project
                    </button>  
                    }
                </div> 
            </div>
            <div>
                <div className='project row'>
                    {getProjectCards()}
                </div>
            </div>
            
            <BotForm isOpen={showProjectForm} onClose={() => setShowProjectForm(false)} />
            <DeleteModel
                showDeleteModal={showConfirmation}
                title='Delete project?'
                content={`All the data associated with this project will be erased forever if you delete it. Are you sure you want to delete project ${deleteBotInfo?.name}?`}
                onClose={() => setShowConfirmation(false)}
                handleDelete={handleDelete}
            />
        </div>
    );
};

export default MyProjectsContent;
