import React, { useRef, DragEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import { NodeManifest } from '../../../botEditor';
import NodeCategory from '../../../botEditor/nodes/NodeCategory';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';

import './EditorModuleLib.scss';
import { clearSelectedCategory, getSelectedCategory, setSelectedCategory } from '../../../features/botEditor/botEditorSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

type nodeCategoryData = {
    type: NodeCategory,
    name: string,
    iconId: string
}

const nodeCategories: nodeCategoryData[] = [
    {
        type: NodeCategory.generative,
        name: 'Generative',
        iconId: 'bi-easel'
    },
    {
        type: NodeCategory.data,
        name: 'Data',
        iconId: 'bi-database'
    },
    {
        type: NodeCategory.modalCnv,
        name: 'Conversion',
        iconId: 'bi-arrow-down-up'
    },
    {
        type: NodeCategory.output,
        name: 'Output',
        iconId: 'bi-box-arrow-right'
    },
    {
        type: NodeCategory.logic,
        name: 'Logic',
        iconId: 'bi-signpost-split'
    }
];

const EditorModuleLib: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedCategory = useAppSelector(getSelectedCategory);
    const containerRef = useRef<HTMLDivElement>(null);
    const moduleContainerRef = useRef<HTMLDivElement>(null);

    const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: string) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <div ref={containerRef} className='editormodulelib-container'>
            <div className='editormodulelib-module-category-container'>
                <h1>MODULE LIBRARY</h1>
                <div className='editormodulelib-category-list'>
                    {nodeCategories.map(c =>
                        <div onClick={() => { if (c.type === selectedCategory) { dispatch(clearSelectedCategory()); } else { dispatch(setSelectedCategory(c.type)); } }} className={`editormodulelib-category-card ${selectedCategory === c.type ? 'editormodulelib-category-card-selected' : ''}`}>
                            <div>
                                <i className={`bi ${c.iconId}`} />
                                <p>{c.name}</p>
                            </div>
                            <i className={`editormodulelib-go-icon bi ${selectedCategory === c.type ? 'bi-arrow-right' : 'bi-arrow-right-short'}`} />
                        </div>)}
                </div>
            </div>
            <Slide mountOnEnter unmountOnExit direction='right' container={containerRef.current} in={!!selectedCategory}>
                <div ref={moduleContainerRef} className='editormodulelib-module-container'>
                    <div className='editormodulelib-module-header'>
                        <div className='editormodulelib-module-header-title-container'>
                            <span className='editormodulelib-module-indicator' />
                            {nodeCategories.find(n => n.type === selectedCategory)?.name}
                        </div>
                        <IconButton disabled={false} onClick={() => dispatch(clearSelectedCategory())}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className='editormodulelib-module-list'>
                        {NodeManifest.filter(n => n.category === selectedCategory).map(node => {
                            return <div className='editormodulelib-module-card' draggable onDragStart={(event) => onDragStart(event, node.id)}>
                                <div className='editormodulelib-module-card-header-container'>
                                    <span className='editormodulelib-module-card-title-container'>
                                        <img src={`/images/${node.iconFile}`} alt='Profile' />
                                        <h2>{node.name}</h2>
                                    </span>
                                    <span onClick={() => {
                                        const newWindow = window.open(node.docUrl, '_blank', 'noopener,noreferrer');
                                        if (newWindow) newWindow.opener = null;
                                    }} className='editormodulelib-module-card-doc-container'>
                                        Learn more
                                        <i className='bi bi-box-arrow-up-right' />
                                    </span>
                                </div>
                                <p>{node.description}</p>
                            </div>;
                        })}
                    </div>
                </div>
            </Slide>
        </div>
    );
};

export default EditorModuleLib;
