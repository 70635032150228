import React, { ReactNode } from 'react';
import './TabButton.scss';

interface Props {
    children: ReactNode
    onClick?: ()=>void
    disabled?: boolean
    focused: boolean
}

const TabButton: React.FC<Props> = ({ children, onClick, disabled, focused }) => {
    return (
        <button disabled={disabled} onClick={onClick} className={`tabbutton ${disabled ? 'tabbutton-disabled' : ''} ${focused ? 'tabbutton-focused' : ''}`}>{children}</button>
    );
};

export default TabButton;
