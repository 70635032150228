import React, { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import './ImageCrop.scss';

interface Props {
    image: string
    onCropDone: (croppedArea?: Area) => void
    onCropCancel: () => void
}

const ImageCropper: React.FC<Props> = ({ image, onCropDone, onCropCancel }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState<Area>();

    const onCropComplete = (croppedAreaPercentage: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels);
    };

    return (
        <div className='image-cropper'>
            <Cropper
                image={image}
                aspect={1}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                style={{
                    containerStyle: {
                        position: 'relative',
                        width: '600px',
                        height: '600px',
                        backgroundColor: 'white',
                        borderRadius: '8px'
                    },
                }}
            />

            <div className='image-crop-buttons'>
                <button className='image-crop-cancel' onClick={onCropCancel}>
                    Cancel
                </button>

                <button className='image-crop-done' onClick={() => onCropDone(croppedArea)}>
                    Done
                </button>
            </div>
        </div>
    );
};

export default ImageCropper;
