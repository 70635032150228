import React, { useEffect, useState, useRef } from 'react';
import { fetchMemberData, MemberObject, createModuleInstance, Workflow, updateModuleInstance, updateWorkflow, rollbackBot, publishBot, fetchBotChannels } from '../../../services/BSCore';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { NodeType, NodeResourceLoaderTypes } from '../../../botEditor';
import AuthService from '../../../services/AuthService';
import { ReactFlowInstance, Edge, Node } from 'reactflow';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { clearData } from '../../../features/workspace/workspaceSlice';
import { useDispatch } from 'react-redux';
import EditorPrompt from '../../components/editorPrompt/EditorPrompt';
import WarningPrompt from '../warningPrompt/WarningPrompt';
import './EditorNav.scss';
import BSToolTip from '../../../components/toolTip/TooTip';

interface Props {
    botName: string
    botId: string
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>
    nodes: Node[]
    edges: Edge[]
    shouldBlockNavigateRef: React.MutableRefObject<boolean>
    showSuccessSnackBar: ActionCreatorWithPayload<string, 'botEditor/showSuccessSnackBar'>
    showErrorSnackBar: ActionCreatorWithPayload<string, 'botEditor/showErrorSnackBar'>
    reloadEditor: () => Promise<void>
    setShowToDeployModal: React.Dispatch<React.SetStateAction<boolean>>
    workflow?: Workflow
    reactFlowInstance?: ReactFlowInstance
}

const EditorNav: React.FC<Props> = ({ botName, botId, setShowLoading, workflow, reactFlowInstance, nodes, edges, shouldBlockNavigateRef, showSuccessSnackBar, showErrorSnackBar, reloadEditor, setShowToDeployModal }) => {
    const [avatarUrl, setAvatarUrl] = useState('');
    const [showRollbackPrompt, setShowRollbackPrompt] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileImgRef = useRef(null);

    useEffect(() => {
        fetchUser();
    }, []);

    const handleLogout = async () => {
        await AuthService.signOut();
        dispatch(clearData());
        console.log('Logged out');
        navigate('/auth');
    };

    const handleSaveAndExit = async () => {
        const saveResult = await handleSave();
        if (saveResult) navigate(`/bot/${botId}`);
    };

    const fetchUser = async () => {
        try {
            const user = await fetchMemberData() as MemberObject;
            setAvatarUrl(user.info.imageUrl);
        } catch (e) {
            console.error('EditorNav error fetching user data', e);
        }
    };

    const handleSave = async () => {
        setShowLoading(true);

        try {
            if (!workflow || !reactFlowInstance) return;
            const rfInstance = reactFlowInstance.toObject();
            const { viewport } = rfInstance;

            // Extract data
            const { id: workflowId, workflowKey } = workflow;

            // Create/update module instances
            for (const n of nodes) {
                // Skip modules
                if (n.type === NodeType.start) continue;
                if (n.data.moduleKey && !n.data.moduleDataDidChange) continue;

                // no module key, create module instance
                if (!n.data.moduleKey) {
                    const moduleData: any = {};
                    if (n.type === NodeType.vdSearch) moduleData['datasourceKey'] = n.data.datasource.datasourceKey;
                    else if (n.type === NodeType.vdChatHistory) moduleData['datasourceKey'] = n.data.datasource.datasourceKey;
                    else if (n.type === NodeType.youtubeSearch) moduleData['datasourceKey'] = n.data.datasource.datasourceKey;
                    else if (n.type === NodeType.resourceLoader) {
                        moduleData['type'] = n.data.resourceType;
                        if (n.data.resourceType !== NodeResourceLoaderTypes.text) moduleData['value'] = n.data.resourceKey;
                    }
                    else if (n.type === NodeType.audioToText) moduleData['audioData'] = n.data.audioData;
                    else if (n.type === NodeType.textToAudio) {
                        moduleData['text'] = n.data.text;
                        moduleData['voiceType'] = n.data.voiceType;
                    }
                    else if (n.type === NodeType.oaiDALLE) {
                        moduleData['n'] = n.data.n;
                        moduleData['size'] = n.data.size;
                        moduleData['prompt'] = n.data.prompt;
                    }
                    else {
                        moduleData['data'] = n.data;
                    }

                    console.log('moduleData', moduleData);

                    const { moduleKey } = await createModuleInstance(workflowKey, n.type!, moduleData);
                    n.data['moduleKey'] = moduleKey;

                    console.log(`Did created module for node: ${n.id}, moduleData: ${JSON.stringify(moduleData)}`);
                }

                // Module data did change, update module
                if (n.data.moduleDataDidChange) {
                    const moduleData: any = {};
                    if (n.type === NodeType.vdSearch) moduleData['datasourceKey'] = n.data.datasource.datasourceKey;
                    else if (n.type === NodeType.resourceLoader) {
                        moduleData['type'] = n.data.resourceType;
                        if (n.data.resourceType !== NodeResourceLoaderTypes.text) moduleData['value'] = n.data.resourceKey;
                    }
                    else if (n.type === NodeType.audioToText) moduleData['audioData'] = n.data.audioData;
                    else if (n.type === NodeType.textToAudio) {
                        moduleData['text'] = n.data.text;
                        moduleData['voiceType'] = n.data.voiceType;
                    }
                    else if (n.type === NodeType.oaiDALLE) {
                        moduleData['n'] = n.data.n;
                        moduleData['size'] = n.data.size;
                        moduleData['prompt'] = n.data.prompt;
                    }
                    else {
                        moduleData['data'] = n.data;
                    }
                    await updateModuleInstance(n.data.moduleKey, moduleData);
                    delete n.data.moduleDataDidChange;
                    console.log(`Did update module for node: ${n.id}, moduleData: ${JSON.stringify(moduleData)}`);
                }
            }

            // Save JSON
            await updateWorkflow(workflowId, {
                nodes,
                edges,
                viewport
            });

            shouldBlockNavigateRef.current = false;
            setShowLoading(false);
            dispatch(showSuccessSnackBar('Workflow saved!'));
            return true;
        } catch (e) {
            console.error('Error happened while saving workflow', e);
            setShowLoading(false);
            dispatch(showErrorSnackBar('Failed to save'));
            return false;
        }
    };

    const handleRollback = async () => {
        setShowLoading(true);
        try {
            await rollbackBot(+botId);
            await reloadEditor();
            dispatch(showSuccessSnackBar('Successfully rolled back all workflows!'));
            setShowLoading(false);
            setShowRollbackPrompt(false);
        } catch (e) {
            console.error('Error happened while rolling back', e);
            setShowLoading(false);
            setShowRollbackPrompt(false);
            dispatch(showErrorSnackBar('Failed to roll back'));
        }
    };

    const handlePublish = async () => {
        setShowLoading(true);
        try {
            // Publish workflow
            await handleSave();
            await publishBot(+botId);
            console.log('Published!');
            dispatch(showSuccessSnackBar('Successfully published all workflows!'));

            // Navigate to deploy page if no channel created
            const channels = await fetchBotChannels(+botId);
            if (!channels || channels.length === 0) {
                setShowToDeployModal(true);
            }

            setShowLoading(false);
        } catch (e) {
            console.error('Error happened while publishing', e);
            setShowLoading(false);
            dispatch(showErrorSnackBar('Failed to publish'));
        }
    };

    return (
        <div className='editornav-container'>
            <div className='editornav-content-container'>
                <Button
                    className='editornav-exit-btn'
                    sx={{ textTransform: 'none' }}
                    variant='text'
                    startIcon={<i className='bi bi-x-lg' />}
                    onClick={()=>setShowExitModal(true)}
                >Exit</Button>
                <p className='editornav-botname'>{`${botName}`}</p>
                <div className='editornav-button-container'>
                    <BSToolTip marginTop={10} title='Revert to the previous published version'>
                        <IconButton
                            className='editornav-button-rollback'
                            onClick={() => setShowRollbackPrompt(true)}
                        >
                            <i className='bi bi-arrow-90deg-left' />
                        </IconButton>
                    </BSToolTip>
                    <BSToolTip marginTop={10} title='Save current workflow'>
                        <Button
                            className='editornav-button-save'
                            sx={{ textTransform: 'none' }}
                            startIcon={<i className='bi bi-save' />}
                            variant='outlined'
                            onClick={handleSave}
                        >Save</Button>
                    </BSToolTip>
                    <BSToolTip marginTop={10} title='Make the workflow public'>
                        <Button
                            className='editornav-button-publish'
                            sx={{ textTransform: 'none' }}
                            startIcon={<i className='bi bi-arrow-repeat' />}
                            variant='contained'
                            onClick={handlePublish}
                        >Publish</Button>
                    </BSToolTip>

                </div>
            </div>
            <div className='editornav-profile-container'>
                {avatarUrl && <img ref={profileImgRef} onClick={() => setShowProfileMenu(!showProfileMenu)} className='editornav-profile-img' src={avatarUrl} alt='Avatar' />}
            </div>
            <Menu
                className='editornav-profile-menu'
                anchorEl={profileImgRef.current}
                open={showProfileMenu}
                onClose={() => setShowProfileMenu(false)}
            >
                <MenuItem
                    className='editornav-profile-menu-item'
                    onClick={() => { setShowProfileMenu(false); navigate('/profile'); }}
                ><i className='bi bi-person' />Profile</MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => { setShowProfileMenu(false); handleLogout(); }}
                    className='editornav-profile-menu-item'><i className='bi bi-box-arrow-right' />Logout</MenuItem>
            </Menu>
            <EditorPrompt
                title='Confirm reverting'
                description='Revert to the previous published version?'
                show={showRollbackPrompt}
                actionLeftTitle='Cancel'
                actionLeftOnClick={() => setShowRollbackPrompt(false)}
                actionRightTitle='Confirm'
                actionRightOnClick={handleRollback}
            >
            </EditorPrompt>
            <WarningPrompt
                title='Save changes before leave'
                actionCloseModal={()=>setShowExitModal(false)}
                description='Are you sure to save changes before leaving this page?'
                show={showExitModal}
                actionLeftTitle='Cancel'
                actionLeftOnClick={()=>{
                    setShowExitModal(false);
                }}
                actionMiddleTitle='No, exit without saving'
                actionMiddleOnClick={()=>{
                    shouldBlockNavigateRef.current = false;
                    setShowExitModal(false);
                    navigate(`/bot/${botId}`);
                }}
                actionRightTitle='Yes, save and exit'
                actionRightOnClick={()=>{
                    setShowExitModal(false);
                    handleSaveAndExit();
                }}
            >
            </WarningPrompt>
        </div>
    );
};

export default EditorNav;
