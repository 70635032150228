export function shuffleArray(array: any[]) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export const formatISODateTim = (isoDateTime: string): string => {
    const date = new Date(isoDateTime);
    
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert from 24h to 12h format
    hours = hours % 12;
    // the hour '0' should be '12'
    hours = hours ? hours : 12; 

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-indexed.
    const day = date.getDate();

    // Pad the month, day, hours and minutes with leading zeros, if necessary.
    const paddedMonth = month < 10 ? '0' + month : month;
    const paddedDay = day < 10 ? '0' + day : day;
    const paddedHours = hours < 10 ? '0' + hours : hours;
    const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${paddedMonth}/${paddedDay} ${year} ${paddedHours}:${paddedMinutes}${ampm}`;
};

export const getTimeAgo = (isoDateTime: string): string => {
    const date = new Date(isoDateTime);
    const now = new Date();

    const timeDifference = now.getTime() - date.getTime();
    const secondsAgo = Math.floor(timeDifference / 1000);

    if (secondsAgo < 60) {
        return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
    }

    const minutesAgo = Math.floor(secondsAgo / 60);

    if (minutesAgo < 60) {
        return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
    }

    const hoursAgo = Math.floor(minutesAgo / 60);

    if (hoursAgo < 24) {
        return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
    }

    const daysAgo = Math.floor(hoursAgo / 24);

    if (daysAgo < 7) {
        return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
    }

    const weeksAgo = Math.floor(daysAgo / 7);

    if (weeksAgo < 4) {
        return `${weeksAgo} week${weeksAgo !== 1 ? 's' : ''} ago`;
    }

    const monthsAgo = now.getMonth() - date.getMonth() + 12 * (now.getFullYear() - date.getFullYear());

    if (monthsAgo < 12) {
        return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
    }

    const yearsAgo = Math.floor(monthsAgo / 12);

    return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
};
