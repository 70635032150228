import { Auth } from 'aws-amplify';
import React, { useState, useEffect, useRef, useCallback, DragEvent } from 'react';
import { useNavigate, useParams, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { NodeTypes, EdgeTypes, EditorControlPanel, EditorSidePanel } from '../../botEditor';
import './EditBots.scss';
import ReactFlow, { ReactFlowProvider, MiniMap, Background, Controls, ReactFlowInstance, useNodesState, useEdgesState, addEdge, Edge, Node, Connection, MarkerType } from 'reactflow';
import './EditBots.scss';
import 'reactflow/dist/style.css';
import { getNodeId, getNodeInfo, updateNodesOptions, findValidVariables, findIsolatedNodeErrors, unselectAllNodes } from '../../botEditor/utils';
import { fetchBot, Workflow, fetchWorkflow, toggleWorkflow } from '../../services/BSCore';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchWorkspaceAsync } from '../../features/workspace/workspaceSlice';
import { getIsDraggable, getShouldShowSuccessSnackBar, getShouldShowErrorSnackBar, getSuccessSnackBarMessage, getErrorSnackBarMessage, hideSuccessSnackBar, hideErrorSnackBar, showSuccessSnackBar, showErrorSnackBar, getRecalculateNodesMetaTrigger, getEditorSidePanelProps, hideEditorSidePanel, clearSelectedCategory } from '../../features/botEditor/botEditorSlice';
import EditorNav from '../../botEditor/components/editorNav/EditorNav';
import EditorSideUtil, { inputTypeInfo, InputType } from '../../botEditor/components/editorSideUtil/EditorSideUtil';
import EditorModuleLib from '../../botEditor/components/editorModuleLib/EditorModuleLib';
import EditorPrompt from '../../botEditor/components/editorPrompt/EditorPrompt';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Editor as CodeEditor } from '@monaco-editor/react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const initialNodes: Node[] = [
    { id: 'start-0', type: 'start', position: { x: 0, y: 0 }, data: {} }
];
const initialEdges: Edge[] = [];

enum CodeEditorContentType {
    NODES = 0,
    EDGES = 1
}

export interface TestRunCredentials {
    userId: string,
    userToken: string,
    channelId: string
};

let workflowJsonStore: any = {};
const EditBots: React.FC = () => {
    const isDraggable = useAppSelector(getIsDraggable);
    const recalculateNodesMetaTrigger = useAppSelector(getRecalculateNodesMetaTrigger);
    const shouldShowSuccessSnackBar = useAppSelector(getShouldShowSuccessSnackBar);
    const successSnackBarMessage = useAppSelector(getSuccessSnackBarMessage);
    const shouldShowErrorSnackBar = useAppSelector(getShouldShowErrorSnackBar);
    const errorSnackBarMessage = useAppSelector(getErrorSnackBarMessage);
    const editorSidePanelProps = useAppSelector(getEditorSidePanelProps);
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
    const navigate = useNavigate();
    const { bot_id } = useParams();
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [editingWorkflows, setEditingWorkflows] = useState<Workflow[]>([]);
    const [workflow, setWorkflow] = useState<Workflow | undefined>(undefined);
    const [botName, setBotName] = useState('');
    const dispatch = useAppDispatch();
    const [codeEditorSelectedContentType, setCodeEditorSelectedContentType] = useState<CodeEditorContentType>(CodeEditorContentType.NODES);
    const [showEditModal, setShowEditModal] = useState(false);
    const [shouldHideInputTypeCancel, setShouldHideInputTypeCancel] = useState(true);
    const [shouldDisableInputTypeSave, setShouldDisableInputTypeSave] = useState(true);
    const shouldBlockNavigateRef = useRef<boolean>(true);
    const [showToDeployModal, setShowToDeployModal] = useState(false);
    const [shouldShowEditorSidePanel, setShouldShowEditorSidePanel] = useState(false);

    useEffect(() => {
        setShouldShowEditorSidePanel(!!editorSidePanelProps);
    }, [editorSidePanelProps]);

    useBlocker(() => {
        console.log('shouldBlockNavigate', shouldBlockNavigateRef.current);
        if (shouldBlockNavigateRef.current) {
            // Custom logic to decide whether or not to allow the transition
            return window.confirm('You have unsaved changes, are you sure you want to leave this page?') ? false : true;
        } else {
            return false;
        }
    });

    // Input type modal button states
    useEffect(() => {
        if (!workflows || !editingWorkflows) return;

        // Cancel btn state
        let hasEnabledWorkflow = false;
        workflows.forEach(w => { if (w.enabled) { hasEnabledWorkflow = true; } });
        setShouldHideInputTypeCancel(!hasEnabledWorkflow);

        // Save btn state
        hasEnabledWorkflow = false;
        editingWorkflows.forEach(w => { if (w.enabled) { hasEnabledWorkflow = true; } });
        setShouldDisableInputTypeSave(!hasEnabledWorkflow);
    }, [workflows, editingWorkflows]);

    // JSON editor
    const [isVisualMode, setIsVisualMode] = useState(true);

    // Not authorized
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(error => {
                console.error(error);
                navigate('/auth');
            });
    }, [navigate]);

    useEffect(() => {
        // Update JSON local storage
        if (workflow?.type) {
            workflowJsonStore[workflow!.type] = {
                nodes,
                edges
            };
        }

        // Should block navigate
        shouldBlockNavigateRef.current = true;
    }, [nodes, edges]);

    useEffect(() => {
        // Load workflow json data on workflow change
        loadWorkflowJson();
    }, [workflow]);

    // Load data
    useEffect(() => {
        setShowLoading(true);

        // Clear cache
        workflowJsonStore = {};

        loadData()
            .then(() => { setShowLoading(false); })
            .catch(e => {
                setShowLoading(false);
                console.error('Error caught while fetching data', e);
                setShowError(true);
            });

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = 'Are you sure you want to exit?';
        };

        // Clear side panel cache
        dispatch(hideEditorSidePanel());

        // Before unload handler
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        updateNodesMetadata();
    }, [nodes.length, edges.length, recalculateNodesMetaTrigger]);

    const updateNodesMetadata = () => {
        // Valid variables
        const validVariables = findValidVariables(nodes, edges, 'start-0');

        // Isolated nodes
        const isolatedNodeErrorList = findIsolatedNodeErrors(nodes, edges);

        // Update nodes
        nodes.forEach(n => {
            n.data.validVariables = validVariables[n.id] || [];
            n.data.errorFromEditor = isolatedNodeErrorList[n.id] || [];
        });
        setNodes(JSON.parse(JSON.stringify(nodes)));
    };

    const loadData = async () => {
        try {
            const botId = bot_id ? +bot_id : undefined;
            if (!botId) throw new Error('Invalid bot id');
            const { devWorkflows, name } = await fetchBot(botId!);
            setWorkflows(devWorkflows);
            setBotName(name);
            setWorkflow(devWorkflows.find(w => w.enabled));
            dispatch(fetchWorkspaceAsync());

            // If no enabled workflow, show edit modal dialog
            if (devWorkflows && !devWorkflows.find(w => w.enabled)) {
                setEditingWorkflows(JSON.parse(JSON.stringify(devWorkflows)));
                setShowEditModal(true);
            }
        } catch (err) {
            throw err;
        }
    };

    const loadWorkflowJson = async () => {
        try {
            setShowLoading(true);

            // Workflow not set yet, return
            if (!workflow?.id) return;

            // No data in local storage, fetch from cloud
            if (!workflowJsonStore[workflow!.type]) {
                const { data: workflowJsonData } = await fetchWorkflow(workflow!.id);
                workflowJsonStore[workflow!.type] = workflowJsonData;
            }

            // Storage data empty, use initial value
            if (!workflowJsonStore[workflow!.type]) {
                workflowJsonStore[workflow!.type] = {
                    nodes: initialNodes,
                    edges: initialEdges
                };
            }

            // Set start node modal type
            const fetchedNodes = (workflowJsonStore[workflow!.type].nodes as Node[]).map(n => {
                if (n.type === 'start') {
                    n.data.outputModal = workflow.type === 'greeting' ? 'text' : workflow.type;
                    return n;
                } else {
                    return n;
                }
            });

            // Load JSON data to canvas
            setNodes(fetchedNodes);
            setEdges(workflowJsonStore[workflow!.type].edges);

            setShowLoading(false);
        } catch (e) {
            setShowLoading(false);
            console.error('Error caught while fetching data', e);
            setShowError(true);
        }
    };

    const onConnect = useCallback((params: Edge | Connection) => {
        console.log('params', params);
        // Switch edge types
        let edgeType = 'default';
        if (params.sourceHandle === 'true-handle') edgeType = 'trueEdge';
        if (params.sourceHandle === 'false-handle') edgeType = 'falseEdge';

        // Switch edge arrow color
        let edgeArrowColor = '#3849D3';
        if (params.sourceHandle === 'false-handle') edgeArrowColor = '#C2410C';

        setEdges((els) => addEdge({ ...params, markerEnd: { type: MarkerType.Arrow, color: edgeArrowColor }, type: edgeType }, els));
    }, [setEdges]);

    const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const reactFlowBounds = reactFlowWrapper!.current!.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');

        // check if the dropped element is valid
        if (typeof type === 'undefined' || !type) return;

        const position = reactFlowInstance!.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });

        const newNodeInfo = getNodeInfo(type);
        const newNode = {
            id: getNodeId(nodes, type),
            type,
            position,
            data: {
                nodeInfo: newNodeInfo
            },
        };

        setNodes((nds) => nds.concat(newNode));
    },
        [reactFlowInstance, setNodes, nodes]
    );

    const onBackgroundClick = () => {
        dispatch(clearSelectedCategory());
    };

    const handleCodeEditorChange = (value: string | undefined) => {
        try {
            if (!value) return;

            const parsedValue = JSON.parse(value);

            if (codeEditorSelectedContentType === CodeEditorContentType.NODES) {
                setNodes(parsedValue);
            } else {
                setEdges(parsedValue);
            }

        } catch (e) { }
    };

    const reloadEditor = async () => {
        try {
            setWorkflow(undefined);
            setWorkflows([]);
            setNodes([]);
            setEdges([]);
            workflowJsonStore = {};
            await loadData();
        } catch (e) {
            console.error('Error occurred while reloading editor');
            throw e;
        }
    };

    const handleEditModal = () => {
        setEditingWorkflows(JSON.parse(JSON.stringify(workflows)));
        setShowEditModal(true);
    };

    const handleSaveEditingModals = async () => {
        try {
            setShowEditModal(false);
            setShowLoading(true);

            // Toggle workflow requests
            const editRequests: Promise<void>[] = [];
            editingWorkflows.forEach(editingWorkflow => {
                const workflow = workflows.find(w => w.id === editingWorkflow.id);
                if (workflow && workflow.enabled !== editingWorkflow.enabled) editRequests.push(toggleWorkflow(editingWorkflow.id, editingWorkflow.enabled));
            });

            await Promise.all(editRequests);
            await reloadEditor();
            setShowLoading(false);
            dispatch(showSuccessSnackBar('Successfully updated input types'));
        } catch (e) {
            console.error('Toggling workflows failed', e);
            setShowLoading(false);
            dispatch(showErrorSnackBar('Error occurred while updating input types'));
        }
    };

    const handleToggleModal = (modalId: number) => {
        setEditingWorkflows(editingWorkflows.map(w => {
            if (w.id === modalId) {
                w.enabled = !w.enabled;
                return w;
            } else {
                return w;
            }
        }));
    };

    // Disable nodes dragging
    useEffect(() => {
        updateNodesOptions(nodes, setNodes, { draggable: isDraggable });
    }, [isDraggable]);

    return (<div className='editbots-container'>
        <EditorNav
            botName={botName}
            botId={bot_id || ''}
            setShowLoading={setShowLoading}
            nodes={nodes}
            edges={edges}
            shouldBlockNavigateRef={shouldBlockNavigateRef}
            showSuccessSnackBar={showSuccessSnackBar}
            showErrorSnackBar={showErrorSnackBar}
            reloadEditor={reloadEditor}
            setShowToDeployModal={setShowToDeployModal}
            workflow={workflow}
            reactFlowInstance={reactFlowInstance || undefined}
            />
        <div className='editbots-edit-container'>
            <EditorSideUtil
                setIsVisualMode={setIsVisualMode}
                isVisualMode={isVisualMode}
                workflows={workflows}
                workflow={workflow}
                setWorkflow={setWorkflow}
                handleEditModal={handleEditModal}
            />
            <EditorModuleLib />
            <div className='editbots-flow-container'>
                {
                    isVisualMode ?
                        <ReactFlowProvider>
                            <div className='editbots-flow-wrapper reactflow-wrapper' ref={reactFlowWrapper}>
                                <ReactFlow
                                    defaultViewport={{x: 100, y: 180, zoom: 1}}
                                    nodes={nodes}
                                    edges={edges}
                                    onNodesChange={onNodesChange}
                                    onEdgesChange={onEdgesChange}
                                    onConnect={onConnect}
                                    nodeTypes={NodeTypes}
                                    edgeTypes={EdgeTypes}
                                    onDrop={onDrop}
                                    onDragOver={onDragOver}
                                    onInit={setReactFlowInstance}
                                    panOnDrag={isDraggable}
                                    preventScrolling={isDraggable}
                                    onPaneClick={onBackgroundClick}
                                >
                                    <Controls />
                                    <MiniMap zoomable pannable />
                                    <Background color='#aaa' gap={16} />
                                    <EditorControlPanel
                                        setShowLoading={setShowLoading}
                                        nodes={nodes}
                                        edges={edges}
                                        botId={+bot_id!}
                                    />
                                    <EditorSidePanel
                                        show={shouldShowEditorSidePanel}
                                        shouldShow={setShouldShowEditorSidePanel}
                                        title={editorSidePanelProps?.title || ''}
                                        subtitle={editorSidePanelProps?.subtitle || ''}
                                        panelComponent={editorSidePanelProps?.panelComponent || <></>}
                                        unselectAllNodes={() => unselectAllNodes(nodes, setNodes)}
                                    />
                                </ReactFlow>
                            </div>
                        </ReactFlowProvider>
                        :
                        <div className='editbots-codeeditor-container'>
                            <Tabs
                                value={codeEditorSelectedContentType}
                                textColor='inherit'
                                indicatorColor='primary'
                                onChange={(e: React.SyntheticEvent, n: number) => setCodeEditorSelectedContentType(n)}
                            >
                                <Tab label='Nodes' />
                                <Tab label='Edges' />
                            </Tabs>
                            <CodeEditor
                                defaultLanguage='json'
                                width={'100%'}
                                value={JSON.stringify(codeEditorSelectedContentType === CodeEditorContentType.NODES ? nodes : edges, null, 4)}
                                options={{ minimap: { enabled: false } }}
                                onChange={handleCodeEditorChange}
                            />
                        </div>
                }
            </div>
        </div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showLoading}
        >
            <CircularProgress color='inherit' />
        </Backdrop>
        <Dialog
            open={showError}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                {`⚠️ Error loading editor data`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Error happened while loading editor data, please try again later
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => navigate(`/bot/${bot_id}`)}>Back</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={showToDeployModal}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                {`✅ Bot published successfully`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    You haven't deployed your bot to any channel yet. Would you like to go to the deployment page?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowToDeployModal(false)}>Stay here</Button>
                <Button onClick={() => {
                    shouldBlockNavigateRef.current = false;
                    navigate(`/bot/${bot_id}`, { state: { showDeployment: true } });
                }}>Go</Button>
            </DialogActions>
        </Dialog>
        <Snackbar open={shouldShowSuccessSnackBar} autoHideDuration={3000} onClose={() => dispatch(hideSuccessSnackBar())}>
            <Alert onClose={() => dispatch(hideSuccessSnackBar())} severity='success' sx={{ width: '100%' }}>
                {successSnackBarMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={shouldShowErrorSnackBar} autoHideDuration={3000} onClose={() => dispatch(hideErrorSnackBar())}>
            <Alert onClose={() => dispatch(hideErrorSnackBar())} severity='error' sx={{ width: '100%' }}>
                {errorSnackBarMessage}
            </Alert>
        </Snackbar>
        <EditorPrompt
            title='Input types'
            description='Toggle the input methods for this bot on or off' 
            show={showEditModal}
            actionLeftTitle='Cancel'
            actionLeftOnClick={() => setShowEditModal(false)}
            hideActionLeft={shouldHideInputTypeCancel}
            actionRightTitle='Save'
            actionRightOnClick={handleSaveEditingModals}
            disableActionRight={shouldDisableInputTypeSave}   
        >
            <List
                className='editbots-inputtype-dialog-list'
            >
                {editingWorkflows.map((w) => {
                    return (
                        <ListItem
                            key={w.id}
                            disablePadding
                            secondaryAction={
                                <Checkbox
                                    onChange={() => handleToggleModal(w.id)}
                                    edge='start'
                                    tabIndex={-1}
                                    disableRipple
                                    checked={w.enabled}
                                />
                            }
                        >
                            <ListItemButton onClick={() => handleToggleModal(w.id)} role={undefined} dense>
                                <ListItemIcon>
                                    <i className={`bi ${inputTypeInfo[w.type as InputType].iconId}`}/>
                                </ListItemIcon>
                                <ListItemText id={'' + w.id} primary={`${w.type.charAt(0).toUpperCase() + w.type.slice(1)}`} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List> 
        </EditorPrompt>
    </div>);
};

export default EditBots;