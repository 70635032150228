import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeOutput.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import NodeHeader from '../../components/nodeHeader/NodeHeader';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import { NodeDataModal } from '../NodeManifest';

import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeSelect from '../../components/nodeSelect/NodeSelect';

export enum NodeOutputTypes {
    text = 'text',
    image = 'image',
    audio = 'audio',
    video = 'video',
    file = 'file',
    location = 'location'
}

const NodeOutputDefaults = {
    outputType: NodeOutputTypes.text
};

const NodeOutput: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [outputData, setOutputData] = useState('');
    const [outputType, setOutputType] = useState(NodeOutputDefaults.outputType);
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const [acceptModal, setAcceptModal] = useState(NodeDataModal.text);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables, acceptModal]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, { outputType: data?.outputType || NodeOutputDefaults.outputType, outputData: data?.outputData || '' });

        // Init UI
        setOutputData(data?.outputData || '');
        setOutputType(data?.outputType || NodeOutputDefaults.outputType);
        setAcceptModal((data?.outputType || NodeOutputDefaults.outputType) as NodeDataModal);
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setAcceptModal((data?.outputType || NodeOutputDefaults.outputType) as NodeDataModal);
        setOutputData(data?.outputData || '');
        setOutputType(data?.outputType || NodeOutputDefaults.outputType);

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.outputData) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.outputData || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.outputData, validVariables]);

    const outputTypeOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { outputType: event.target.value });

        // Update UI
        setOutputType(event.target.value as NodeOutputTypes);
        setAcceptModal(event.target.value as NodeDataModal);
    };

    const outputDataOnChangeTextArea = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { outputData: value });

        // Update UI
        setOutputData(value);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeSelect
                        onChange={outputTypeOnChange}
                        value={outputType}
                        title='Type'
                        toolTipText='Output data type'
                        bottomMargin
                    >
                        {Object.values(NodeOutputTypes).map(t => <option value={t}>{t}</option>)}
                    </NodeSelect>
                    <NodeTextArea
                        className={`nodeoutput-data-input-${id}`}
                        title='Data'
                        toolTipText='Output data'
                        validVariables={validVariables}
                        onChange={outputDataOnChangeTextArea}
                        value={outputData}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodeoutput-data-input-${id}`, text: outputData, textOnChangeHandler: outputDataOnChangeTextArea }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'out',
    name: 'Output',
    description: 'Handles text, image, audio, video, and file outputs',
    iconFile: 'node-icon-out.svg',
    color: '#E04B6F',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/output#output',
    category: NodeCategory.output
};

export default NodeOutput;
export { nodeInfo };