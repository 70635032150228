import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Profile.scss';
import BSNav, { NavType } from '../../components/navbar/BSNav';
import Divider from '../../components/divider/Divider';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Auth } from 'aws-amplify';
import BSLogo from '../../components/icons/BSLogo';
import AccountCard from '../../components/cards/accountCard/AccountCard';
import PlanCard from '../../components/cards/planCard/PlanCard';
import WorkspaceManagementCard from '../../components/cards/workspaceManagementCard/WorkspaceManagementCard';
import AuthService from '../../services/AuthService';
import { fetchWorkspaceAsync, getWorkspaces, getSelectedWorkspace } from '../../features/workspace/workspaceSlice';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { persistSelectedWorkspace } from '../../util/logic';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import TokenUsageCard from '../../components/cards/tokenUsageCard/TokenUsageCard';

export enum ProfileSection {
    account, credit, workspace, tokenUsage
}

export enum AccountSection {
    profile, security
}

export enum SubscriptionSection {
    subscription, bills
}

export enum PlanSection {
    subscription, bill
}

const Profile: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { profile_id } = useParams();
    const [selectedProfileSection, setSelectedProfileSection] = useState<ProfileSection>(ProfileSection.account);

    const workspaces = useAppSelector(getWorkspaces);
    const selectedWorkspace = useAppSelector(getSelectedWorkspace);

    const [shouldShowContactPop, setShouldShowContactPop] = useState(false);
    const [contactPopAnchorEl, setContactPopAnchorEl] = useState<null | HTMLElement>(null);

    const contactOnClick = (event: React.MouseEvent<HTMLElement>) => {
        setContactPopAnchorEl(event.currentTarget);
        setShouldShowContactPop((previousOpen) => !previousOpen);
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, []);

    useEffect(() => {
        if (profile_id === 'account') {
            setSelectedProfileSection(ProfileSection.account);
        } else if (profile_id === 'credits') {
            setSelectedProfileSection(ProfileSection.credit);
        } else if (profile_id === 'workspace') {
            setSelectedProfileSection(ProfileSection.workspace);
        } else if (profile_id === 'tokenusage') {
            setSelectedProfileSection(ProfileSection.tokenUsage);
        } else {
            setSelectedProfileSection(ProfileSection.account);
        }
    }, [profile_id]);

    const logout = async () => {
        await AuthService.signOut();
        console.log('Logged out');
        navigate('/auth');
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(result => {
                if (result) {
                    console.log('authenticated');
                    dispatch(fetchWorkspaceAsync());
                } else {
                    console.log('not authenticated');
                }
            })
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, []);

    const handleSelectedWSOnChange = (e: SelectChangeEvent<number>) => {
        persistSelectedWorkspace(+e.target.value);
        dispatch(fetchWorkspaceAsync());
    };

    return (
        <div className='profile-bg'>
            <div className='profile-container container'>
                <div className='profile-left-section'>
                    <div className='profile-left-section-top'>
                        <a href='/'>
                            <BSLogo className='profile-logo' />
                        </a>
                        {selectedWorkspace && <FormControl variant='standard' className='profile-workspace-select'>
                            <InputLabel>Workspace</InputLabel>
                            <Select
                                value={+selectedWorkspace.id}
                                onChange={handleSelectedWSOnChange}
                                fullWidth
                            >
                                {workspaces && workspaces.map(w => <MenuItem value={w.Workspace.id}>{w.Workspace.name}</MenuItem>)}
                            </Select>
                        </FormControl>}
                        <button
                            className={`profile-left-section-tab ${selectedProfileSection === ProfileSection.account ? 'selected' : ''}`}
                            onClick={() => navigate('/profile')}
                        >
                            <i className='bi bi-person-circle ps-3' />&nbsp;Account
                        </button>
                        <button
                            className={`profile-left-section-tab ${selectedProfileSection === ProfileSection.credit ? 'selected' : ''}`}
                            onClick={() => navigate('/profile/credits')}
                        >
                            <i className='bi bi-currency-dollar ps-3' />&nbsp;Credits
                        </button>
                        <button
                            className={`profile-left-section-tab ${selectedProfileSection === ProfileSection.workspace ? 'selected' : ''}`}
                            onClick={() => navigate('/profile/workspace')}
                        >
                            <i className='bi bi-people ps-3' />&nbsp;Workspace
                        </button>
                        <button
                            className={`profile-left-section-tab ${selectedProfileSection === ProfileSection.tokenUsage ? 'selected' : ''}`}
                            onClick={() => navigate('/profile/tokenusage')}
                        >
                            <i className='bi bi-ticket-detailed ps-3' />&nbsp;Token Usage
                        </button>
                    </div>
                    <div className='profile-left-section-bottom'>
                        <Divider color={'#E2E5F1'} children='' />
                        <button onClick={logout}>
                            <i className='bi bi-box-arrow-right' />&nbsp;Log out
                        </button>
                    </div>
                </div>

                <div className='profile-right-section'>
                    <div className='profile-nav'>
                        <BSNav type={NavType.standard} />
                    </div>
                    <div className='profile-right-container'>
                        {selectedProfileSection === ProfileSection.account &&
                            <AccountCard />
                        }
                        {selectedProfileSection === ProfileSection.credit &&
                            <PlanCard />
                        }
                        {selectedProfileSection === ProfileSection.workspace &&
                            <WorkspaceManagementCard />
                        }
                        {selectedProfileSection === ProfileSection.tokenUsage &&
                            <TokenUsageCard />
                        }
                    </div>
                    <div className='profile-footnote'>
                        ©2024 by BotSquare.ai
                        <div className='profile-footnote-links'>
                            <a className='homepage-footnote-terms' target='_blank' rel='noreferrer' href='https://botsquare.gitbook.io/botsquare/'>{'Help center'}</a> | <a className='homepage-footnote-terms' target='_blank' rel='noreferrer' href='https://www.botsquare.ai/'>{'FAQs'}</a> | <button className='homepage-footnote-terms' onClick={contactOnClick} rel='noreferrer'>{'Contact us'}</button>
                        </div>
                    </div>
                </div>
            </div>
            <Popper open={shouldShowContactPop} anchorEl={contactPopAnchorEl} transition sx={{ zIndex: 999999 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box sx={{ border:1, p: 2, bgcolor: 'background.paper'}}>
                            <strong>Email</strong>
                            <br />
                            <a href='mailto:info@arclightsoftware.com' className=''>
                                info@arclightsoftware.com
                            </a>
                            <br />
                            <br />
                            <strong>China Mainland Customer?</strong>
                            <br />
                            <a href='tel:+8615951675015' className='btn btn-link text-light-primary  p-0'>
                                +86 15951675015
                            </a>
                            <br />
                            <br />
                            <strong>On WeChat?</strong>
                            <br />
                            <img className='qr-wechat-img' src='https://bs-core-user-icons.s3.us-west-2.amazonaws.com/ContactWeChat.jpg' alt='wechat qr' />
                        </Box>
                    </Fade>
                )}
            </Popper>
        </div>
    );
};

export default Profile;