import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import './EditorPrompt.scss';

interface Props {
    show: boolean
    title: string
    description: string
    children?: ReactNode
    actionLeftTitle: string
    actionLeftOnClick?: () => void
    hideActionLeft?: boolean
    disableActionLeft?: boolean
    actionRightTitle: string
    actionRightOnClick?: () => void
    hideActionRight?: boolean
    disableActionRight?: boolean
}

const EditorPrompt: React.FC<Props> = ({ show, title, description, children, actionLeftTitle, actionLeftOnClick, actionRightTitle, actionRightOnClick, disableActionLeft, hideActionLeft, disableActionRight, hideActionRight }) => {

    return (
        <Dialog className='editbots-inputtype-dialog' open={show}>
            <DialogTitle className='editbots-inputtype-dialog-title'>{title}</DialogTitle>
            <p className='editbots-inputtype-dialog-description'>
                {description}
            </p>
            {children}
            <Divider />
            <DialogActions>
                <Button className='editbots-inputtype-dialog-cancel-btn' sx={{ textTransform: 'none' }} variant='outlined' disabled={disableActionLeft} hidden={hideActionLeft} onClick={actionLeftOnClick} color='success'>{actionLeftTitle}</Button>
                <Button className='editbots-inputtype-dialog-save-btn' sx={{ textTransform: 'none' }} variant='contained' disabled={disableActionRight} hidden={hideActionRight} onClick={actionRightOnClick} color='success'>
                    {actionRightTitle}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditorPrompt;
