import React, { useState } from 'react';
import './PlanCard.scss';
import { SubscriptionSection } from '../../../pages/profile/Profile';
import SubscriptionCard from '../subscriptionCard/SubscriptionCard';
import MyBillCard from '../myBillCard/MyBillCard';
import { getSelectedWorkspace } from '../../../features/workspace/workspaceSlice';
import { useAppSelector } from '../../../app/hooks';
import CircularProgress from '@mui/material/CircularProgress';

const PlanCard: React.FC = () => {
    const [selectedSubscriptionSection, setSelectedSubscriptionSection] = useState<SubscriptionSection>(SubscriptionSection.subscription);
    const workspace = useAppSelector(getSelectedWorkspace);

    return (
        <div className='content plan-card-container'>
            <div className='profile-tab'>
                <div
                    className={`${selectedSubscriptionSection === SubscriptionSection.subscription ? 'focus' : ''}`}
                    onClick={() => setSelectedSubscriptionSection(SubscriptionSection.subscription)}
                >
                    Credits
                </div>
                <div
                    className={`${selectedSubscriptionSection === SubscriptionSection.bills ? 'focus' : ''}`}
                    onClick={() => setSelectedSubscriptionSection(SubscriptionSection.bills)}
                >
                    My orders
                </div>
            </div>
            <div className='profile-profile-update-message ms-4'>
                <i className='bi bi-piggy-bank' /> Credits available: {workspace!! ? <strong>{workspace.currentTokens}</strong> : <CircularProgress size='10' color='info' />}
            </div>
            {selectedSubscriptionSection === SubscriptionSection.subscription &&
                <SubscriptionCard />
            }
            {selectedSubscriptionSection === SubscriptionSection.bills &&
                <MyBillCard />
            }
        </div>
    );
};

export default PlanCard;