import React, { ChangeEvent, useState, useEffect } from 'react';
import './NodeAkFaceswap.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import NodeCategory from '../NodeCategory';
import { NodeDataModal } from '../NodeManifest';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';

const NodeAkFaceswap: React.FC<NodeProps> = ({ id, data }) => {
    const [figureData, setFigureData] = useState('');
    const [faceData, setFaceData] = useState('');
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const dispatch = useDispatch();
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const acceptModal = NodeDataModal.image;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            figureData: data?.figureData || '',
            faceData: data?.faceData || '',
            outputModal: NodeDataModal.image
        });

        // Init UI
        setFigureData(data?.figureData || '');
        setFaceData(data?.faceData || '');
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setFigureData(data?.figureData || '');
        setFaceData(data?.faceData || '');

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.figureData || !data?.faceData) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.figureData || '', validVariables) || hasInvalidVariables(data?.faceData || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.figureData, data.faceData, validVariables]);

    const figureDataOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { figureData: value });

        // Update UI
        setFigureData(value);
    };

    const faceDataOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { faceData: value });

        // Update UI
        setFaceData(value);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeTextArea
                        className={`nodeakfs-figure-data-input-${id}`}
                        title='Figure image data'
                        toolTipText='Image data of the figure'
                        validVariables={validVariables}
                        onChange={figureDataOnChange}
                        value={figureData}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                        oneLine
                    />
                    <NodeTextArea
                        className={`nodeakfs-face-data-input-${id}`}
                        title='Face image data'
                        toolTipText='Image data of the face'
                        validVariables={validVariables}
                        onChange={faceDataOnChange}
                        value={faceData}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                        oneLine
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodeakfs-figure-data-input-${id}`, text: figureData, textOnChangeHandler: figureDataOnChange }, { inputElementClass: `nodeakfs-face-data-input-${id}`, text: faceData, textOnChangeHandler: faceDataOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'ak-fs',
    name: 'Akool Faceswap',
    description: 'Swap faces in images with Akool Faceswap',
    iconFile: 'node-icon-ak-fs.svg',
    color: '#9F8366',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/generative#akool-faceswap',
    category: NodeCategory.generative
};

export default NodeAkFaceswap;
export { nodeInfo };