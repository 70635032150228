import React from 'react';
import './ErrorCard.scss';
import { WorkflowErrorToDisplay } from '../../botEditor/components/editorControlPanel/EditorControlPanel';
import { useReactFlow } from 'reactflow';

interface Props {
    error: WorkflowErrorToDisplay
}

const ErrorCard: React.FC<Props> = ({ error }) => {
    const { setCenter } = useReactFlow();

    const handleCardOnClick = () => {
        setCenter(error.zoom.x, error.zoom.y, { zoom: 1.85, duration: 1000 });
    };

    return (
        <div className='errorcard-container' onClick={handleCardOnClick}>
            <div className='errorcard-content-container'>
                <div className='errorcard-content-title'>
                    <i className='bi bi-exclamation-triangle' />
                    <h6>{`[${error.id}] ${error.error.name}`}</h6>
                </div>
                <div className='errorcard-content-error'>
                    <i className='bi bi-dot'/>
                    <p>{`${error.error.description}`}</p>
                </div>
            </div>
        </div>
    );
};

export default ErrorCard;
