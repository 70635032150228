import React, { useMemo, useState } from 'react';
import './ProjectCard.scss';
import Edit from '../../../../assets/icon/pencil-alt.svg';
import EditGrey from '../../../../assets/icon/pencil-alt-grey.svg';
import Setting from '../../../../assets/icon/cog.svg';
import SettingGrey from '../../../../assets/icon/cog-grey.svg';
import { Bot } from '../../../../services/BSCore';
import { getTimeAgo } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { setSelectedBotInfo } from '../../../../features/workspace/workspaceSlice';
import BSToolTip from '../../../toolTip/TooTip';

interface ProjectCardProps {
    bot: Bot;
    onDeleteClick: (bot: Bot) => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({bot, onDeleteClick}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isEditHovered, setIsEditHovered] = useState(false);
    const [isSettingHovered, setIsSettingHovered] = useState(false);

    const randomNumber = useMemo(()=> Math.floor(Math.random() * 3) + 1, [bot]);
    
    const cardColor = `project-card__title${randomNumber}`;
    const edit = (event: React.MouseEvent) => {
        event.stopPropagation();  // Prevent the event from bubbling up to the parent container
        if (bot) {
            dispatch(setSelectedBotInfo(bot));
            navigate(`/bot/edit/${bot.id}`);
        }
    };
    const detail = () => {  
        if (bot) {
            dispatch(setSelectedBotInfo(bot));
            navigate(`/bot/${bot.id}`);
        }
    };
    return (
        <div className='project-card border rounded'>
            <div className={cardColor}>
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold fs-18 text-main'>{bot?.name}</div>
                </div>
                <div className='text-grey'>Edited {bot?.updated_at ? getTimeAgo(bot.updated_at) : ''}</div>
            </div>
            <div className='project-card__content d-flex align-items-center'>
                <div className='col-3 project-card__creator'>
                    <img src={bot?.icon} alt='bot-icon' className='bot-icon' />
                </div>
                <div className='col-9 d-flex justify-content-end'>
                    <BSToolTip marginTop={1} title={'Settings'}>
                        <button onMouseEnter={() => setIsSettingHovered(true)}
                        onMouseLeave={() => setIsSettingHovered(false)} className='btn btn-link'  onClick={detail}>
                            <img src={isSettingHovered ? Setting : SettingGrey} className='hover-icon p-0 me-3' alt='setting'/>
                        </button>
                    </BSToolTip>
                    <BSToolTip marginTop={1} title={'Edit bot'}>
                        <button onMouseEnter={() => setIsEditHovered(true)}
                        onMouseLeave={() => setIsEditHovered(false)} className='btn btn-link'  onClick={edit}>
                            <img src={isEditHovered?Edit:EditGrey} className='hover-icon p-0' alt='edit'/>
                        </button>
                    </BSToolTip>
                </div>
            </div>
        </div>
    );
};
export default ProjectCard;