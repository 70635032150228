import { NodeTypes } from 'reactflow';

import NodeStart, { nodeInfo as nodeStartInfo } from '../nodes/nodeStart/NodeStart';
import NodeOutput, { nodeInfo as nodeOutputInfo } from '../nodes/nodeOutput/NodeOutput';
import NodeOpenAILLM, { nodeInfo as nodeOpenAILLMInfo } from '../nodes/nodeOpenAILLM/NodeOpenAILLM';
import NodeWenXinYiYanLLM, { nodeInfo as nodeWenXinYiYanLLMInfo } from '../nodes/nodeWenXinYiYanLLM/NodeWenXinYiYanLLM';
import NodeAkFaceswap, { nodeInfo as nodeAkFaceswapInfo } from '../nodes/nodeAkFaceswap/NodeAkFaceswap';
import NodeVDSearch, { nodeInfo as nodeVDSearchInfo } from '../nodes/nodeVDSearch/NodeVDSearch';
import NodeResourceLoader, { nodeInfo as nodeResourceLoaderInfo } from '../nodes/nodeResourceLoader/NodeResourceLoader';
import NodeTextToAudio, { nodeInfo as nodeTextToAudioInfo } from '../nodes/nodeTextToAudio/NodeTextToAudio';
import NodeAudioToText, { nodeInfo as nodeAudioToTextInfo } from '../nodes/nodeAudioToText/NodeAudioToText';
import NodeOpenAIDALLE, { nodeInfo as nodeOpenAIDALLEInfo } from '../nodes/nodeOpenAIDALLE/NodeOpenAIDALLE';
import NodeCHSearch, { nodeInfo as nodeCHSearchInfo } from './nodeCHSearch/NodeCHSearch';
import NodeCall, { nodeInfo as nodeCallInfo } from './nodeCall/NodeCall';
import NodeSMS, { nodeInfo as nodeSMSInfo } from './nodeSMS/NodeSMS';
import NodeGoogleSearch, { nodeInfo as nodeGoogleSearchInfo } from './nodeGoogleSearch/NodeGoogleSearch';
import NodeYoutubeSearch, { nodeInfo as NodeYoutubeSearchInfo } from './nodeYoutubeSearch/NodeYoutubeSearch';
import NodeBSLLM, { nodeInfo as nodeBSLLMInfo } from './nodeBSLLM/NodeBSLLM';
import NodeBlandCall, { nodeInfo as nodeBlandCallInfo } from './nodeBlandCall/NodeBlandCall';
import NodeBaiduCall, { nodeInfo as nodeBaiduCallInfo } from './nodeBaiduCall/NodeBaiduCall';
import NodeLogic, { nodeInfo as nodeLogicInfo } from './nodeLogic/NodeLogic';

import NodeImageToText, { nodeInfo as nodeImageToTextInfo } from './nodeImageToText/NodeImageToText';

const nodeTypes: NodeTypes = {};
nodeTypes[nodeStartInfo.id] = NodeStart;
nodeTypes[nodeOutputInfo.id] = NodeOutput;
nodeTypes[nodeOpenAILLMInfo.id] = NodeOpenAILLM;
nodeTypes[nodeWenXinYiYanLLMInfo.id] = NodeWenXinYiYanLLM;
nodeTypes[nodeVDSearchInfo.id] = NodeVDSearch;
nodeTypes[nodeAkFaceswapInfo.id] = NodeAkFaceswap;
nodeTypes[nodeResourceLoaderInfo.id] = NodeResourceLoader;
nodeTypes[nodeTextToAudioInfo.id] = NodeTextToAudio;
nodeTypes[nodeAudioToTextInfo.id] = NodeAudioToText;
nodeTypes[nodeOpenAIDALLEInfo.id] = NodeOpenAIDALLE;
nodeTypes[nodeCHSearchInfo.id] = NodeCHSearch;
nodeTypes[nodeCallInfo.id] = NodeCall;
nodeTypes[nodeSMSInfo.id] = NodeSMS;
nodeTypes[nodeGoogleSearchInfo.id] = NodeGoogleSearch;
nodeTypes[NodeYoutubeSearchInfo.id] = NodeYoutubeSearch;
nodeTypes[nodeImageToTextInfo.id] = NodeImageToText;
nodeTypes[nodeBSLLMInfo.id] = NodeBSLLM;
nodeTypes[nodeBlandCallInfo.id] = NodeBlandCall;
nodeTypes[nodeBaiduCallInfo.id] = NodeBaiduCall;
nodeTypes[nodeLogicInfo.id] = NodeLogic;

export default nodeTypes;