import React, { useState, useEffect, useRef } from 'react';
import './SidebarComponent.scss';
import BotForm from '../../../../components/dialog/BotForm';
import { Bot } from '../../../../services/BSCore';
import { getBots, getDatasources, fetchWorkspaceAsync, getSelectedBot, getSelectedDataSource, setSelectedBot, setSelectedDataSource } from '../../../../features/workspace/workspaceSlice';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import BSLogo from '../../../icons/BSLogo';
import DataSourceForm from '../../../dialog/DataSourceForm';
import DataSourceCell from '../../../cells/DataSourceCell';
import BSToolTip from '../../../toolTip/TooTip';

interface SidebarComponentProps {
    activeTab: string;
    setActiveTab: (tabId: string) => void;
}

const SidebarComponent: React.FC<SidebarComponentProps> = ({ activeTab, setActiveTab }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [showBotForm, setShowBotForm] = useState(false);
    const [showDataSourceForm, setShowDataSourceForm] = useState(false);
    const { bot_id, ds_id } = useParams();
    const bots = useAppSelector(getBots);
    const datasources = useAppSelector(getDatasources);

    const selectedBot = useAppSelector(getSelectedBot);
    const selectedDataSource = useAppSelector(getSelectedDataSource);

    const listRef = useRef<HTMLDivElement | null>(null);


    const handleTabClick = (tabId: string) => {
        if (tabId === 'my-project') {
            if (selectedBot) {
                navigate(`/bot/${selectedBot}`);
            } else if (bots && bots?.length > 0) {
                navigate(`/bot/${bots[0].id}`);
            }
        } else {
            if (selectedDataSource) {
                navigate(`/datasource/${selectedDataSource}`);
            } else if (datasources && datasources?.length > 0) {
                navigate(`/datasource/${datasources[0].id}`);
            } else {
                navigate('/datasource');
            }
        }
    };
    const handleBotClick = (info: Bot): void => {
        dispatch(setSelectedBot(info.id.toString()));
        navigate(`/bot/${info.id}`);
    };

    const handleModelClose = () => {
        setShowBotForm(false);
    };
    
    const scrollToItem = async () => {
        if (ds_id) {
            const ds = document.getElementById(ds_id.toString());
            ds?.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }

        if (bot_id) {
            const bot = document.getElementById(bot_id.toString());
            bot?.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
    };

    useEffect(() => {
        scrollToItem();
    }, [bot_id, ds_id, bots, datasources]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(result => {
                if (result) {
                    dispatch(fetchWorkspaceAsync());

                    if (bot_id) { dispatch(setSelectedBot(bot_id)); }
                    if (ds_id) { dispatch(setSelectedDataSource(ds_id)); }

                } else {
                    console.log('not authenticated');
                }
            })
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, []);

    return (
        <nav id='sidebarMenu' className='side-bar-content-container'>
            <div className='side-bar-logo-container'>
                <div className='side-bar-logo'>
                    <a href='/'>
                        <BSLogo className='bs-nav-logo' />
                    </a>
                </div>
            </div>

            <div className='sidebarmenu-back-btn-container p-3'>
                <button className='btn btn-outline-secondary sidebarmenu-back-btn' onClick={() => navigate(`/`)}>
                    <i className='bi bi-arrow-bar-left' />
                    Dashboard
                </button>
            </div>
            <div className='border-bottom'>
                <div className='nav justify-content-center'>
                    <button
                        className={`nav-link ${activeTab === 'my-project' ? 'active' : ''}`}
                        id='my-project-tab'
                        onClick={() => handleTabClick('my-project')}
                        type='button'
                        role='tab'
                        aria-controls='my-project'
                        aria-selected={activeTab === 'my-project'}
                    >
                        <h6>My project</h6>
                    </button>
                    <BSToolTip marginTop={-9} title='Access AI App Knowledge Base'>
                        <button
                            className={`nav-link ${activeTab === 'data-source' ? 'active' : ''}`}
                            id='data-source-tab'
                            onClick={() => handleTabClick('data-source')}
                            type='button'
                            role='tab'
                            aria-controls='data-source'
                            aria-selected={activeTab === 'data-source'}
                        >
                            <h6>Data Source</h6>
                        </button>
                    </BSToolTip>
                </div>
            </div>
            <div className='tab-content m-3' id='nav-tabContent'>
                <div className={`tab-pane fade show ${activeTab === 'my-project' ? 'active' : ''}`} id='my-project' role='tabpanel' aria-labelledby='my-project-tab'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h6 className='text-main'>All Project</h6>
                        <div>
                            <button className='btn btn-outline-main' onClick={() => setShowBotForm(true)}>
                                <i className='bi bi-plus pe-2'></i>
                                Create
                            </button>
                        </div>
                    </div>
                    <BotForm isOpen={showBotForm} onClose={() => handleModelClose()} />
                    {bots &&
                        <div className='my-3 side-bar-list'>
                            {bots.map((bot, idx) => (
                                <li id={bot.id.toString()} className='list-unstyled py-2' key={idx}>
                                    <button className={`rounded list-bots-btn w-100 text-secondary ${bot.id === Number(bot_id) ? 'active' : ''}`} onClick={() => handleBotClick(bot)} >
                                        <div className='list-bots-btn-content d-flex align-items-center' >
                                            <img src={bot.icon} width={'30px'} height={'30px'} style={{ objectFit: 'cover', borderRadius: '20%' }} alt='' />
                                            <div className='px-2'>{bot.name}</div>
                                        </div>
                                    </button>
                                </li>
                            ))}
                        </div>
                    }
                </div>
                <div className={`tab-pane fade show ${activeTab === 'data-source' ? 'active' : ''}`} id='data-source' role='tabpanel' aria-labelledby='data-source-tab'>
                    <div className='side-bar-ds-add'>
                        <h1>All data sources</h1>
                        <button onClick={() => setShowDataSourceForm(true)}>+ Add</button>
                    </div>
                    {datasources &&
                        <div id='ds-list' className='my-3 side-bar-list' ref={listRef}>
                            {datasources.map(datasource => (
                                <DataSourceCell datasource={datasource} />
                            ))}
                        </div>
                    }
                    <DataSourceForm isOpen={showDataSourceForm} onClose={() => setShowDataSourceForm(false)} />
                </div>
            </div>
        </nav>
    );
};

export default SidebarComponent;