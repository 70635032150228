import React from 'react';
import './Divider.scss';

interface Props {
    color?: string;
    children?: string;
}

const Divider: React.FC<Props> = ({ color, children }) => {
    return (  
        <div>         
            { children === '' ?
                <div className='divider-container'>
                    <div className='divider-border' style={{ borderColor: color }} />
                </div>:
                <div className='divider-container'>
                    <div className='divider-border' style={{ borderColor: color }} />
                    <span className='divider-content'>
                        {children}
                    </span>
                    <div className='divider-border' style={{ borderColor: color }} />
                </div>
            }
        </div>
    );
};

export default Divider;