import React, { useEffect, useState } from 'react';
import './MyBillCard.scss';
import { getPaymentOrders, PaymentOrder } from '../../../services/BSCore';
import { useAppSelector } from '../../../app/hooks';
import { getSelectedWorkspace } from '../../../features/workspace/workspaceSlice';
import { formatISODateTim } from '../../../utils';

export enum OrderStatus {
    pending = 'Pending',
    fulfilled = 'Fulfilled',
    aborted = 'Aborted',
    status = 'Status'
};

const MyBillCard: React.FC = () => {    
    let header = { 'id': 'Order#',  'created_at': 'Purchase Time',  'amount': 'Token Amount', 'price': 'Cost', 'status': OrderStatus.status, 'workspaceId': 0, 'memberId': 0 };
    const workspace = useAppSelector(getSelectedWorkspace);
    const [paymentOrders, setPaymentOrders] = useState<PaymentOrder[]>([]);

    useEffect(() => {
        if (!workspace) return;

        getPaymentOrders(workspace.id)
        .then(orders => setPaymentOrders(orders))
        .catch(e => console.error('Error getting orders', e));
    }, [workspace]);

    return (
        <div className='my-bill-content-container'>
            <div className='my-bill-content-title'>Past orders</div>

            <div className='my-bill-content'>
                <BillRow className='my-bill-content-header' order={header}/>
                <div className='my-bill-content-rows'>
                    {paymentOrders.map( order => 
                        <BillRow order={order}/>
                    )}
                </div>
            </div>
        </div>
    );
};

interface BillRowProps {
    className?: string;
    order: any
}

const BillRow: React.FC<BillRowProps> = ({ className, order }) => {
    let shouldParse = true;
    if (className === 'my-bill-content-header') shouldParse = false;

    return (
        <div className={`my-bill-row ${className}`} >
            <div className='my-bill-row-section id'>{order.id}</div>
            <div className='my-bill-row-section price'>${shouldParse ? +order.price/100 : order.price}</div>
            <div className='my-bill-row-section amount'>{shouldParse ? `${+order.amount/1000}K` : order.amount}</div>
            <div className='my-bill-row-section date'>{shouldParse ? formatISODateTim(order.created_at) : order.created_at}</div>
            <div className={`my-bill-row-section status ${order.status}`}><div>{order.status}</div></div>
        </div>
    );
};

export default MyBillCard;