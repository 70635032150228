import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Template } from '../../services/BSCore';

export interface TemplateState {
    templates: Template[] | null;
}
const initialState: TemplateState = {
    templates: null
};
export const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setTemplate: (state, action: PayloadAction<Template[]>) => {
            state.templates = action.payload;
        },
        clearData: (state) => {
            state.templates = null;
        }
    },
});
export const { setTemplate } = templateSlice.actions;

export const getTemplates = (state: RootState) => state.template.templates;

export default templateSlice.reducer;