import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import workspaceReducer from '../features/workspace/workspaceSlice';
import botEditorReducer from '../features/botEditor/botEditorSlice';
import templateReducer from '../features/template/templateSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    workspace: workspaceReducer,
    botEditor: botEditorReducer,
    template: templateReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
