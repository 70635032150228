import React, { useMemo, useRef, useState } from 'react';
import './DataSourceCard.scss';
import Setting from '../../../../assets/icon/cog.svg';
import SettingGrey from '../../../../assets/icon/cog-grey.svg';

import { DataSource} from '../../../../services/BSCore';
import { getTimeAgo } from '../../../../utils';
import { useNavigate } from 'react-router-dom';

import { Divider, Menu, MenuItem } from '@mui/material';
import { DataSourceType } from '../../../dialog/DataSourceForm';
import Qdrant from '../../../../assets/icon/quadrant.svg';
import Message from '../../../../assets/icon/message.svg';
import Video from '../../../../assets/icon/video.svg';

interface DataSourceCardProps {
    dataSource: DataSource;
    onDeleteClick: (dataSource: DataSource) => void;
}

const dataSourceStrMap: { [key in DataSourceType]: string } = { [DataSourceType.qdrant]: 'Qdrant', [DataSourceType.youtube]: 'YouTube', [DataSourceType.chatHistory]: 'Chat History' };
const dataSourceImageMap: { [key in DataSourceType]: string } = { [DataSourceType.qdrant]: Qdrant, [DataSourceType.youtube]: Video, [DataSourceType.chatHistory]: Message };


const DataSourceCard: React.FC<DataSourceCardProps> = ({dataSource, onDeleteClick}) => {
    const [showMoreMenu, setShowMoreMenu] = useState(false);
    const [isSettingHovered, setIsSettingHovered] = useState(false);

    const moreMenuRef = useRef(null); // Corrected typo in ref name

    const navigate = useNavigate();

    const randomNumber = useMemo(()=> Math.floor(Math.random() * 3) + 1, [dataSource]);
    
    const cardColor = `dataSource-card__title${randomNumber}`;


    const viewInfo = () => {
        if (dataSource) {
            navigate(`/datasource/${dataSource.id}`);
        }
    };

    return (
        <div className='dataSource-card border rounded'>
            <div className={cardColor}>
                <div className='bg-white title border-bottom'>
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold fs-18 text-main'>
                            {dataSource?.name + ' ' + dataSource?.id}
                        </div>
                    </div>
                    <div className='text-grey'>Edited {dataSource?.updated_at ? getTimeAgo(dataSource.updated_at) : ''}</div>
                </div>
            </div>
            <div className='dataSource-card__content d-flex'>
                <div className='col-9 dataSource-card__creator d-flex'>
                    <img alt={dataSource?.type} src={dataSource?.type ? dataSourceImageMap[dataSource?.type] : '...'}/>
                    <div className = 'ps-2'>
                        <div className='text-grey text-uppercase fs-12'>Type of Data</div>
                        <div className='dataSource-card__creator-name fs-14 text-main'>{dataSource?.type ? dataSourceStrMap[dataSource?.type] : 'Other'}</div>
                    </div>
                </div>
                <div className='col-3 d-flex justify-content-end'>
                    <button onMouseEnter={() => setIsSettingHovered(true)}
                    onMouseLeave={() => setIsSettingHovered(false)} className='btn btn-link'  onClick={viewInfo}>
                        <img src={isSettingHovered?Setting:SettingGrey} className='hover-icon p-0 me-3' alt='setting'/>
                    </button>
                </div>
            </div>
            <Menu
                className='editornav-profile-menu'
                anchorEl={moreMenuRef.current}
                open={showMoreMenu}
                onClose={() => setShowMoreMenu(false)}
            >
                <MenuItem
                    className='editornav-profile-menu-item'
                    onClick={() => { setShowMoreMenu(false); }}
                >Edit profile</MenuItem>
                <MenuItem
                    className='editornav-profile-menu-item'
                    onClick={() => { setShowMoreMenu(false); }}
                >Replace data file</MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => { setShowMoreMenu(false); }}
                    className='editornav-profile-menu-item'>Delete file</MenuItem>
            </Menu>
            
        </div>
    );
};

export default DataSourceCard;
