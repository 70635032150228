import React from 'react';
import { Position, Handle, HandleType } from 'reactflow';

import './StyledHandle.scss';

interface Props {
    type: HandleType,
    position: Position
};

const StyledHandle: React.FC<Props> = ({ type, position }) => {

    return (
        <Handle className='styled-handle' type={type} position={position} />
    );
};

export default StyledHandle;
