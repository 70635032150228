import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';
import App from './App';
import './index.scss'; 
import './i18n';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import awsExportsQA from './aws-exports-qa';
import { env, BSENV } from './config';

if (env === BSENV.qa || env === BSENV.local) {
    Amplify.configure(awsExportsQA);
} else {
    Amplify.configure(awsExports);
}

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        {App}
    </Provider>
);
