import React from 'react';
import './TemplateCard.scss';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Template } from '../../../services/BSCore';
import BotForm from '../../dialog/BotForm';
interface TemplateCardProps {
    card: Template;
}

const TemplateCard: React.FC<TemplateCardProps> = ({card}) => {
    const [showForm, setShowForm] = React.useState(false);
    const onTemplateClick = () => {
        setShowForm(true);
    };

    return (
        <div className='templates-card justify-content-between mb-4' style={{ backgroundColor: card.theme }}>
            <div className='templates-card-info'>
                <div>
                    <div className='templates-card-title'>{card.name}</div>
                    <div className='templates-card-description'>{card.description}</div>
                </div>
                <button className='templates-card-url btn btn-link' onClick={onTemplateClick}>Get started<KeyboardArrowRightIcon fontSize='small' /></button>
            </div>
            <img className='templates-card-thumbnail' src={card.icon} alt='card-thumbnail'></img>
            <BotForm isOpen={showForm} onClose={() => setShowForm(false) } template ={card} />
        </div>
    );
};
export default TemplateCard;