import { useEffect, useState } from 'react';
import AnimateHeight, { Height } from 'react-animate-height';
import { hexToRGBA } from '../../utils';

import NodeManifest from '../../nodes/NodeManifest';
import './NodeValidVariables.scss';

interface Props {
    validVariables: string[]
    inputTargets: InputTarget[]
};

interface NodeVariableBadgeProps {
    variableName: string
    inputTargets: InputTarget[]
};

interface InputTarget {
    inputElementClass: string,
    text: string,
    textOnChangeHandler: (data: string) => void
};

const NodeVariableBadge: React.FC<NodeVariableBadgeProps> = ({ variableName, inputTargets }) => {
    const nodeInfo = NodeManifest.find(n => n.id === variableName.substring(0, variableName.lastIndexOf('-')))!;
    const [focusedInputElement, setFocusedInputElement] = useState<HTMLTextAreaElement | null>(null);
    const [focusedInputElementCursorIndex, setFocusedInputElementCursorIndex] = useState<number>(0);
    const badgeStyle = {
        border: `1px solid ${nodeInfo.color}`,
        background: `${hexToRGBA(nodeInfo.color, 0.15)}`,
        color: nodeInfo.color,
    };

    const handleVariableBadgeOnClick = () => {
        // No valid input targets
        if (!inputTargets || inputTargets.length === 0) { console.log('no valid inputs!'); return; }

        // Check each input target
        for (const inputTarget of inputTargets) {
            // Find element
            const inputElement = document.querySelector(`.${inputTarget.inputElementClass}`) as HTMLTextAreaElement;
            if (!inputElement) { console.warn('Invalid input class:', inputTarget.inputElementClass); return; }

            // If focused
            if (focusedInputElement === inputElement) {
                // Insert tag at cursor location
                inputTarget.textOnChangeHandler(inputTarget.text.substring(0, focusedInputElementCursorIndex) + `{${variableName}}` + inputTarget.text.substring(focusedInputElementCursorIndex));
                return;
            }
        }

        // No focused elements, append to first input
        inputTargets[0].textOnChangeHandler(`${inputTargets[0].text}{${variableName}}`);
    };

    const handleOnPointerEnter = () => {
        // Find currently focused text area element
        const inputElement = document.activeElement as HTMLTextAreaElement;
        if (!inputElement) return;

        setFocusedInputElement(inputElement);
        setFocusedInputElementCursorIndex(inputElement.selectionStart);
    };

    return (
        <div key={variableName} style={badgeStyle} onPointerEnter={handleOnPointerEnter} onClick={handleVariableBadgeOnClick} className='nodevariablebadge-wrapper'>{variableName}</div>
    );
};

const NodeValidVariables: React.FC<Props> = ({ validVariables, inputTargets }) => {
    const [height, setHeight] = useState<Height>(0);

    useEffect(() => {
        setHeight('auto');
    }, [validVariables]);

    const setHeightToNumeric = (newHeight: Height) => {
        if (height === 'auto') {
            setHeight(newHeight);
        }
    };

    return (
        <AnimateHeight
            duration={350}
            height={height}
            onHeightAnimationEnd={setHeightToNumeric}
        >
            <div className='nodevalidvariables-container'>{validVariables.map(v => <NodeVariableBadge key={v} variableName={v} inputTargets={inputTargets} />)}</div>
        </AnimateHeight>
    );
};

export default NodeValidVariables;