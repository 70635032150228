import React, { useState } from 'react';
import { NodeInfo } from '../../nodes/NodeManifest';
import ToolboxCard from '../toolboxCard/ToolboxCard';
import './ToolboxFolder.scss';
import NodeCategory from '../../nodes/NodeCategory';
import AnimateHeight from 'react-animate-height';

interface Props {
    title: string,
    nodeCategory: NodeCategory,
    nodes: NodeInfo[]
}

const ToolboxFolder: React.FC<Props> = ({ title, nodeCategory, nodes }) => {
    const [folderExpanded, setFolderExpanded] = useState(false);
    const [arrowRotation, setArrowRotation] = useState(0);
    const toggleFolder = () => {
        setFolderExpanded(!folderExpanded);
        setArrowRotation(folderExpanded ? 0 : 180);
      };

    return (
        <div className='toolboxfolder-container' >
            <button onClick={toggleFolder} className='toolboxfolder-button'>
                {title}
                <i className='bi bi-chevron-down toolboxfolder-button-arrow' style={{ transform: `rotate(${arrowRotation}deg)` }} />
            </button>
            <AnimateHeight
                duration={250}
                height={folderExpanded ? 'auto' : 0}
            >
                <div className='toolboxfolder-nodes-container'>
                    {
                        nodes.filter(node => node.category === nodeCategory).map(node => <ToolboxCard {...node} />)
                    }
                </div>
            </AnimateHeight>
        </div>
    );
};

export default ToolboxFolder;