import React, { useEffect, useState } from 'react';
import './AccountCard.scss';
import ProfileCard from '../profileCard/ProfileCard';
import { AccountSection } from '../../../pages/profile/Profile';
import SecurityCard from '../securityCard/SecurityCard';
import { useParams } from 'react-router-dom';
import ConfirmationBanner from '../../confirmationBanner/ConfirmationBanner';

const AccountCard: React.FC = () => {
    const { sub_id } = useParams();
    const [selectedAccountSection, setSelectedAccountSection] = useState<AccountSection>(AccountSection.profile);
    const [showUpdateMessage, setShowUpdateMessage] = useState(false);

    useEffect(() => {
        if (sub_id) {
            if (sub_id === 'profile') {}
        }
    }, [sub_id]);

    return (
        <div className='content account-card-container'>
            <div className='profile-tab'>
                <div
                    className={`${selectedAccountSection === AccountSection.profile ? 'focus' : ''}`}
                    onClick={() => setSelectedAccountSection(AccountSection.profile)}
                >
                    Profile
                </div>
                <div
                    className={`${selectedAccountSection === AccountSection.security ? 'focus' : ''}`}
                    onClick={() => setSelectedAccountSection(AccountSection.security)}
                >
                    Security
                </div>
            </div>
            <ConfirmationBanner showUpdateMessage={showUpdateMessage} updated='Profile'/>
            { selectedAccountSection === AccountSection.profile &&
                <ProfileCard showConfirmation={ setShowUpdateMessage }/>
            }
            { selectedAccountSection === AccountSection.security &&
                <SecurityCard />
            }
        </div>
    );
};

export default AccountCard;