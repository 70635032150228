import React, { useMemo, useState } from 'react';
import './HomeContent.scss';
import { Divider } from '@mui/material';
import ProjectCard from '../../cards/home/projectCard/ProjectCard';
import BotForm from '../../dialog/BotForm';
import { Bot, deleteBot, fetchBots, Template } from '../../../services/BSCore';
import DeleteModel from '../../models/DeleteModel';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchWorkspaceAsync, getSelectedWorkspace } from '../../../features/workspace/workspaceSlice';
import { useNavigate } from 'react-router-dom';
import ProjectEmptyCard from '../../cards/home/projectCard/ProjectEmptyCard';
import moment from 'moment';
import TemplateCard from '../../cards/templateCard/TemplateCard';
import config from '../../../config';
const { TEMPLATE_BLACK } = config;


interface HomeContentProps {
    bots?: Bot[] | undefined | null;
    isLoading?: boolean;
    templates?: Template[] | undefined | null;
    setActiveTab: (tabId: string) => void;
}
const HomeContent: React.FC<HomeContentProps> = ({bots, templates, setActiveTab}) => {

    const [showProjectForm, setShowProjectForm] = useState(false); // Add state for showing project form
    const [deleteBotInfo, setDeleteBotInfo] = useState<Bot>();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const workspace = useAppSelector(getSelectedWorkspace);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onDeleteCardClick = (bot:Bot)=>{
        setShowConfirmation(true);
        setDeleteBotInfo(bot);
    };
    const handleDelete = async () => {
        if (deleteBotInfo?.id && workspace) {
            await deleteBot(deleteBotInfo?.id);
            dispatch(fetchWorkspaceAsync());
            const bots = await fetchBots(workspace.id);
            setShowConfirmation(false);
            if (bots && bots.length > 0) {
                navigate(`/bot/${bots[0].id}`);
            }
        }
    };

    const sortedBots = useMemo(() => {
        if (!bots || bots.length === 0) {
            return [];
        }

        return [...bots].sort((a, b) => {
            const dateA = moment.utc(a.updated_at).valueOf();
            const dateB = moment.utc(b.updated_at).valueOf();
            return dateB - dateA;
        });
    }, [bots]);

    return (
        <div className='content'>
            <h4>Recent Project</h4>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='text-grey m-0'>Continue the project you left off, or build something new.</p>
                {bots?.length !== 0 &&
                <button className='btn btn-outline-main' onClick={() => setShowProjectForm(true)}>
                    <i className='bi bi-plus-lg px-2'></i>
                    Create a new project
                </button>
                }   
            </div>
            <div>
                <div className='project row'>

                    {sortedBots?.length === 0
                        ?
                            <div className='col-12 col-md-3'>
                                <ProjectEmptyCard setShowProjectForm={setShowProjectForm}/>
                            </div>
                        :
                            <div className='home-project row'>
                                {sortedBots?.slice(0, 3)?.map((bot, index) => (
                                    <div className='col-4 col-xxl-3' key={index}>
                                        <ProjectCard bot={bot} onDeleteClick={onDeleteCardClick} />
                                    </div>
                                ))}
                            </div>

                           
}
                </div>
            </div>
            <Divider className='my-4'/>
            <h4>Popular templates</h4>
            <div className='d-flex justify-content-between align-items-center mb-4' >
                <p className='text-grey m-0'>Get started to create a new project by choosing our popular template.</p>
                <button className='text-main btn btn-link text-decoration-none' onClick={() => setActiveTab('templates')}>
                    All templates
                </button>   
            </div>
            <div>

                <div className='home-template row'>
                    {templates?.slice(0,4)?.filter(t => !TEMPLATE_BLACK.includes(t.id)).map((template) => (
                        <div className='col-6 col-xxl-4'>
                            <TemplateCard card = {template}/>
                        </div>
                    ))}
                </div>
            </div>
            
            <DeleteModel
                showDeleteModal={showConfirmation}
                title='Delete project?'
                content={`All the data associated with this project will be erased forever if you delete it. Are you sure you want to delete project ${deleteBotInfo?.name}?`}
                onClose={() => setShowConfirmation(false)}
                handleDelete={handleDelete}
            />
            <Divider className='my-4'/>
            <BotForm isOpen={showProjectForm} onClose={() => setShowProjectForm(false)} />
        </div>
    );
};
export default HomeContent;