import React from 'react';
import { Workflow } from '../../../services/BSCore';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import './EditorSideUtil.scss';
import BSToolTip from '../../../components/toolTip/TooTip';

interface Props {
    isVisualMode: boolean
    setIsVisualMode: React.Dispatch<React.SetStateAction<boolean>>
    workflows: Workflow[]
    workflow: Workflow | undefined
    setWorkflow: React.Dispatch<React.SetStateAction<Workflow | undefined>>
    handleEditModal: ()=>void
}

enum EditorMode {
    visual = 'visual',
    code = 'code'
}

export enum InputType {
    text = 'text',
    image = 'image',
    video = 'video',
    audio = 'audio',
    file = 'file',
    location = 'location',
    greeting = 'greeting'
};

export type InputTypeInfo = {
    [key in InputType]: { iconId: string, description: string }
};

export const inputTypeInfo: InputTypeInfo = {
    [InputType.text]: { iconId: 'bi-blockquote-left', description: 'Input type: text' },
    [InputType.image]: { iconId: 'bi-image', description: 'Input type: image' },
    [InputType.video]: { iconId: 'bi-camera-video', description: 'Input type: video' },
    [InputType.audio]: { iconId: 'bi-mic', description: 'Input type: audio' },
    [InputType.file]: { iconId: 'bi-file-earmark-text', description: 'Input type: file' },
    [InputType.location]: { iconId: 'bi-geo', description: 'Input type: location' },
    [InputType.greeting]: { iconId: 'bi-chat-dots', description: 'Input type: greeting' },
};

const EditorSideUtil: React.FC<Props> = ({ isVisualMode, setIsVisualMode, workflows, workflow, setWorkflow, handleEditModal }) => {

    const handleEditorModeToggleTap = (_: React.MouseEvent<HTMLElement>, tappedView: string) => {
        if (!tappedView) return;
        setIsVisualMode(tappedView === EditorMode.visual ? true : false);
    };

    const handleInputTypeToggleTap = (_: React.MouseEvent<HTMLElement>, tappedView: string) => {
        if (!tappedView) return;
        setWorkflow(workflows.find(w => w.type === tappedView));
    };

    return (
        <div className='editorsideutil-container'>
            <div className='editorsideutil-inputtype-container'>
                <ToggleButtonGroup
                    color='primary'
                    orientation='vertical'
                    value={workflow?.type}
                    exclusive
                    onChange={handleInputTypeToggleTap}
                    className='editorsideutil-toggle-group'
                >
                    {workflows.filter(w => w.enabled).map(w =>
                        <ToggleButton
                            value={w.type}
                        >
                            <BSToolTip marginTop={2} marginLeft={20} placement='right-end' title={inputTypeInfo[w.type as InputType].description}>
                                <i className={`bi ${inputTypeInfo[w.type as InputType].iconId}`} />
                            </BSToolTip>
                        </ToggleButton>
                    )}
                    <BSToolTip marginTop={4} marginLeft={9} placement='right-end' title='Edit input types'>
                        <IconButton onClick={handleEditModal}>
                            <i className='bi bi-pencil-square'/>
                        </IconButton>
                    </BSToolTip>
                </ToggleButtonGroup>
            </div>
            <div className='editorsideutil-editormode-container'>
                <ToggleButtonGroup
                    color='primary'
                    orientation='vertical'
                    value={isVisualMode ? EditorMode.visual : EditorMode.code}
                    exclusive
                    onChange={handleEditorModeToggleTap}
                    className='editorsideutil-toggle-group'
                >
                    <ToggleButton value={EditorMode.visual}>
                        <BSToolTip marginTop={4} marginLeft={20} placement='right-end' title='Switch to Visual Editor'>
                            <i className='bi bi-diagram-2' />
                        </BSToolTip>
                    </ToggleButton>
                    <ToggleButton value={EditorMode.code}>
                        <BSToolTip marginTop={4} marginLeft={20} placement='right-end' title='Switch to Code Editor'>
                            <i className='bi bi-code-slash' />
                        </BSToolTip>
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div >
    );
};

export default EditorSideUtil;
