import React from 'react';
import { Position, Handle } from 'reactflow';

import './FalseHandle.scss';

const FalseHandle: React.FC = () => {

    return (
        <Handle id='false-handle' className='false-handle' type='source' position={Position.Right} />
    );
};

export default FalseHandle;
