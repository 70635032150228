import React from 'react';
import { NodeInfo } from '../../nodes/NodeManifest';
import './NodeHeader.scss';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useReactFlow } from 'reactflow';
import { getNodeId, getNodeInfo } from '../../utils';
import BSToolTip from '../../../components/toolTip/TooTip';


interface Props {
    nodeInfo: NodeInfo,
    id: string
}

const NodeHeader: React.FC<Props> = ({ nodeInfo, id }) => {
    const [optionsAnchorEl, setOptionsAnchorEl] = React.useState<null | HTMLElement>(null);
    const showOptionsMenu = !!optionsAnchorEl;
    const { setNodes, setEdges, getNodes } = useReactFlow();
    const nodes = getNodes();

    const handleInfoBtnOnClick = () => {
        const newWindow = window.open(nodeInfo.docUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const handleDeleteOnClick = () => {
        setOptionsAnchorEl(null);
        setNodes(node => node.filter(n => n.id !== id));
        setEdges(edge => edge.filter(e => {
            return e.source !== id && e.target !== id;
        }));
    };

    const handleDuplicateOnClick = () => {
        setOptionsAnchorEl(null);
        const newNodeInfo = getNodeInfo(nodeInfo.id);

        // Calculate new node position
        let currentNodePosition = (nodes.find(n => n.id === id))?.position;
        if (!currentNodePosition) currentNodePosition = { x: 0, y: 0 };

        const newNode = {
            id: getNodeId(nodes, nodeInfo.id),
            type: nodeInfo.id,
            position: { x: currentNodePosition.x + 220, y: currentNodePosition.y },
            data: {
                nodeInfo: newNodeInfo
            },
        };
        setNodes((nds) => nds.concat(newNode));
    };

    const handleOptionsMenuClose = () => {
        setOptionsAnchorEl(null);
    };

    const handleOptionsBtnOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOptionsAnchorEl(event.currentTarget);
    };

    return (
        <div className='node-header-container'>
            <div style={{ backgroundColor: nodeInfo.color }} className='node-header-theme-bar' />
            <div className='node-header-content-container'>
                <img src={`/images/${nodeInfo.iconFile}`} alt='Profile' />
                <div className='node-header-content-info-container'>
                    <h1>{nodeInfo.name}</h1>
                    <BSToolTip title='Module ID'>
                        <p>{id}</p>
                    </BSToolTip>
                </div>
                <div className='node-header-content-action-container'>
                    <BSToolTip title='Usage guide'>
                        <IconButton aria-label='info' size='small' onClick={handleInfoBtnOnClick}>
                            <i className='bi bi-info-circle' />
                        </IconButton>
                    </BSToolTip>
                    <IconButton aria-label='options' size='small' onClick={handleOptionsBtnOnClick}>
                        <i className='bi bi-three-dots-vertical' />
                    </IconButton>
                </div>
            </div>
            <Menu
                id='basic-menu'
                anchorEl={optionsAnchorEl}
                open={showOptionsMenu}
                onClose={handleOptionsMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    'dense': true
                }}
            >
                <MenuItem onClick={handleDuplicateOnClick}><i className='bi bi-files' />&nbsp;Duplicate</MenuItem>
                <MenuItem onClick={handleDeleteOnClick}><i className='bi bi-trash' />&nbsp;Delete</MenuItem>
            </Menu>
        </div>
    );
};

export default NodeHeader;
