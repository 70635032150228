import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './NavbarComponent.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { clearData, fetchWorkspaceAsync, getBots, getSelectedWorkspace, getWorkspaces, setBots } from '../../../../features/workspace/workspaceSlice';
import { Box, Divider, Drawer, List, ListItem, Menu, MenuItem } from '@mui/material';

import { Auth } from 'aws-amplify';
import AuthService from '../../../../services/AuthService';
import { Bot, fetchBots, fetchMemberData, MemberObject } from '../../../../services/BSCore';
import CloseIcon from '@mui/icons-material/Close';
import { persistSelectedWorkspace } from '../../../../util/logic';

type Anchor = 'left' | 'top';

const NavbarComponent: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const profileImgRef = useRef(null);
    const location = useLocation();
    const currentPath = location.pathname;

    const [avatar, setAvatar] = useState('');
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [wsOpen, setWSOpen] = useState(false);
    const [botOpen, setBotOpen] = useState(false);

    const [selectedBot, setSelectedBot] = useState<Bot | undefined>(undefined);

    const workspaces = useAppSelector(getWorkspaces);
    const selectedWorkspace = useAppSelector(getSelectedWorkspace);

    // in detail page
    const { bot_id } = useParams();
    const bots = useAppSelector(getBots);

    const fetchUser = async () => {
        try {
            const user = await fetchMemberData() as MemberObject;
            setAvatar(user.info.imageUrl);
        } catch (e) {
            console.error('Error while fetchMemberData in navbar');
        }
    };
    const handleLogout = async () => {
        await AuthService.signOut();
        dispatch(clearData());
        console.log('Logged out');
        navigate('/auth');
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(result => {
                if (result) {
                    fetchUser();
                    dispatch(fetchWorkspaceAsync());
                } else {
                    console.log('not authenticated');
                }
            })
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    },[avatar]);
    useEffect(() => {
        // Fetch bots data when the component mounts
        fetchBots(selectedWorkspace?.id!)
            .then(bots => {
                setBots(bots); // Update the bots state
                
            })
            .catch(error => {
                console.log(error);
            });
            const botInfo = bots?.filter((bot:Bot) => bot.id === Number(bot_id));
            setSelectedBot(botInfo?.[0]);
    }, [selectedWorkspace, bot_id]);

    const handleSelectedWSOnChange = (workspaceId:number) => {
        persistSelectedWorkspace(+workspaceId);
        dispatch(fetchWorkspaceAsync());
    };
    const handleSelectedBotOnChange = (bot: Bot) =>{
        setSelectedBot(bot);
        navigate(`/bot/${bot.id}`);
    };

    const toggleDrawer =
    (type: string, anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      if (type === 'ws'){setWSOpen(open);}
      if (type === 'bot'){setBotOpen(open);}
    };

    const list = (anchor: Anchor) => (
        <Box
            className = 'ws-drawer'
            role='presentation'
            style={{ cursor: 'pointer' }}
            onClick={toggleDrawer('ws', anchor, false)}
            onKeyDown={toggleDrawer('ws', anchor, false)}
        >
            <div className='d-flex align-items-center justify-content-between ws-drawer-title' 
            style={{ cursor: 'pointer' }}
            onClick={toggleDrawer('ws', 'left', true)}>
                <div className='d-flex align-items-center'>
                    <img className = 'ws-image' alt='ws' src = {selectedWorkspace?.info.imageUrl}></img>
                    <div className='px-2 text-main'>{selectedWorkspace?.name}</div>
                </div>
                <CloseIcon/>
            </div>
            <div className='ws-drawer-content'>
                <div className='text-main fw-bold'>Switch my workspace</div>
                <List>
                    {workspaces?.map((ws, index) => (
                        <ListItem key={index}>
                            <div className='d-flex align-items-center ws-button' 
                            style={{ cursor: 'pointer' }}
                            onClick={()=>handleSelectedWSOnChange(ws.Workspace.id)}>
                                <img className = 'ws-image' alt='ws' src = {ws.Workspace.info.imageUrl}></img>
                                <div className='px-2 text-main'>{ws.Workspace.name}</div>
                            </div>
                        </ListItem>
            ))}
                </List>
            </div>
            
        </Box>
      );

      const botsList = (anchor: Anchor) => (
          <Box
            className = 'bot-drawer w-100'
            role='presentation'
            onClick={toggleDrawer('bot', anchor, false)}
            onKeyDown={toggleDrawer('bot', anchor, false)}
        >
              <div className='d-flex align-items-center justify-content-between ws-drawer-title' 
              style={{ cursor: 'pointer' }}
              onClick={toggleDrawer('bot','top', true)}>
                  <div className='d-flex align-items-center'>
                      <img className = 'ws-image' alt='ws' src = {selectedBot?.icon}></img>
                      <div className='px-2 text-main'>{selectedBot?.name}</div>
                  </div>
                  <CloseIcon/>
              </div>
              <div className='bot-drawer-content p-4'>
                  <div className='text-main fw-bold'>Switch my project</div>
                  <List>
                      {bots?.map((bot, index) => (
                          <ListItem key={index}>
                              <div className='d-flex align-items-center ws-button' 
                              style={{ cursor: 'pointer' }}
                              onClick={()=>handleSelectedBotOnChange(bot)}>
                                  <img className = 'ws-image' alt='ws' src = {bot?.icon}></img>
                                  <div className='px-2 text-main'>{bot?.name}</div>
                              </div>
                          </ListItem>
            ))}
                  </List>
              </div>
            
          </Box>
      );

    return (
        <header className='sticky-top bg-white border d-flex justify-content-between'>
            {!currentPath.includes('/profile') && !bot_id && selectedWorkspace &&
            <div>
                <Drawer
            hideBackdrop
            anchor='left'
            open={wsOpen}
            onClose={toggleDrawer('ws','left', false)}
          >
                    {list('left')}
                </Drawer>
                <div className='d-flex align-items-center justify-content-between ws' 
                style={{ cursor: 'pointer' }}
                onClick={toggleDrawer('ws','left', true)}>
                    <div className='d-flex align-items-center'>
                        <img className = 'ws-image' alt='ws' src = {selectedWorkspace.info.imageUrl}></img>
                        <div className='px-2 text-main'>{selectedWorkspace.name}</div>
                    </div>
                    <div className = 'ws-switch-icon'></div>
                </div>
            </div>
            }
            {bot_id &&
                <div>
                    <div className='d-flex align-items-center h-100 p-2' 
                    style={{ cursor: 'pointer' }}
                    onClick={()=>navigate('/')}>
                        <i className='bi bi-arrow-left pe-2'></i>
                        My Projects
                    </div>
                </div>
            }
            {
                currentPath.includes('/profile') &&
                <div>
                    <div className='d-flex align-items-center h-100 p-2' 
                    style={{ cursor: 'pointer' }}
                    onClick={()=>navigate('/')}>
                        <i className='bi bi-arrow-left pe-2'></i>
                        Home
                    </div>
                </div>
            }
            {
                bot_id &&
                <div>
                    <Drawer
            hideBackdrop
            anchor='top'
            open={botOpen}
            onClose={toggleDrawer('bot','top', false)}
          >
                        {botsList('top')}
                    </Drawer>
                    <div className='d-flex align-items-center justify-content-between ws' 
                    style={{ cursor: 'pointer' }}
                    onClick={toggleDrawer('bot','top', true)}>
                        <div className='d-flex align-items-center'>
                            <div className='px-2 text-main'>{selectedBot?.name}</div>
                        </div>
                        <div className = 'ws-switch-icon'></div>
                    </div>
                </div>
            }
            
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex justify-content-between align-items-center right-info'>
                    <div className='home-credit d-flex align-items-center'>
                        <div><i className='bi bi-piggy-bank credit-icon' /></div>
                        <div className='px-2'>{selectedWorkspace?.currentTokens}</div>
                    </div>
                </div>
                <div className='editornav-profile-container'>
                    {avatar && <img ref={profileImgRef} 
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowProfileMenu(!showProfileMenu)} className='editornav-profile-img' src={avatar} alt='Avatar' />}
                </div>
            </div>
            
            <Menu
                className='editornav-profile-menu'
                anchorEl={profileImgRef.current}
                open={showProfileMenu}
                onClose={() => setShowProfileMenu(false)}
            >
                <MenuItem
                    className='editornav-profile-menu-item'
                    onClick={() => { setShowProfileMenu(false); navigate('/profile'); }}
                ><i className='bi bi-person' />Profile</MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => { setShowProfileMenu(false); handleLogout(); }}
                    className='editornav-profile-menu-item'><i className='bi bi-box-arrow-right' />Logout</MenuItem>
            </Menu>
        </header>
    );
};

export default NavbarComponent;