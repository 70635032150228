import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './DataSourceCell.scss';
import { DataSource } from '../../services/BSCore';
import { useAppDispatch } from '../../app/hooks';
import { setSelectedDataSource } from '../../features/workspace/workspaceSlice';
import YoutubeIcon from '../icons/YoutubeIcon';
import { DataSourceType } from '../dialog/DataSourceForm';
import QdrantIcon from '../icons/QdrantIcon';
import ChatHistoryIcon from '../icons/ChatHistoryIcon';

interface Props {
    datasource: DataSource;
    onClick?: () => void;
}

const DataSourceCell: React.FC<Props> = ({ datasource, onClick }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { ds_id } = useParams();

    const handleDataSourceClick = (info: DataSource): void => {
        dispatch(setSelectedDataSource(info.id.toString()));
        navigate(`/datasource/${info.id}`);
        onClick?.();
    };

    return (
        <button id={datasource.id.toString()} className={`ds-cell-container ${datasource.id === Number(ds_id) ? 'active' : ''}`} onClick={() => handleDataSourceClick(datasource)} >
            <div className={`ds-cell-content  ${datasource.type}`} >
                <div className='ds-cell-icon'>
                    {datasource.type === DataSourceType.qdrant &&
                        <QdrantIcon color={datasource.id === Number(ds_id) ? '#3C49CB' : '#8390BA'} />
                    }
                    {datasource.type === DataSourceType.youtube &&
                        <YoutubeIcon color={datasource.id === Number(ds_id) ? '#3C49CB' : '#8390BA'} />
                    }
                    {datasource.type === DataSourceType.chatHistory &&
                        <ChatHistoryIcon color={datasource.id === Number(ds_id) ? '#3C49CB' : '#8390BA'} />
                    }
                </div>
                {datasource.name}
            </div>
        </button>
    );
};

export default DataSourceCell;