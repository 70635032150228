import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeTextToAudio.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import { NodeDataModal } from '../NodeManifest';

import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';
import NodeSelect from '../../components/nodeSelect/NodeSelect';

export enum NodeTextToAudioVoiceTypes {
    CN_GIRL_MADARIN = 'zh-CN-XiaoshuangNeural',
    CN_LADY_TAIWAN = 'zh-TW-HsiaoChenNeural',
    CN_LADY_DONGBEI = 'zh-CN-liaoning-XiaobeiNeural',
    CN_MAN = 'zh-CN-YunyangNeural',
    US_LADY = 'en-US-JaneNeural',
    US_MAN = 'en-US-TonyNeural',
    CN_SHANDONG = 'zh-CN-shandong-YunxiangNeural',
    CN_SICHUAN = 'zh-CN-sichuan-YunxiNeural',
    TW_YUNJHE = 'zh-TW-YunJheNeural',
    CN_HENAN = 'zh-CN-henan-YundengNeural',
    CN_SHAANXI = 'zh-CN-shaanxi-XiaoniNeural',
    CN_XIAOXIAO = 'zh-CN-XiaoxiaoNeural',
    CN_XIAOQIU = 'zh-CN-XiaoqiuNeural',
    CN_YUNXI = 'zh-CN-YunxiNeural',
    CN_YUNYE = 'zh-CN-YunyeNeural',
    CN_XIAOYOU = 'zh-CN-XiaoyouNeural',
    ES_DALIA = 'es-MX-DaliaNeural',
    ES_JORGE = 'es-MX-JorgeNeural'
}

const NodeTextToAudioVoiceDefaults = {
    voiceType: NodeTextToAudioVoiceTypes.CN_GIRL_MADARIN
};

const NodeTextToAudio: React.FC<NodeProps> = ({ id, data }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [text, setText] = useState('');
    const [voiceType, setVoiceType] = useState(NodeTextToAudioVoiceDefaults.voiceType);
    const dispatch = useDispatch();
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const acceptModal = NodeDataModal.text;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            voiceType: data?.voiceType || NodeTextToAudioVoiceDefaults.voiceType,
            text: data?.text || '',
            outputModal: NodeDataModal.audio
        });

        // Init UI
        setText(data?.text || '');
        setVoiceType(data?.voiceType || NodeTextToAudioVoiceDefaults.voiceType);
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setText(data?.text || '');
        setVoiceType(data?.voiceType || NodeTextToAudioVoiceDefaults.voiceType);

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.text) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.text || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.text, validVariables]);

    const voiceTypeOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { voiceType: event.target.value, moduleDataDidChange: true });

        // Update UI
        setVoiceType(event.target.value as NodeTextToAudioVoiceTypes);
    };

    const textOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { text: value, moduleDataDidChange: true });

        // Update UI
        setText(value);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeSelect
                        onChange={voiceTypeOnChange}
                        value={voiceType}
                        title='Voice type'
                        toolTipText='Voice model to use'
                        bottomMargin
                    >
                        {Object.values(NodeTextToAudioVoiceTypes).map(t => <option value={t}>{t}</option>)}
                    </NodeSelect>

                    <NodeTextArea
                        className={`nodetexttoaudio-data-input-${id}`}
                        title='Text'
                        toolTipText='Text data to convert'
                        validVariables={validVariables}
                        onChange={textOnChange}
                        value={text}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodetexttoaudio-data-input-${id}`, text: text, textOnChangeHandler: textOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'txt-aud',
    name: 'Text to Audio',
    description: 'Convert text to voice with customizable options',
    iconFile: 'node-icon-txt-aud.svg',
    color: '#3B82F6',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/modal-conversion#text-to-audio',
    category: NodeCategory.modalCnv
};

export default NodeTextToAudio;
export { nodeInfo };