import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

// Extend TooltipProps with your custom margin properties
interface BSToolTipProps extends TooltipProps {
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
}

// Define a styled Tooltip component with extended props
const StyledTooltip = styled((props: BSToolTipProps) => (
    <Tooltip {...props} arrow classes={{ popper: props.className }} />
))<BSToolTipProps>(({ marginTop, marginBottom, marginLeft, marginRight }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 160,
        marginTop: marginTop ? `${marginTop}px !important` : undefined,
        marginBottom: marginBottom ? `${marginBottom}px !important` : undefined,
        marginLeft: marginLeft ? `${marginLeft}px !important` : undefined,
        marginRight: marginRight ? `${marginRight}px !important` : undefined,
    },
}));

// Define the functional component
const BSToolTip: React.FC<BSToolTipProps> = ({
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    ...tooltipProps
}) => {
    return (
        <StyledTooltip
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            {...tooltipProps}
        />
    );
};

export default BSToolTip;
