import React, { useState, useEffect } from 'react';
import './BotDetail.scss';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Bot, fetchBots, fetchBot, fetchBotChannels, Channel } from '../../services/BSCore';
import DeleteModel from '../models/DeleteModel';
import Deployment from './deploy/Deployment';
import BotProfile from './botProfile/BotProfile';
import Settings from './settings/Settings'; // Import the new Settings component
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setBots, getSelectedWorkspace } from '../../features/workspace/workspaceSlice';
import Skeleton from '@mui/material/Skeleton';
import BotProfileSkeleton from './botProfile/BotProfileSkeleton';
import BSToolTip from '../toolTip/TooTip';


interface BotDetailProps {
    handleBotDelete: () => void;
}

const BotDetail: React.FC<BotDetailProps> = ({ handleBotDelete }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { bot_id } = useParams();

    const location = useLocation();
    const showDeployment = location.state?.showDeployment;
    const [activeTab, setActiveTab] = useState(showDeployment ? 'nav-deployment' : 'nav-profile');
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [channels, setChannels] = useState<Channel[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    const workspaceId = useAppSelector(getSelectedWorkspace)?.id;

    const [bot, setBot] = useState<Bot>();

    useEffect(() => {
        loadBotDetailData();
    }, [bot_id]);

    const loadBotDetailData = async () => {
        try {
            if (!bot_id) return;
            setShowLoading(true);

            const bot = await fetchBot(+bot_id);
            setBot(bot);

            const channels = await fetchBotChannels(+bot_id);
            setChannels(channels);
            setShowLoading(false);
        } catch (e) {
            console.error('Error occurred while fetching bot detail data');
            setShowLoading(false);
        }
    };

    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
    };

    const handleDelete = () => {
        setShowDeleteModel(false);
        handleBotDelete();
    };

    const handleBotSaved = async () => {
        try {
            const bots = await fetchBots(Number(workspaceId));
            dispatch(setBots(bots));
            setBot(bots.find((b: Bot) => b.id === Number(bot_id)));
        } catch (e) {
            console.error('Handle bot saved failed', e);
        }
    };

    return (
        <>
            {bot &&
                <div className='bots-main-content-container'>
                    <div className='bots-title-card d-flex justify-content-between align-items-center p-3 rounded'>
                        <div>
                            <h4 className='text-main'>{bot.name}</h4>
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                            <button className='btn btn-outline-danger mx-2' onClick={() => setShowDeleteModel(true)}>
                                <i className='bi bi-exclamation-triangle pe-2'></i>
                                Delete app
                            </button>
                            <DeleteModel
                                handleDelete={() => handleDelete()}
                                onClose={() => setShowDeleteModel(false)}
                                showDeleteModal={showDeleteModel}
                                title='Delete project?'
                                content={`All the data associated with this project will be erased forever if you delete it. Are you sure you want to delete project ${bot.name}?`}
                            />
                            
                            <BSToolTip marginTop={10} title='Access the app creation workflow'>
                                <div>
                                    <button className='btn btn-outline-main font-weight-bold' onClick={() => navigate(`/bot/edit/${bot_id}`)}><i className='bi bi-pencil-square me-2'></i> Edit flow</button>
                                </div>                                
                            </BSToolTip>
                        </div>
                    </div>

                    <div className='bg-white p-3 rounded bots-info-card'>
                        <div className='d-flex border-bottom'>
                            {showLoading ?
                                <Skeleton animation='wave' height={45} width={200} /> :
                                <>
                                    <button
                                        className={`p-2 nav-link bot-link ${activeTab === 'nav-profile' ? 'active' : ''}`}
                                        id='nav-profile-tab'
                                        onClick={() => handleTabClick('nav-profile')}
                                        type='button'
                                        role='tab'
                                        aria-controls='nav-profile'
                                        aria-selected={activeTab === 'nav-profile'}
                                    >
                                        Profile
                                    </button>
                                    <button
                                        className={`p-2 nav-link bot-link ${activeTab === 'nav-deployment' ? 'active' : ''}`}
                                        id='nav-deployment-tab'
                                        onClick={() => handleTabClick('nav-deployment')}
                                        type='button'
                                        role='tab'
                                        aria-controls='nav-deployment'
                                        aria-selected={activeTab === 'nav-deployment'}
                                    >
                                        Deployment
                                    </button>
                                    <button
                                        className={`p-2 nav-link bot-link ${activeTab === 'nav-settings' ? 'active' : ''}`}
                                        id='nav-settings-tab'
                                        onClick={() => handleTabClick('nav-settings')}
                                        type='button'
                                        role='tab'
                                        aria-controls='nav-settings'
                                        aria-selected={activeTab === 'nav-settings'}
                                    >
                                        Settings
                                    </button>
                                </>
                            }
                        </div>
                        <div className='tab-content' id='nav-tabContent'>
                            <div className={`tab-pane fade show ${activeTab === 'nav-profile' ? 'active' : ''}`} id='nav-profile' role='tabpanel' aria-labelledby='nav-profile-tab'>
                                {showLoading ?
                                    <BotProfileSkeleton /> :
                                    <BotProfile
                                        botDetail={bot}
                                        onBotSaved={handleBotSaved}
                                        setShowLoading={setShowLoading}
                                    />
                                }
                            </div>
                            <div className={`tab-pane fade show ${activeTab === 'nav-deployment' ? 'active' : ''}`} id='nav-deployment' role='tabpanel' aria-labelledby='nav-deployment-tab'>
                                <Deployment
                                    botId={+bot_id!}
                                    workspaceId={+workspaceId!}
                                    loadBotDetailData={loadBotDetailData}
                                    channels={channels}
                                />
                            </div>
                            <div className={`tab-pane fade show ${activeTab === 'nav-settings' ? 'active' : ''}`} id='nav-settings' role='tabpanel' aria-labelledby='nav-settings-tab'>
                                <Settings 
                                bot={bot}
                                onBotSaved={handleBotSaved}
                                setShowLoading={setShowLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default BotDetail;
