import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './DeleteModel.scss';
interface Props {
    title: string;
    content: string;
    showDeleteModal: boolean;
    onClose: () => void;
    handleDelete: () => void;
}
const DeleteModel: React.FC<Props> = ({title, content, showDeleteModal, onClose, handleDelete}) => {    
  return (
      <>
          <Modal show={showDeleteModal}>
              <Modal.Header>
                  <Modal.Title>
                      <div className='text-main'>{title}</div>
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body><div className='text-main'>{content}</div></Modal.Body>
              <Modal.Footer>
                  <button type='button' className='btn btn-link text-main' onClick={onClose}>Cancel</button>
                  <button type='button' className= 'btn btn-danger' onClick={handleDelete}>Delete it</button>
              </Modal.Footer>
          </Modal>
      </>
  );
};
export default DeleteModel;