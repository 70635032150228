import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeCHSearch.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useAppSelector } from '../../../app/hooks';
import { getDatasources } from '../../../features/workspace/workspaceSlice';
import { DataSource } from '../../../services/BSCore';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import NodeCategory from '../NodeCategory';
import { DataSourceType } from '../../../components/dialog/DataSourceForm';
import { NodeDataModal } from '../NodeManifest';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';
import NodeSelect from '../../components/nodeSelect/NodeSelect';

const NodeCHSearchDefaults = {
    limit: 5
};

const SUPPORTED_DATASOURCE_TYPE = DataSourceType.chatHistory;

const NodeCHSearch: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const datasources = useAppSelector(getDatasources);
    const datasourcesFiltered = datasources?.filter(d => d.type === SUPPORTED_DATASOURCE_TYPE);
    const [datasource, setDatasource] = useState<DataSource | null>(null);
    const [limit, setLimit] = useState(NodeCHSearchDefaults.limit);
    const [prompt, setPrompt] = useState('');
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const acceptModal = NodeDataModal.text;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        const datasourceData = data.datasource || ((datasourcesFiltered && datasourcesFiltered.length > 0) ? datasourcesFiltered[0] : null);

        // Init data
        updateNodeData(id, setNodes, {
            datasource: datasourceData,
            prompt: data.prompt || '',
            limit: data.limit || NodeCHSearchDefaults.limit,
            outputModal: NodeDataModal.text
        });

        // Init UI
        setDatasource(datasourceData);
        setLimit(data.limit || NodeCHSearchDefaults.limit);
        setPrompt(data.prompt || '');
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        const datasourceData = data.datasource || ((datasourcesFiltered && datasourcesFiltered.length > 0) ? datasourcesFiltered[0] : null);

        // Update UI with data change
        setDatasource(datasourceData);
        setLimit(data.limit || NodeCHSearchDefaults.limit);
        setPrompt(data.prompt || '');

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.prompt) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.prompt || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.prompt, validVariables]);

    const queryOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { prompt: value });

        // Update UI
        setPrompt(value);
    };

    const limitOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { limit: +event.target.value });

        // Update UI
        setLimit(+event.target.value);
    };

    const datasourceOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { datasource: datasources?.find(d => d.id === +event.target.value), moduleKey: undefined });

        // Update UI
        setDatasource(datasources?.find(d => d.id === +event.target.value) || null);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeSelect
                        onChange={datasourceOnChange}
                        value={datasource?.id || ''}
                        title='Data source'
                        toolTipText='Search history data source instance to use'
                        bottomMargin
                    >
                        {datasourcesFiltered && datasourcesFiltered.map(d => <option value={d.id}>{d.name}</option>)}
                    </NodeSelect>
                    <NodeSelect
                        onChange={limitOnChange}
                        value={limit}
                        title='Limit'
                        toolTipText='Maximum number of results returned by a query'
                        bottomMargin
                    >
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>                    
                    </NodeSelect>
                    <NodeTextArea
                        className={`nodechsearch-query-input-${id}`}
                        title='Query'
                        toolTipText='Chat history search query'
                        validVariables={validVariables}
                        onChange={queryOnChange}
                        value={prompt}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodechsearch-query-input-${id}`, text: prompt, textOnChangeHandler: queryOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'vd-bc',
    name: 'Chat History',
    description: 'Allows users to use any chat history data',
    iconFile: 'node-icon-vd-bc.svg',
    color: '#B043C0',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/data#chat-history',
    category: NodeCategory.data
};

export default NodeCHSearch;
export { nodeInfo };