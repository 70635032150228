import ReactModal from 'react-modal';
import './GeneralAlert.scss';
import AlertSuccess from '../icons/AlertSuccess';
import AlertError from '../icons/AlertError';

export enum AlertType {
    dsSuccess, dsError, insufficientCredit
}

interface Props {
    isOpen: boolean;
    type: AlertType;
    title?: string;
    message?: string;
    secondaryAction?: () => void;
    onClose?: () => void;
}

const GeneralAlert: React.FC<Props> = ({ isOpen, type, title, message, secondaryAction, onClose }) => {
    const alertTitle = (): string => {
        if (title) {
            return title;
        }

        switch (type) {
            case AlertType.dsSuccess: { 
                return 'Success';
             } 
             case AlertType.dsError: { 
                return 'Oops!';
             } 
             default: {
                return '';
             }
        }
    };

    const alertMessage = (): string => {
        if (message) {
            return message;
        }

        switch (type) {
            case AlertType.dsSuccess: {
                return 'Congratulations! You have successfully uploaded new sources to your datasource.';
            }
            case AlertType.dsError: {
                return 'Unfortunately! We ran into an issue while trying to upload this source. Please make sure the format is correct, or try another source.';
            }
            default: {
                return '';
            }
        }
    };

    const primary = (): string => {
        switch (type) {
            case AlertType.dsSuccess: {
                return 'Continue';
            }
            case AlertType.dsError: {
                return 'Ok';
            }
            case AlertType.insufficientCredit: {
                return 'Ok';
            }
            default: {
                return '';
            }
        }
    };

    const secondary = (): string => {
        switch (type) {
            case AlertType.insufficientCredit: {
                return 'Recharge Now';
            }
            default: {
                return '';
            }
        }
    };

    const isError = (): Boolean => {
        return type !== AlertType.dsSuccess;
    };

    const hasSecondaryButton = (): Boolean => {
        return type === AlertType.insufficientCredit;
    };

    const handleClose = () => {
        onClose?.();
    };
    
    return (
        <>
            <ReactModal className='general-alert-container' overlayClassName='general-alert-overlay' isOpen={ isOpen } onRequestClose={ handleClose }>
                <div className='general-alert-form'>
                    <div className='general-alert-content'>
                        <div className='alert-icon'>
                            {isError() ? 
                                <AlertError /> :
                                <AlertSuccess />
                            }
                        </div>

                        <h1>{alertTitle()}</h1>

                        <h2>{alertMessage()}</h2>

                        <div className='primary-button'>
                            <button onClick={handleClose}>{primary()}</button>
                        </div>

                        {hasSecondaryButton() && 
                            <div className='secondary-button'>
                                <button onClick={secondaryAction}>{secondary()}</button>
                            </div>
                        }
                    </div>
                </div>
            </ReactModal>
        </>
    );
};

export default GeneralAlert;
