import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './DataSourceEmptyCard.scss';
import DataSourceForm from '../../dialog/DataSourceForm';

const DataSourceEmptyCard: React.FC = () => {
    const navigate = useNavigate();
    const { ds_id } = useParams();
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (ds_id) {
            navigate(`/datasource/${ds_id}`);
        }
    }, [ds_id]);

    return (
        <div className='ds-empty-Container'>
            <div className='ds-empty-main-container'>
                <div className='ds-empty-main-page-info'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='46' height='46' viewBox='0 0 46 46' fill='none'>
                        <path d='M7.6665 13.4167V32.5833C7.6665 36.8192 14.532 40.25 22.9998 40.25C31.4677 40.25 38.3332 36.8192 38.3332 32.5833V13.4167M7.6665 13.4167C7.6665 17.6525 14.532 21.0833 22.9998 21.0833C31.4677 21.0833 38.3332 17.6525 38.3332 13.4167M7.6665 13.4167C7.6665 9.18083 14.532 5.75 22.9998 5.75C31.4677 5.75 38.3332 9.18083 38.3332 13.4167M38.3332 23C38.3332 27.2358 31.4677 30.6667 22.9998 30.6667C14.532 30.6667 7.6665 27.2358 7.6665 23' stroke='#8390BA' stroke-width='3.83333' stroke-linecap='round' stroke-linejoin='round' />
                    </svg>
                    <h1>Data source will display here</h1>
                    <button onClick={ () => setShowForm(true) }>+ Data source</button>
                </div>
            </div>
            <DataSourceForm isOpen={showForm} onClose={() => setShowForm(false) } />
        </div>
    );
};

export default DataSourceEmptyCard;