import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteDataSourceSRecord, DSString, updateDateSourceRecord } from '../../../services/BSCore';
import './DataSourceStringCard.scss';
import Backdrop from '@mui/material/Backdrop';
import { Checkbox, CircularProgress, Collapse } from '@mui/material';

interface Props {
    record: DSString;
    isSelected: boolean | undefined;
    refetch: () => Promise<void>;
    select: (string: string) => void;
    unselect: (string: string) => void;
}

const DataSourceStringCard: React.FC<Props> = ({ record, isSelected, refetch, select, unselect }) => {
    const { ds_id } = useParams();
    const [showLoading, setShowLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [editedString, setEdittedString] = useState('');
    const [viewMore, setViewMore] = useState(false);

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    const copyString = async () => {
        if (record.payload.text) {
            navigator.clipboard.writeText(record.payload.text);
            setCopied(true);
            await delay(3000);
            setCopied(false);
        }
    };

    const deleteString = async () => {
        if (ds_id) {
            setShowLoading(true);

            const result = await deleteDataSourceSRecord(ds_id, record.id);
            console.log('deleted', result);
            await refetch();

            unselect(record.id);

            setShowDelete(false);

            setShowLoading(false);
        }
    };

    const editString = () => {
        if (record.payload.text) {
            setEdittedString(record.payload.text);
            setShowEdit(true);
        }
    };

    const updateString = async () => {
        if (editedString !== record.payload.text && ds_id) {
            setShowLoading(true);

            const result = await updateDateSourceRecord(ds_id, record.id, editedString);
            console.log('updated ', result);
            await refetch();

            setShowLoading(false);
        }

        setShowEdit(false);
    };

    const handleSelect = () => {
        if (!isSelected) {
            select(record.id);
        } else {
            unselect(record.id);
        }
    };

    const handleChange = (event: { target: { value: any; }; }) => {
        setEdittedString(event.target.value);
    };

    return (
        <div className={`ds-sc-container ${isSelected ? 'selected-border' : 'unselected-border'}`}>
            <div className='ds-sc-top'>
                <div className='ds-sc-top-info'>
                    <Checkbox
                        checked={isSelected}
                        onChange={handleSelect}
                        sx={{
                            color: '#8390BA',
                            '&.Mui-checked': {
                                color: '#3C49CB',
                            },
                        }}
                    />
                    <h1>String:&nbsp;</h1>
                    <h2>{record.id}</h2>
                </div>
                {!showEdit && !showDelete ?
                    <div className='ds-sc-top-buttons'>
                        {copied &&
                            <div>Copied</div>
                        }
                        <button onClick={copyString}>
                            <i className='bi bi-file-earmark' />
                            <i className='bi bi-file-earmark-fill' />
                        </button>
                        <button onClick={() => setShowDelete(true)} >
                            <i className='bi bi-trash' />
                            <i className='bi bi-trash-fill' />
                        </button>
                        <button onClick={editString}>
                            <i className='bi bi-pencil' />
                            <i className='bi bi-pencil-fill' />
                        </button>
                    </div> :
                    <>
                        {showEdit ?
                            <div className='ds-sc-top-edits'>
                                <button onClick={() => setShowEdit(false)} >Cancel</button>
                                <button onClick={updateString}>Save</button>
                            </div> :
                            <div className='ds-sc-top-deletes'>
                                <button onClick={() => setShowDelete(false)} >Cancel</button>
                                <button className='ds-sc-top-delete-btn' onClick={deleteString}>Delete</button>
                            </div>
                        }
                    </>
                }
            </div>
            <div className='ds-sc-bottom' onClick={handleSelect}>
                {showEdit ?
                    <textarea value={editedString} onChange={handleChange} /> :
                    <div className='ds-sc-text'>
                        <div className='ds-sc-text-show-less'>
                            <h3 className='ds-sc-show-sentence'>{record.payload.sentence}</h3>
                            <button className='ds-sc-show-button' onClick={e => { e.stopPropagation(); setViewMore(!viewMore); }}>{`View ${viewMore ? 'less' : 'more'}`}
                                <i className={`bi bi-chevron-${viewMore ? 'up' : 'down'} show-chevron`} />
                            </button>
                            <div className='ds-sc-show-button'></div>
                        </div>
                        <Collapse in={viewMore}><h4>{record.payload.text}</h4></Collapse>
                    </div>
                }
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </div>
    );
};

export default DataSourceStringCard;