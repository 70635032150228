import React from 'react';
import './NodeStart.scss';
import { Position, NodeProps, Handle } from 'reactflow';

const NodeStart: React.FC<NodeProps> = () => {
    return (
        <>
            <div className='node-start-container'>
                <p className='start-label'>Start</p>
                <Handle className='start-handle' type='source' position={Position.Right} />
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'start',
    name: 'Start',
    iconId: ''
};

export default NodeStart;
export { nodeInfo };