import React, { useEffect, useState } from 'react';
import './TypingIndicator.scss';
import { useChannelStateContext } from 'stream-chat-react';

const TextField: React.FC = () => {
    const { messages } = useChannelStateContext();
    const [shouldHideIndicator, setShouldHideIndicator] = useState(true);

    // Show/hide indicator
    useEffect(() => {
        if (!messages || messages.length === 0) return;

        if (messages[messages.length - 1].user?.id?.startsWith('dev')) {
            setShouldHideIndicator(false);
        } else {
            setShouldHideIndicator(true);
        }
    }, [messages]);

    return (
        <div hidden={shouldHideIndicator} className='typing-indicator-container'>
            <div className='spinner-grow text-info spinner-grow-sm' role='status' />
            <span>thinking...</span>
        </div>
    );
};

export default TextField;