import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeBaiduCall.scss';
import { Position, NodeProps, useReactFlow, useStoreApi, Node } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import { NodeDataModal } from '../NodeManifest';
import { showEditorSidePanel, getCurrentlySelectedNode } from '../../../features/botEditor/botEditorSlice';
import { useAppSelector } from '../../../app/hooks';
import IconButton from '@mui/material/IconButton';

import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';

const NodeCallDefaults = {
    robotId: '',
    phoneNumber: '',
    variables: []
};

type NodeBaiduCallVariable = {
    name: string;
    value: string;
}

type setNodesType = (payload: Node<any>[] | ((nodes: Node<any>[]) => Node<any>[])) => void;

const DialogVariable: React.FC<NodeBaiduCallVariable & { validVariables: string[], variables: NodeBaiduCallVariable[], id: string, updateNodeData: (id: string, setNodes: setNodesType, data: Object)=>void, setNodes: setNodesType, index: number, setVariables: React.Dispatch<React.SetStateAction<NodeBaiduCallVariable[]>> }> = ({ index, setVariables, name, value, updateNodeData, setNodes, id, variables, validVariables }) => {
    const removeVariable = (index: number) => {
        setVariables(data => {
            return [
                ...data.slice(0, index),
                ...data.slice(index + 1)
            ];
        });
    };

    const data1OnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        let dataValue = data;
        if (typeof data === 'object') dataValue = data?.target?.value;
        if (typeof dataValue !== 'string') return;

        const updatedData: NodeBaiduCallVariable[] = [...variables];
        updatedData[index].name = dataValue;
        updateNodeData(id, setNodes, { variables: updatedData });

        // const updatedData: NodeBaiduCallVariable[] = [];
        // if (index === 0) {
        //     updatedData.push({
        //         ...variables[0],
        //         key: dataValue
        //     });
        //     if (variables.length > 1) updatedData.push(variables[1]);
        // } else {
        //     updatedData.push(variables[0]);
        //     updatedData.push({
        //         ...variables[1],
        //         key: dataValue
        //     });
        // }

        // updateNodeData(id, setNodes, { variables: updatedData });
    };

    const data2OnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        let dataValue = data;
        if (typeof data === 'object') dataValue = data?.target?.value;
        if (typeof dataValue !== 'string') return;

        const updatedData: NodeBaiduCallVariable[] = [...variables];
        updatedData[index].value = dataValue;
        updateNodeData(id, setNodes, { variables: updatedData });
    };

    console.log('key', name);
    console.log('value', value);
    console.log('variables', variables);

    return (
        <div className='logicalcomponent-container'>
            <div className='logicalcomponent-title-container'>
                <p>{`Variable ${index + 1}`}</p>
                <IconButton disabled={false} onClick={() => removeVariable(index)}>
                    <i className='bi bi-trash3 logicalcomponent-title-delete-icon' />
                </IconButton>
            </div>
            <NodeTextArea
                        className={`logicalcomponent-data-1`}
                        oneLine
                        title='Name'
                        toolTipText='Name of the variable'
                        validVariables={validVariables}
                        onChange={data1OnChange}
                        defaultValue={name}
                    />
            <NodeTextArea
                className={`logicalcomponent-data-2`}
                oneLine
                title='Value'
                toolTipText='Value of the variable'
                validVariables={validVariables}
                onChange={data2OnChange}
                defaultValue={value}
            />
        </div>
    );
};

const NodeBaiduCall: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [robotId, setRobotId] = useState<string>('');
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const [phoneNumber, setPhoneNumber] = React.useState<string>('');
    const [variables, setVariables] = useState<NodeBaiduCallVariable[]>([]);
    const acceptModal = NodeDataModal.text;
    const [hasError, setHasError] = useState(false);
    const currentlySelectedNode = useAppSelector(getCurrentlySelectedNode);

    const nodeSelected = () => {
        return currentlySelectedNode === id;
    };

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            robotId: data?.robotId || NodeCallDefaults.robotId,
            phoneNumber: data?.phoneNumber || NodeCallDefaults.phoneNumber,
            variables: data?.variables || NodeCallDefaults.variables,
            outputModal: NodeDataModal.text
        });

        // Init UI
        setRobotId(data?.robotId || NodeCallDefaults.robotId);
        setPhoneNumber(data?.phoneNumber || NodeCallDefaults.phoneNumber);
        setVariables(data?.variables || NodeCallDefaults.variables);
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setRobotId(data?.robotId || NodeCallDefaults.robotId);
        setPhoneNumber(data?.phoneNumber || NodeCallDefaults.phoneNumber);
        setVariables(data?.variables || NodeCallDefaults.variables);

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.robotId || data?.phoneNumber?.length === 0) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.robotId || '', validVariables) || hasInvalidVariables(data?.phoneNumber || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.robotId, data.phoneNumber, validVariables]);

    const addDialogVariableOnClick = () => {
        // Update data
        updateNodeData(id, setNodes, { variables: [
            ...variables,
            {
                key: '',
                value: ''
            }
        ] });

        // Update UI
        setVariables((list => {
            return [...list, {
                name: '',
                value: ''
            }];
        }));
    };

    useEffect(() => {
        if (nodeSelected()) {
            dispatch(showEditorSidePanel({
                title: 'Baidu call',
                subtitle: `Configure dialog variables for ${id}`,
                panelComponent: (<>
                    {variables.map((variable, i) => <DialogVariable 
                        id={id}
                        updateNodeData={updateNodeData}
                        setNodes={setNodes}
                        index={i}
                        variables={variables}
                        setVariables={setVariables}
                        name={variable.name}
                        value={variable.value}
                        validVariables={validVariables}
                    />)}
                    <button className='nodelogic-addlogic-btn' onClick={addDialogVariableOnClick}>
                        <i className='bi bi-plus-lg' />
                        {' New dialog variable'}
                    </button>
                </>)
            }));
        }
    }, [variables.length, variables, currentlySelectedNode]);

    const robotIdOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { robotId: value });

        // Update UI
        setRobotId(value);
    };

    const phoneNumberOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { phoneNumber: value });

        // Update UI
        setPhoneNumber(value);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeTextArea
                        className={`nodebaiducall-number-input-${id}`}
                        title='Phone number'
                        toolTipText='Your phone number for us to call'
                        validVariables={validVariables}
                        oneLine
                        onChange={phoneNumberOnChange}
                        value={phoneNumber}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeTextArea
                        className={`nodeblandcall-robot-id-input-${id}`}
                        title='Robot Id'
                        toolTipText='Your baidu call robot id'
                        validVariables={validVariables}
                        oneLine
                        onChange={robotIdOnChange}
                        value={robotId}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodebaiducall-number-input-${id}`, text: phoneNumber, textOnChangeHandler: phoneNumberOnChange }, { inputElementClass: `nodeblandcall-robot-id-input-${id}`, text: robotId, textOnChangeHandler: robotIdOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'bd-c',
    name: 'Baidu Call',
    description: 'Connects users with AI for voice conversations (China mainland customers only)',
    iconFile: 'node-icon-bd-c.svg',
    color: '#0062FF',
    docUrl: 'https://www.google.com',
    category: NodeCategory.output
};

export default NodeBaiduCall;
export { nodeInfo };