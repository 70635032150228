import React from 'react';

interface Props {
    color?: string;
}

const GoogleIcon: React.FC<Props> = ({ color }) => {
    return (
        <svg width='25' height='24' viewBox='0 0 25 24'  enable-background='new 0 0 612 792' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0.5 12C0.5 5.3832 5.8832 0 12.5 0C15.1723 0 17.7017 0.859771 19.8147 2.4864L17.0262 6.1088C15.7197 5.10309 14.1545 4.57143 12.5 4.57143C8.40389 4.57143 5.07143 7.90389 5.07143 12C5.07143 16.0961 8.40389 19.4286 12.5 19.4286C15.7991 19.4286 18.6026 17.2671 19.5688 14.2857H12.5V9.71429H24.5V12C24.5 18.6168 19.1168 24 12.5 24C5.8832 24 0.5 18.6168 0.5 12Z' fill='#141936'/>
        </svg>

    );
};

export default GoogleIcon;