import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './NewProfile.scss';
import { useAppDispatch } from '../../app/hooks';
import { Auth } from 'aws-amplify';
import AccountCard from '../../components/cards/accountCard/AccountCard';
import PlanCard from '../../components/cards/planCard/PlanCard';
import { fetchWorkspaceAsync } from '../../features/workspace/workspaceSlice';
import TokenUsageCard from '../../components/cards/tokenUsageCard/TokenUsageCard';
import NavbarComponent from '../../components/shared/main/Navbar/NavbarComponent';
import HomepageFootnote from '../../components/homepageFootnote/HomepageFootnote';

export enum ProfileSection {
    account, credit, tokenUsage
}

export enum AccountSection {
    profile, security
}

export enum SubscriptionSection {
    subscription, bills
}

export enum PlanSection {
    subscription, bill
}

const NewProfile: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { profile_id } = useParams();
    const [selectedProfileSection, setSelectedProfileSection] = useState<ProfileSection>(ProfileSection.account);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, []);

    useEffect(() => {
        if (profile_id === 'account') {
            setSelectedProfileSection(ProfileSection.account);
        } else if (profile_id === 'credits') {
            setSelectedProfileSection(ProfileSection.credit);
        } else if (profile_id === 'tokenusage') {
            setSelectedProfileSection(ProfileSection.tokenUsage);
        } else {
            setSelectedProfileSection(ProfileSection.account);
        }
    }, [profile_id]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(result => {
                if (result) {
                    console.log('authenticated');
                    dispatch(fetchWorkspaceAsync());
                } else {
                    console.log('not authenticated');
                }
            })
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, []);

    return (
        <div className='main'>
            <NavbarComponent/>
            <div className='d-flex'>
                <div className='side-bar-content-container border-end'>
                    <ul className = 'main-menu'>
                        <li className={`d-flex align-items-center menu-item ${selectedProfileSection === ProfileSection.account ? 'active' : ''}`}
                        onClick={() => navigate('/profile/account')}>
                            <i className='bi bi-person-circle ps-3' />&nbsp;Profile
                        </li>
                        <li className={`d-flex align-items-center menu-item ${selectedProfileSection === ProfileSection.credit ? 'active' : ''}`}
                        onClick={() => navigate('/profile/credits')}>
                            <i className='bi bi-currency-dollar ps-3' />&nbsp;Credits
                        </li>
                        <li className={`d-flex align-items-center menu-item ${selectedProfileSection === ProfileSection.tokenUsage ? 'active' : ''}`}
                        onClick={() => navigate('/profile/tokenusage')}>
                            <i className='bi bi-ticket-detailed ps-3' />&nbsp;Token Usage
                        </li>
                    </ul>
                </div>

                <div>
                    <div className='main-content'>
                        {selectedProfileSection === ProfileSection.account &&
                        <AccountCard />
                        }
                        {selectedProfileSection === ProfileSection.credit &&
                        <PlanCard />
                        }
                        {selectedProfileSection === ProfileSection.tokenUsage &&
                        <TokenUsageCard />
                        }
                    </div>
                    <HomepageFootnote/>
                </div>
            </div>
        </div>
    );
};

export default NewProfile;