import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import './WarningPrompt.scss';
import IconButton from '@mui/material/IconButton';

interface Props {
    show: boolean
    title: string
    actionCloseModal?: () => void
    description: string
    children?: ReactNode
    actionLeftTitle: string
    actionLeftOnClick?: () => void
    hideActionLeft?: boolean
    disableActionLeft?: boolean
    actionMiddleTitle?: string
    actionMiddleOnClick?: () => void
    hideActionMiddle?: boolean
    disableActionMiddle?: boolean
    actionRightTitle?: string
    actionRightOnClick?: () => void
    hideActionRight?: boolean
    disableActionRight?: boolean
}

const WarningPrompt: React.FC<Props> = ({ show, title, actionCloseModal, description, children, actionLeftTitle, actionLeftOnClick, actionRightTitle, actionRightOnClick, disableActionLeft, hideActionLeft, disableActionRight, hideActionRight, actionMiddleTitle, actionMiddleOnClick, hideActionMiddle,disableActionMiddle
 }) => {

    return (
        <Dialog open={show}>
            <div className='warning-modal-container'>
                <div className='warning-modal-title-container'>
                    <p className='warning-modal-title'>{title}</p>
                    <IconButton className='warning-modal-close-button' onClick={actionCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <p className='warning-modal-description'>
                    {description}
                </p>
                {children}
                <Divider className='warning-modal-divider'/>
                <DialogActions className='warning-modal-actions-container'>
                    <Button className='warning-modal-cancel-btn' sx={{ textTransform: 'none' }} variant='text' disabled={disableActionLeft} hidden={hideActionLeft} onClick={actionLeftOnClick} color='success'>{actionLeftTitle}</Button>
                    <Button className='warning-modal-no-save-btn' sx={{ textTransform: 'none' }} variant='outlined' disabled={disableActionMiddle} hidden={hideActionMiddle} onClick={actionMiddleOnClick} color='success'>{actionMiddleTitle}</Button>
                    <Button className='warning-modal-save-btn' sx={{ textTransform: 'none' }} variant='contained' disabled={disableActionRight} hidden={hideActionRight} onClick={actionRightOnClick} color='success'>
                        {actionRightTitle}
                    </Button>
                </DialogActions>
            </div>  
        </Dialog>
    );
};

export default WarningPrompt;
