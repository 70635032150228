import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import './ProfileCard.scss';
import { Auth } from 'aws-amplify';
import { fetchMemberData, MemberObject, updateMemberData, uploadIconUrl } from '../../../services/BSCore';
import TextField from '../../textfield/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ImageCropper from '../../imageCrop/ImageCrop';
import { Area } from 'react-easy-crop';
import Collapse from '@mui/material/Collapse';

interface Props {
    showConfirmation: Dispatch<SetStateAction<boolean>>;
}

const ProfileCard: React.FC<Props> = ({ showConfirmation }) => {
    const [name, setName] = useState('');
    const [newName, setNewName] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [email, setEmail] = useState('');
    const [image, setImage] = useState<File>();
    const [imageURL, setImageURL] = useState('');
    const [showCrop, setShowCrop] = useState(false);
    const imageRef = useRef<File>();
    imageRef.current = image;

    const [showImageUploadSuccessAlert, setShowImageUploadSuccessAlert] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(result => {
                if (result) {
                    fetchUser();
                }

                console.log(result.attributes);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const fetchUser = async () => {
        const user = await fetchMemberData() as MemberObject;
        console.log(user);
        setName(user.name);
        setNewName(user.name);
        setEmail(user.email);
        setProfileImage(user.info.imageUrl);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const reader = new FileReader();
        const file = event.target.files?.[0];

        if (file) {
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                if (reader.result) {
                    setImageURL(reader.result.toString());
                    setShowCrop(true);
                }
            };
        }
    };

    const onCropDone = async (croppedArea?: Area) => {
        if (croppedArea) {
            const canvasEle = document.createElement('canvas');
            canvasEle.width = croppedArea.width;
            canvasEle.height = croppedArea.height;

            console.log('area', croppedArea);
            const context = canvasEle.getContext('2d');

            let imageObj1 = new Image();
            imageObj1.src = imageURL;
            imageObj1.onload = async () => {
                if (context) {
                    context.drawImage(
                        imageObj1,
                        croppedArea.x,
                        croppedArea.y,
                        croppedArea.width,
                        croppedArea.height,
                        0,
                        0,
                        croppedArea.width,
                        croppedArea.height
                    );

                    const dataURL = canvasEle.toDataURL('image/jpeg');
                    const response = await fetch(dataURL);
                    const blob = await response.blob();
                    const file = new File([blob], 'image');

                    setImage(file);
                    setShowCrop(false);
                    setShowLoading(true);

                    await uploadImage();
                }
            };
        }
    };


    const uploadImage = async () => {
        let counter = 0;

        while (!imageRef.current) {
            if (counter > 10) {
                setShowCrop(false);
                setImage(undefined);
                setShowLoading(false);
                await delay(200);
                alert('Something went wrong, please try again later');
                return;
            }

            await delay(2000);
            counter += 1;
            console.log('counter', counter);
        }

        if (imageRef.current) {
            try {
                setShowLoading(true);
                const imageUrl = await uploadIconUrl(imageRef.current);
                await updateMemberData({ info: { imageUrl: imageUrl.url } });
                setShowImageUploadSuccessAlert(true);
                setImage(undefined);
                setShowLoading(false);
                setProfileImage(imageUrl.url);
                setTimeout(() => {
                    setShowImageUploadSuccessAlert(false);
                }, 5000);
            } catch (error) {
                setShowLoading(false);
                console.error('Error uploading image:', error);
            }
        }
    };

    const onCropCancel = () => {
        setShowCrop(false);
        setImage(undefined);
        setImageURL('');
    };

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    const updateUser = async () => {
        showConfirmation(true);

        const { attributes } = await Auth.currentAuthenticatedUser();
        console.log('user ', attributes);

        if (newName !== name) {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const result = await Auth.updateUserAttributes(user, {
                    name: newName
                });
                console.log(result); // SUCCESS

                await updateMemberData({ name: newName });
                await fetchUser();
            } catch (err) {
                console.log(err);
            }

            await delay(3000);

            const { attributes } = await Auth.currentAuthenticatedUser();
            console.log(attributes);
        }
        showConfirmation(false);
    };

    return (
        <div className='profile-right-content-container'>
            <div className='profile-right-content'>
                <Collapse in={showImageUploadSuccessAlert}>
                    <div className='alert alert-success d-flex align-items-center' role='alert'>
                        <i className='bi bi-check-circle pe-2'></i>
                        <div>
                            Upload successful.
                        </div>
                    </div>
                </Collapse>
                <div className='profile-right-content-top p-4'>
                    <img style={{ objectFit: 'cover' }} src={profileImage} alt='' />
                    <button onClick={() => fileInputRef.current?.click()} >Upload...</button>
                    <input
                        type='file'
                        accept='image/*'
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <h1>Format JPG, PNG or PDF</h1>
                </div>
                <div className='profile-right-content-bottom'>
                    <h2>Name</h2>
                    <TextField icon={''} type={'standard'} input={newName} placeholder={''} hide={false} onChange={setNewName} />
                    <h3>Email</h3>
                    <h4>{email}</h4>
                    <button onClick={updateUser} >Update</button>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>

            {showCrop &&
                <ImageCropper image={imageURL} onCropDone={onCropDone} onCropCancel={onCropCancel} />
            }
        </div>
    );
};

export default ProfileCard;