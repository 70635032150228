import axios from 'axios';
import { DataSourceType } from '../components/dialog/DataSourceForm';

import config from '../config';
import AuthService from './AuthService';
const BS_CORE_URL = `${config.BS_CORE_URL}/api/v1`;

const setupConfig = async () => {
  try {
    const token = await AuthService.getCurrentUserTokenAndId();
    console.log('id token:', token.idToken);
    const config = {
      headers: { Authorization: `Bearer ${token.idToken}` }
    };
    return config;
  } catch (error) {
    console.error(error);
  }
};

export const createMember = async () => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/member`,
      {},
      config
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchMemberData = async () => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/member`,
      config
    );
    return data as MemberObject;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateMemberData = async (memberData: any) => {
  try {
    const config = await setupConfig();

    await axios.put(
      `${BS_CORE_URL}/member`,
      memberData,
      config
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createWorkspace = async (name: string, description: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/workspace`,
      { name, description },
      config
    );
   
    const object = data as MemberObject;
    console.log(object.teamList);
    return object;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const editWorkspace = async (workspaceId: number, data: object) => {
  const config = await setupConfig();

  try {
    await axios.put(`${BS_CORE_URL}/workspace/${workspaceId}`,
      data,
      config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const fetchWorkspace = async (workspaceId: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/workspace/${workspaceId}`,
      config
    );
    console.log('fetched workspace', data);
    return data as Workspace;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchTokenUsageList = async (workspaceId: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/tokenizer/expenditures/${workspaceId}`,
      config
    );
    console.log('fetched tokenUsageList', data);
    return data as TokenUsageTableData[];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createBot = async (bot: { workspaceId: number; name: string; icon: string; config: { description: string; }; }) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/bot`,
      bot,
      config
    );

   
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteBot = async (botId: number) => {
  const config = await setupConfig();

  try {
    await axios.delete(`${BS_CORE_URL}/bot/${botId}`,
      config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editBot = async (botId: number, data: object) => {
  const config = await setupConfig();

  try {
    await axios.put(`${BS_CORE_URL}/bot/${botId}`,
      data,
      config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadIconUrl = async (icon: File) => {
  try {
    const config = await setupConfig();

    const formData = new FormData();
    console.log('url', icon);
    formData.append('file', icon);

    const { data } = await axios.post(`${BS_CORE_URL}/upload/icon`,
      formData,
      config);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};




export const fetchBot = async (botId: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/bot/${botId}`,
      config
    );

    return data as Bot;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchBots = async (ws_id: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/bot/workspace/${ws_id}`,
      config
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createDataSource = async (dataSource: { workspaceId: number, name: string, type: DataSourceType }) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/datasource`,
      dataSource,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchDataSources = async (ws_id: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/datasource/workspace/${ws_id}`,
      config
    );
    return data as DataSource[];
  } catch (error) {
    throw error;
  }
};

export const fetchDataSource = async (id: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/datasource/${id}`,
      config
    );
    return data as DataSource;
  } catch (error) {
    throw error;
  }
};

export const updateDataSourceName = async (ds_id: string, name: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.put(
      `${BS_CORE_URL}/datasource/${ds_id}`,
      { name },
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteDataSource = async (ds_id: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.delete(
      `${BS_CORE_URL}/datasource/${ds_id}`,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const dataSourceAddString = async (id: string, file: File) => {
  try {
    const config = await setupConfig();

    const formData = new FormData();
    formData.append('file', file);

    const { data } = await axios.post(
      `${BS_CORE_URL}/datasource/task/file/${id}`,
      formData,
      config
    );
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const dataSourceAddFileWithAI = async (id: string, file: File) => {
  try {
    const config = await setupConfig();

    const formData = new FormData();
    formData.append('file', file);

    const { data } = await axios.post(
      `${BS_CORE_URL}/datasource/task/file/ai/${id}`,
      formData,
      config
    );
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const dataSourceAddStringURL = async (id: string, url: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/datasource/task/web/${id}`,
      { url },
      config
    );
    console.log('upload string url to datasource', data);
    return data;
  } catch (error) {
    throw error;
  }
};

export const dataSourceAddStringText = async (id: string, content: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/datasource/task/text/${id}`,
      { content },
      config
    );
    console.log('upload string url to datasource', data);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const searchVideo = async (url: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/youtube/search`,
      { url },
      config
    );
    console.log('youtube video', data);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const dataSourceAddStringVideo = async (ds_id: string, videoId: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/datasource/task/youtube/${ds_id}`,
      { videoId },
      config
    );
    console.log('upload string video to datasource', data);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const updateDateSourceRecord = async (ds_id: string, record_id: string, newString: string) => {
  try {
    const config = await setupConfig();

    await axios.put(
      `${BS_CORE_URL}/datasource/${ds_id}/detail/${record_id}`,
      {
        'payload': {
          'text': newString
        }
      },
      config
    );
  } catch (error) {
    
    throw error;
  }
};

export const deleteDataSourceSRecord = async (ds_id: string, record_id: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.delete(
      `${BS_CORE_URL}/datasource/${ds_id}/detail/${record_id}`,
      config
    );
   
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const deleteStrings = async (ds_id: string, strings: string[]) => {
  try {
    const config = await setupConfig();

    const result = await axios.delete(
      `${BS_CORE_URL}/datasource/${ds_id}/detail`,
      {
        headers: config?.headers,
        data: { 
          'idList': strings 
        }
      }
    );
    console.log(result);
    return result;
  } catch (error) {
    
    throw error;
  }
};

export const fetchDataSourceRecords = async (id: string, query: string) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.get(
      `${BS_CORE_URL}/datasource/${id}/detail?page=1&searchQuery=${query}`,
      config
    );
    console.log(`fetching datasoure ${id} ${data}`);
    console.log('offset', data.nextCursor);
    const strings = data.points as DSString[];
    const next = data.nextCursor;
    return { strings, next };
  } catch (error) {
    
    throw error;
  }
};

export const fetchMoreDataSourceRecords = async (id: string, cursor: string, query: string) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.get(
      `${BS_CORE_URL}/datasource/${id}/detail?cursor=${cursor}&searchQuery=${query}`,
      config
    );
    console.log(`fetching datasoure ${id} ${data}`);
    console.log('offset', data.nextCursor);
    const strings = data.points as DSString[];
    const next = data.nextCursor as string;
    return { strings, next };
  } catch (error) {
    
    throw error;
  }
};

export const fetchDataSourceRecordCount = async (ds_id: string) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.get(
      `${BS_CORE_URL}/datasource/${ds_id}/detail/count`,
      config
    );
    console.log('datasouce record count ', data);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const fetchDataSourceUploadProgress = async (taskId: string) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.get(
      `${BS_CORE_URL}/datasource/task/${taskId}`,
      config
    );
    console.log('datasource task status', data);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const createModuleInstance = async (workflowKey: string, type: string, data: object) => {
  try {
    const config = await setupConfig();
    const { data: responseData } = await axios.post(
      `${BS_CORE_URL}/module`,
      {
        workflowKey,
        type,
        data
      },
      config
    );
    return responseData;
  } catch (error) {
    
    throw error;
  }
};

export const updateModuleInstance = async (moduleId: string, data: object) => {
  try {
    const config = await setupConfig();
    const { data: responseData } = await axios.put(
      `${BS_CORE_URL}/module/${moduleId}`,
      {
        data
      },
      config
    );
    return responseData;
  } catch (error) {
    
    throw error;
  }
};

export const updateWorkflow = async (workflowId: number, data: object) => {
  try {
    const config = await setupConfig();
    const { data: responseData } = await axios.put(
      `${BS_CORE_URL}/workflow/${workflowId}`,
      {
        data
      },
      config
    );
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const toggleWorkflow = async (workflowId: number, enabled: boolean) => {
  try {
    const config = await setupConfig();
    const { data: responseData } = await axios.put(
      `${BS_CORE_URL}/workflow/${workflowId}`,
      {
        enabled
      },
      config
    );
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchWorkflow = async (workflowId: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/workflow/${workflowId}`,
      config
    );

    return data as Workflow;
  } catch (error) {
    
    throw error;
  }
};

export const fetchBotChannels = async (botId: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/channel/bot/${botId}`,
      config
    );

    return data as Channel[];
  } catch (error) {
    
    throw error;
  }
};

export const fetchChannel = async (channelId: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/channel/${channelId}`,
      config
    );

    return data as Channel;
  } catch (error) {
    
    throw error;
  }
};

export const createChannel = async (botId: number, workspaceId: number, type: string, data: Object | undefined) => {
  try {
    const config = await setupConfig();
    const { data: responseData } = await axios.post(
      `${BS_CORE_URL}/channel`,
      {
        botId,
        workspaceId,
        type,
        data
      },
      config
    );
    return responseData;
  } catch (error) {
    
    throw error;
  }
};

export const deleteChannel = async (channelId: number) => {
  try {
    const config = await setupConfig();

    await axios.delete(
      `${BS_CORE_URL}/channel/${channelId}`,
      config
    );
  } catch (error) {
    
    throw error;
  }
};

export const uploadPrivateFile = async (file: File) => {
  try {
    const config = await setupConfig();

    const formData = new FormData();
    formData.append('file', file);

    const { data } = await axios.post(
      `${BS_CORE_URL}/upload/file`,
      formData,
      config
    );
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const publishBot = async (botId: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/bot/publish/${botId}`,
      {},
      config
    );
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const rollbackBot = async (botId: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/bot/rollback/${botId}`,
      {},
      config
    );
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const createStreamChannelDev = async (botId: number) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.post(
      `${BS_CORE_URL}/stream/dev/channel`,
      { botId },
      config
    );
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const getStreamDevToken = async () => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/stream/dev/token`,
      config
    );

    return data;
  } catch (error) {
    
    throw error;
  }
};

export const enableChatHistory = async (botId: number) => {
  const config = await setupConfig();

  try {
    await axios.put(`${BS_CORE_URL}/bot/enable/history/${botId}`,
      {},
      config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const disableChatHistory = async (botId: number) => {
  const config = await setupConfig();

  try {
    await axios.put(`${BS_CORE_URL}/bot/disable/history/${botId}`,
      {},
      config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const purchaseCredit = async (workspaceId: number, amount: number) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.post(
      `${BS_CORE_URL}/payment/purchase/${workspaceId}/${amount}`,
      {},
      config
    );
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const getPaymentOrders = async (workspaceId: number) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.get(
      `${BS_CORE_URL}/payment/orders/${workspaceId}`,
      config
    );

    return data as PaymentOrder[];
  } catch (error) {
    
    throw error;
  }
};

export const addWorkspaceMember = async (workspaceId: number, email: string, teamMemberRole: string) => {
  try {
    const config = await setupConfig();

    const { data } = await axios.post(
      `${BS_CORE_URL}/workspace/member/${workspaceId}`,
      { email, teamMemberRole },
      config
    );

   
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteWorkspaceMember = async (workspaceId: number, teamMemberId: number) => {
  const config = await setupConfig();
  try {
    await axios.delete(`${BS_CORE_URL}/workspace/${workspaceId}/member/${teamMemberId}`,
      config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchTemplates = async () => {
  const config = await setupConfig();

  try {
    const { data } = await axios.get(
      `${BS_CORE_URL}/template/all`,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const createBotUsingTemplate = async (templateId: number, botInfo:{ workspaceId: number; name: string; config: { description: string; }; }) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.post(
      `${BS_CORE_URL}/bot/template/${templateId}`,
      botInfo,
      config
    );
    return data;
  } catch (error) {
    
    throw error;
  }
};

export type MemberObject = {
  id: number
  email: string
  name: string
  identityKey: string
  info: {
    imageUrl: string
  }
  teamList: Team[]
};

export type Team = {
  id: number
  memberId: number
  workspaceId: number
  selectedAt: string | null
  created_at: string
  Workspace: Workspace
};

export type Workspace = {
  id: number
  name: string
  description: string
  info: {
    imageUrl: string
  }
  currentTokens: number
  memberList: any[]
};

export interface TokenUsageTableData {
  id: number,
  workspaceId: number,
  itemName: string,
  tokensSpent: number,
  created_at: string,
  updated_at: string,
}

export type Bot = {
  id: number
  workspaceId: number
  name: string
  icon: string
  config: {
    owner: string
    description: string
    enableHistory: boolean
  }
  created_at: string
  updated_at: string
  prodWorkflows: Workflow[]
  devWorkflows: Workflow[]
};

export type Workflow = {
  id: number
  type: string
  botId: number
  workspaceId: number
  workflowKey: string
  created_at: string
  updated_at: string
  data?: object
  enabled: boolean
};

export type DataSource = {
  id: number
  workspaceId: number
  name: string
  type: DataSourceType
  updated_at: string
  created_at: string
  datasourceKey: string
  data: {
    config: {
      endpoint: string
      apiKey: string
      collection: string
    }
  }
  currentTask: {
    id: string
    datasourceId: string
    state: string
  }
};

export type DSString = {
  id: string
  payload: {
    sentence?: string
    text?: string
    description?: string
    summary?: string
    title?: string
    url?: string
    videoId?: string
  }
}

export type Channel = {
  botId: string,
  channelKey: string,
  created_at: string,
  deleted_at: string,
  id: number,
  type: string,
  updated_at: string,
  workspaceId: number,
  data?: any
};

export enum ChannelType {
  wecom = 'wecom',
  streamio = 'streamio',
  streamioDev = 'streamio_dev',
  webhook = 'webhook',
  dingChatbot = 'ding_chatbot'
};

export type PaymentOrder = {
  id: number,
  workspaceId: number,
  memberId: number,
  status: string,
  price: number,
  amount: number,
  created_at: string
};

export type NetworkError = {
  code: string,
  response: NetworkResponse
};

export type NetworkResponse = {
  data: NetworkMessage,
  status: number
};

export type NetworkMessage = {
  name: string,
  message: string
};

export type Template = {
  id: number,
  name: string,
  description: string,
  templateKey: string,
  icon: string,
  theme:string,
  created_at: string,
  updated_at: string,
  data: object
};