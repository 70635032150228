import { useStoreApi, Node } from 'reactflow';
import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeLogic.scss';
import { Position, NodeProps, useReactFlow } from 'reactflow';
import { updateNodeData, WorkflowError, setErrorFromNode, filterValidVariables, WorkflowErrorType, hasInvalidVariables } from '../../utils';
import { NodeDataModal } from '../NodeManifest';
import { showEditorSidePanel, getCurrentlySelectedNode } from '../../../features/botEditor/botEditorSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import IconButton from '@mui/material/IconButton';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';
import NodeSelect from '../../components/nodeSelect/NodeSelect';
import TrueHandle from '../../components/trueHandle/TrueHandle';
import FalseHandle from '../../components/falseHandle/FalseHandle';

type setNodesType = (payload: Node<any>[] | ((nodes: Node<any>[]) => Node<any>[])) => void;

export enum NodeLogicRelationships {
    and = 'And',
    or = 'Or'
}

const NodeLogicDefaults = {
    logicRelationship: NodeLogicRelationships.and,
    logicalComponentData: [],
    condition: ''
};

enum LogicalComponentRelationship {
    equals = 'Equals',
    doesNotEqual = 'Does not equal',
    contains = 'Contains',
    doesNotContain = 'Does not contain'
}

type LogicalComponentData = {
    data1: string,
    data2: string,
    relationship: LogicalComponentRelationship
}

const LogicalComponent: React.FC<LogicalComponentData & { validVariables: string[], logicalComponentData: LogicalComponentData[], id: string, updateNodeData: (id: string, setNodes: setNodesType, data: Object)=>void, setNodes: setNodesType, index: number, setLogicalComponentData: React.Dispatch<React.SetStateAction<LogicalComponentData[]>> }> = ({ index, setLogicalComponentData, data1, data2, relationship, updateNodeData, setNodes, id, logicalComponentData, validVariables }) => {
    const removeLogicalComponent = (index: number) => {
        setLogicalComponentData(data => {
            return [
                ...data.slice(0, index),
                ...data.slice(index + 1)
            ];
        });
    };

    const relationshipOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const updatedData: LogicalComponentData[] = [];

        if (index === 0) {
            updatedData.push({
                ...logicalComponentData[0],
                relationship: event.target.value as LogicalComponentRelationship
            });
            if (logicalComponentData.length > 1) updatedData.push(logicalComponentData[1]);
        } else {
            updatedData.push(logicalComponentData[0]);
            updatedData.push({
                ...logicalComponentData[1],
                relationship: event.target.value as LogicalComponentRelationship
            });
        }

        updateNodeData(id, setNodes, { logicalComponentData: updatedData });
    };

    const data1OnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        const updatedData: LogicalComponentData[] = [];
        if (index === 0) {
            updatedData.push({
                ...logicalComponentData[0],
                data1: value
            });
            if (logicalComponentData.length > 1) updatedData.push(logicalComponentData[1]);
        } else {
            updatedData.push(logicalComponentData[0]);
            updatedData.push({
                ...logicalComponentData[1],
                data1: value
            });
        }

        updateNodeData(id, setNodes, { logicalComponentData: updatedData });
    };

    const data2OnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        const updatedData: LogicalComponentData[] = [];
        if (index === 0) {
            updatedData.push({
                ...logicalComponentData[0],
                data2: value
            });
            if (logicalComponentData.length > 1) updatedData.push(logicalComponentData[1]);
        } else {
            updatedData.push(logicalComponentData[0]);
            updatedData.push({
                ...logicalComponentData[1],
                data2: value
            });
        }

        updateNodeData(id, setNodes, { logicalComponentData: updatedData });
    };

    return (
        <div className='logicalcomponent-container'>
            <div className='logicalcomponent-title-container'>
                <p>{`Logic ${index + 1}`}</p>
                <IconButton disabled={false} onClick={() => removeLogicalComponent(index)}>
                    <i className='bi bi-trash3 logicalcomponent-title-delete-icon' />
                </IconButton>
            </div>
            <NodeTextArea
                        className={`logicalcomponent-data-1`}
                        title='Data 1'
                        toolTipText='Data to process - 1'
                        validVariables={validVariables}
                        onChange={data1OnChange}
                        defaultValue={data1}
                    />
            <NodeSelect
                onChange={relationshipOnChange}
                value={relationship}
                title='Relationship'
                toolTipText='Relationship between data 1 and 2'
                bottomMargin
            >
                {Object.values(LogicalComponentRelationship).map(t => <option value={t}>{t}</option>)}
            </NodeSelect>
            <NodeTextArea
                className={`logicalcomponent-data-2`}
                title='Data 2'
                toolTipText='Data to process - 2'
                validVariables={validVariables}
                onChange={data2OnChange}
                defaultValue={data2}
            />
        </div>
    );
};

const NodeLogic: React.FC<NodeProps> = ({ id, data }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [logicRelationship, setLogicRelationship] = useState(NodeLogicDefaults.logicRelationship);
    const acceptModal = NodeDataModal.text;
    const [hasError, setHasError] = useState(false);
    const [logicalComponentData, setLogicalComponentData] = useState<LogicalComponentData[]>([]);
    const [addLogicalComponentDisabled, setAddLogicalComponentDisabled] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const currentlySelectedNode = useAppSelector(getCurrentlySelectedNode);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            logicRelationship: data.logicRelationship || NodeLogicDefaults.logicRelationship,
            logicalComponentData: data.logicalComponentData || NodeLogicDefaults.logicalComponentData,
            condition: data.condition || NodeLogicDefaults.condition
        });

        // Init UI
        setLogicRelationship(data.logicRelationship || NodeLogicDefaults.logicRelationship);
        setLogicalComponentData(data.logicalComponentData || NodeLogicDefaults.logicalComponentData);
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setLogicRelationship(data.logicRelationship || NodeLogicDefaults.logicRelationship);
        setLogicalComponentData(data.logicalComponentData || NodeLogicDefaults.logicalComponentData);
        
        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        data.logicalComponentData?.forEach((data: LogicalComponentData) => {
            if (!data.data1) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
            if (!data.data2) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
            if (hasInvalidVariables(data?.data1 || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
            if (hasInvalidVariables(data?.data2 || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.logicRelationship, data.logicalComponentData, validVariables]);

    const logicRelationshipOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { logicRelationship: event.target.value });

        // Update UI
        setLogicRelationship(event.target.value as NodeLogicRelationships);
    };

    const addLogicalComponentOnClick = () => {
        // Update data
        updateNodeData(id, setNodes, { logicalComponentData: [...logicalComponentData, {
            data1: '',
            data2: '',
            relationship: LogicalComponentRelationship.equals
        }] });

        // Update UI
        setLogicalComponentData((list => {
            return [...list, {
                data1: '',
                data2: '',
                relationship: LogicalComponentRelationship.equals
            }];
        }));
    };

    const nodeSelected = () => {
        return currentlySelectedNode === id;
    };

    // Disable/enable add logical component btn accordingly
    useEffect(() => {
        if (logicalComponentData.length >= 2) {
            setAddLogicalComponentDisabled(true);
        } else {
            setAddLogicalComponentDisabled(false);
        }
    }, [logicalComponentData]);

    const updateCondition = () => {
        let result = '';
        const logics: string[] = [];
        logicalComponentData.forEach(data => {
            switch (data.relationship) {
                case LogicalComponentRelationship.equals:
                    logics.push(`'${data.data1}' === '${data.data2}'`);
                    break;
                case LogicalComponentRelationship.doesNotEqual:
                    logics.push(`'${data.data1}' !== '${data.data2}'`);
                    break;
                case LogicalComponentRelationship.contains:
                    logics.push(`'${data.data1}'.includes('${data.data2}')`);
                    break;
                case LogicalComponentRelationship.doesNotContain:
                    logics.push(`!'${data.data1}'.includes('${data.data2}')`);
                    break;
            }
        });

        if (logics.length > 1) {
            result = `${logics[0]} ${logicRelationship === NodeLogicRelationships.and ? '&&' : '||'} ${logics[1]}`;
        } else {
            result = logics[0];
        }

        updateNodeData(id, setNodes, { condition: result });
    };

    // Update condition
    useEffect(() => {
        updateCondition();
    }, [logicalComponentData, logicRelationship]);

    useEffect(() => {
        if (nodeSelected()) {
            dispatch(showEditorSidePanel({
                title: 'Logic',
                subtitle: `Configure conditions for ${id}`,
                panelComponent: (<>
                    {logicalComponentData.map((data, i) => <LogicalComponent 
                        id={id}
                        updateNodeData={updateNodeData}
                        setNodes={setNodes}
                        index={i}
                        logicalComponentData={logicalComponentData}
                        setLogicalComponentData={setLogicalComponentData}
                        data1={data.data1}
                        data2={data.data2}
                        relationship={data.relationship} 
                        validVariables={validVariables}
                    />)}
                    <button className='nodelogic-addlogic-btn' onClick={addLogicalComponentOnClick} disabled={addLogicalComponentDisabled}>
                        <i className='bi bi-plus-lg' />
                        {' New logical component'}
                    </button>
                </>)
            }));
        }
    }, [addLogicalComponentDisabled, logicalComponentData, currentlySelectedNode]);

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <TrueHandle />
            <FalseHandle />
            <div className={`node-container node-selectable ${nodeSelected() ? 'node-selected' : ''} node-oaillm-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-description'>Please specify the relationship between each logical component</div>
                <hr className='node-content-break-line' />
                <div className='node-content-container'>
                    {logicalComponentData.length <= 0 ? (
                        <></>
                    ) : logicalComponentData.length === 1 ? (
                        <div className='one-logic-container'>Logic 1</div>
                    ) : (
                        <div className='two-logics-container'>
                            <div>Logic 1</div>
                            <NodeSelect
                                onChange={logicRelationshipOnChange}
                                value={logicRelationship}
                                toolTipText='Logic relationship to use'
                                size={0}
                            >
                                {Object.values(NodeLogicRelationships).map(t => <option value={t}>{t}</option>)}
                            </NodeSelect>
                            <div>Logic 2</div>
                        </div>
                    )}
                </div>
                <hr className='node-content-break-line mb-5' />
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'bs-logic',
    name: 'Logic',
    description: `Redirect workflow based on statement results`,
    iconFile: 'node-icon-bs-logic.svg',
    color: '#E53737',
    docUrl: 'https://www.google.com',
    category: NodeCategory.logic
};

export default NodeLogic;
export { nodeInfo };
