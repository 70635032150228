import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';

const StyledLoadingButton = styled(LoadingButton)(() => ({
    '&.MuiLoadingButton-root': {
        backgroundColor: '#3849D3',
        color: 'white',
        borderRadius: '8px',
        padding: '12px 24px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    '&.MuiLoadingButton-root.Mui-disabled': {
        backgroundColor: '#8B8B8B',
        color: 'white',
    },
    '&.MuiLoadingButton-root.MuiLoadingButton-loading': {
        color: '#8B8B8B'
    }
}));

export default StyledLoadingButton;