import React from 'react';

interface Props {
    color?: string;
}

const ChatHistoryIcon: React.FC<Props> = ({ color }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path fill-rule='evenodd' clip-rule='evenodd' d='M21.6 12.0001C21.6 16.6393 17.3016 20.4001 12 20.4001C10.2961 20.4072 8.61546 20.0039 7.10039 19.2241L2.39999 20.4001L4.00559 16.6525C2.99159 15.3205 2.39999 13.7209 2.39999 12.0001C2.39999 7.3609 6.69839 3.6001 12 3.6001C17.3016 3.6001 21.6 7.3609 21.6 12.0001ZM8.39999 10.8001H5.99999V13.2001H8.39999V10.8001ZM18 10.8001H15.6V13.2001H18V10.8001ZM10.8 10.8001H13.2V13.2001H10.8V10.8001Z' fill={color} />
        </svg>
    );
};

export default ChatHistoryIcon;