import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeOpenAIDALLE.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import { NodeDataModal } from '../NodeManifest';

import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';
import NodeSelect from '../../components/nodeSelect/NodeSelect';

const NodeOpenAIDALLEModels = [1, 2];

const NodeOpenAIDALLENValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

enum NodeOpenAIDALLESizeValues {
    // size256 = '256x256',
    // size512 = '512x512',
    size1024 = '1024x1024'
}

enum NodeOpenAIDALLEModelValues {
    dalle2 = 'dall-e-2',
    dalle3 = 'dall-e-3'
}

const NodeOpenAIDALLEDefaults = {
    n: NodeOpenAIDALLENValues[0],
    size: NodeOpenAIDALLESizeValues.size1024
};

const NodeOpenAIDALLEModelDefaults = {
    n: NodeOpenAIDALLEModels[0],
    model: NodeOpenAIDALLEModelValues.dalle3
};

const NodeOpenAIDALLE: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [model, setModel] = useState(NodeOpenAIDALLEModelDefaults.model);
    const [size, setSize] = useState(NodeOpenAIDALLEDefaults.size);
    const [prompt, setPrompt] = useState('');
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const acceptModal = NodeDataModal.text;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            model: data?.model || NodeOpenAIDALLEModelDefaults.model,
            n: data?.n || NodeOpenAIDALLEDefaults.n,
            size: data?.size || NodeOpenAIDALLEDefaults.size,
            prompt: data?.prompt || '',
            outputModal: NodeDataModal.image
        });

        // Init UI
        setModel(data?.model || NodeOpenAIDALLEModelDefaults.model);
        setSize(data?.size || NodeOpenAIDALLEDefaults.size);
        setPrompt(data?.prompt || '');
    }, []);


    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setModel(data?.model || NodeOpenAIDALLEModelDefaults.model);
        setSize(data?.size || NodeOpenAIDALLEDefaults.size);
        setPrompt(data?.prompt || '');

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.prompt) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.prompt || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.prompt, validVariables]);

    const sizeOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { size: event.target.value, moduleDataDidChange: true });

        // Update UI
        setSize(event.target.value as NodeOpenAIDALLESizeValues);
    };

    const modelOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { model: event.target.value, moduleDataDidChange: true});

        // Update UI
        setModel(event.target.value as NodeOpenAIDALLEModelValues);
    };

    const promptOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { prompt: value, moduleDataDidChange: true });

        // Update UI
        setPrompt(value);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeSelect
                        onChange={modelOnChange}
                        value={model}
                        title='Model'
                        toolTipText='Choose model type'
                        bottomMargin
                    >
                        {Object.values(NodeOpenAIDALLEModelValues).map(t => <option value={t}>{t}</option>)}
                    </NodeSelect>
                    <NodeSelect
                        onChange={sizeOnChange}
                        value={size}
                        title='Size'
                        toolTipText='Output data type'
                        bottomMargin
                    >
                        {Object.values(NodeOpenAIDALLESizeValues).map(t => <option value={t}>{t}</option>)}
                    </NodeSelect>
                    <NodeTextArea
                        className={`nodeoaidalle-prompt-input-${id}`}
                        title='Instructions'
                        toolTipText='Instructions for the art you want to generate'
                        validVariables={validVariables}
                        onChange={promptOnChange}
                        value={prompt}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodeoaidalle-prompt-input-${id}`, text: prompt, textOnChangeHandler: promptOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'oai-dalle',
    name: 'OpenAI DALL·E',
    description: `Generate images from text with OpenAI's DALL-E`,
    iconFile: 'node-icon-oai-dalle.svg',
    color: '#06B6D4',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/generative#open-ai-dall-e',
    category: NodeCategory.generative
};

export default NodeOpenAIDALLE;
export { nodeInfo };