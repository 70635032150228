import { Node, Edge, ReactFlowState } from 'reactflow';
import NodeManifest, { NodeInfo, NodeDataModal } from './nodes/NodeManifest';

type setNodesType = (payload: Node<any>[] | ((nodes: Node<any>[]) => Node<any>[])) => void;
export const nodeToErrorFromNode: any = {};

export const getNodeId = (nodes: Node[], type: string): string => {
    let largestCurrentIndex = -1;

    nodes.forEach(node => {
        const separatorIndex = node.id.lastIndexOf('-');
        if (separatorIndex === -1) return;

        const nodeType = node.id.slice(0, separatorIndex);
        const nodeIndex: number = +node.id.slice(separatorIndex + 1);

        if (nodeType === type && nodeIndex > largestCurrentIndex) {
            largestCurrentIndex = nodeIndex;
        }
    });
    return `${type}-${largestCurrentIndex + 1}`;
};

export const getNodeInfo = (type: string): NodeInfo | undefined => {
    for (const nodeInfo of NodeManifest) {
        if (nodeInfo.id === type) return nodeInfo;
    }
    return undefined;
};

export const updateNodeData = (id: string, setNodes: setNodesType, data: Object) => {
    setNodes((nodes) => nodes.map((node) => {
        if (node.id === id) node.data = { ...node.data, ...data };
        return node;
    }));
};

export const updateNode = (id: string, setNodes: setNodesType, data: Object) => {
    setNodes((nodes) =>
        nodes.map(node => {
            if (node.id === id) node = { ...node, ...data };
            return node;
        }));
};

export const setErrorFromNode = (id: string, errorFromNode: WorkflowError[]) => {
    if (errorFromNode.length === 0) delete nodeToErrorFromNode[id];
    else nodeToErrorFromNode[id] = errorFromNode;
};

export const updateNodeDataWithNodes = (id: string, nodes: Node[], setNodes: setNodesType, data: Object) => {
    setNodes(nodes.map((node) => {
        if (node.id === id) node.data = { ...node.data, ...data };
        return node;
    }));
};

export const updateNodeOptions = (id: string, nodes: Node[], setNodes: setNodesType, options: Object) => {
    setNodes(nodes.map((node) => {
        node = { ...node, ...options };
        return node;
    }));
};

export const updateNodesOptions = (nodes: Node[], setNodes: setNodesType, options: Object) => {
    setNodes(Array.from(nodes).map((node) => {
        node = { ...node, ...options };
        return node;
    }));
};

export const unselectAllNodes = (nodes: Node[], setNodes: setNodesType) => {
    setNodes(Array.from(nodes).map((node) => {
        node = { ...node };
        if (node.selected !== undefined) node.selected = false;
        return node;
    }));
};

interface BFSNode {
    id: string;
    data: any;
}

interface BFSEdge {
    source: string;
    target: string;
}

export const findValidVariables = (nodes: BFSNode[], edges: BFSEdge[], startNodeId: string): { [nodeId: string]: string[] } => {
    let layers: string[][] = [];
    let visited: { [nodeId: string]: boolean } = {};
    let edgeMap: { [source: string]: string[] } = {};

    // Build an adjacency list
    for (let edge of edges) {
        if (edgeMap[edge.source] === undefined) {
            edgeMap[edge.source] = [];
        }
        edgeMap[edge.source].push(edge.target);
    }

    // Initialize queue with start node
    let queue: string[] = [startNodeId];
    visited[startNodeId] = true;

    while (queue.length > 0) {
        let layer: string[] = [];
        let nextQueue: string[] = [];

        // Process all nodes in the current queue
        while (queue.length > 0) {
            let nodeId = queue.shift()!;
            layer.push(nodeId);

            // Add neighbors to the next queue
            let neighbors = edgeMap[nodeId] || [];
            for (let neighbor of neighbors) {
                if (!visited[neighbor]) {
                    visited[neighbor] = true;
                    nextQueue.push(neighbor);
                }
            }
        }

        layers.push(layer);
        queue = nextQueue;
    }

    // Generate the output object
    let layerMap: { [nodeId: string]: string[] } = {};
    let previousLayers: string[] = [];

    for (let i = 0; i < layers.length; i++) {
        for (let nodeId of layers[i]) {
            // Assign previous layers node ids
            layerMap[nodeId] = [...previousLayers];
        }
        // Add current layer's node ids to previousLayers
        previousLayers = previousLayers.concat(layers[i]);
    }

    return layerMap;
};

// Error handing

export enum WorkflowErrorType {
    isolatedNode = 'isolatedNode',
    invalidParam = 'invalidParam',
    hasInvalidVariable = 'hasInvalidVariable'
}

export type WorkflowError = {
    type: WorkflowErrorType
    name: string
    description: string
}

export const findIsolatedNodeErrors = (nodes: Node[], edges: Edge[]) => {
    const result: { [key: string]: WorkflowError[]; } = {};

    nodes.forEach(n => {
        // Remove existing isolatedNode errors
        const errorList: WorkflowError[] = [];

        // Append isolatedNode error, if is isolated node
        const isIsolatedNode = !!!edges.find(e => e.target === n.id) && n.type !== 'start';
        if (isIsolatedNode) errorList.push({ type: WorkflowErrorType.isolatedNode, name: 'Isolated Node', description: 'Connect your node to the graph in order to resolve this issue' });

        // Update node
        result[n.id] = errorList;
    });

    return result;
};

export const filterValidVariables = (valieVariables: string[], state: ReactFlowState, acceptModal: NodeDataModal | ''): string[] => {
    const nodes = Array.from(state.nodeInternals.values());
    return valieVariables.filter(v => {
        return nodes.find(n => n.id === v)?.data?.outputModal === acceptModal;
    });
};

const extractQuotedWords = (text: string): string[] => {
    const matches = text.match(/{([^}]*)}/g);
    if (!matches) return [];

    // Extract the actual content from inside the curly brackets.
    return matches.map(match => match.substring(1, match.length - 1));
};

export const hasInvalidVariables = (text: string, validVariables: string[]): boolean => {
    const variables = extractQuotedWords(text);

    for (const variable of variables) {
        if (!validVariables.includes(variable)) return true;
    }

    return false;
};

export const hexToRGBA = (hex: string, alpha: number = 1): string => {
    // Remove the hash symbol if it exists
    hex = hex.replace('#', '');

    // Convert to RGB
    const r: number = parseInt(hex.slice(0, 2), 16);
    const g: number = parseInt(hex.slice(2, 4), 16);
    const b: number = parseInt(hex.slice(4, 6), 16);

    // Return in RGBA format
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const blendWithWhite = (hex: string, alpha: number): string => {
    // Nested function to convert hex to RGB
    const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
        // Remove the hash sign if it exists
        hex = hex.replace('#', '');

        // Convert shorthand hex to full version
        if (hex.length === 3) {
            hex = hex.split('').map(x => x + x).join('');
        }

        const r: number = parseInt(hex.substring(0, 2), 16);
        const g: number = parseInt(hex.substring(2, 4), 16);
        const b: number = parseInt(hex.substring(4, 6), 16);

        return { r, g, b };
    };

    const { r, g, b } = hexToRgb(hex);
    const white: number = 255;

    // Function to blend the color with white
    const blend = (color: number, alpha: number): number => {
        return Math.round((color * alpha) + (white * (1 - alpha)));
    };

    const newR: number = blend(r, alpha);
    const newG: number = blend(g, alpha);
    const newB: number = blend(b, alpha);

    return `rgb(${newR}, ${newG}, ${newB})`;
};
