import React, { Dispatch, SetStateAction, useState } from 'react';
import OrganizationProfileSkeleton from '../../organization/profile/OrganizationProfileSkeleton';
import OrganizationProfile from '../../organization/profile/OrganizationProfile';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchWorkspaceAsync, getSelectedWorkspace} from '../../../features/workspace/workspaceSlice';
import OrganizationTeam from '../../organization/team/OrganizationTeam';
import { Backdrop, CircularProgress } from '@mui/material';
interface OrganizationContentProps {
    isLoading?: boolean;
    setActiveTab?:Dispatch<SetStateAction<any>>;
}
const OrganizationContent: React.FC<OrganizationContentProps> = ({ isLoading, setActiveTab}) => {
    const [showProfile, setShowProfile] = useState(true); 
    const [showLoading, setShowLoading] = useState(false);
    const selectedWorkspace = useAppSelector(getSelectedWorkspace);
    const dispatch = useAppDispatch();

    const handleOrganizationSaved = async () => {
        try {
            dispatch(fetchWorkspaceAsync());
        } catch (e) {
            console.error('Handle bot saved failed', e);
        }
    };
    const getProfile = () => {
        if (isLoading) {
            return <OrganizationProfileSkeleton/>;
        } else {
            return <OrganizationProfile 
                organizationDetail={selectedWorkspace}
                onOrganizationSaved={handleOrganizationSaved}
                setShowLoading={setShowLoading}
            />;
        }
    };
    const getTeam=()=>{
        if (isLoading) {
            return;
        } else {
            return <OrganizationTeam setActiveTab = {setActiveTab}/>;
        }
    };

    return (
        <div className='content'>
            <div>
                <h4 className='py-2'>Organization</h4>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <button className={`btn btn-link text-decoration-none fw-bold mx-2 ${showProfile?'active':''}`} onClick={() => setShowProfile(true)}>
                        Profile
                    </button> 
                    <button className={`btn btn-link text-decoration-none fw-bold mx-2 ${showProfile?'':'active'}`} onClick={() => setShowProfile(false)} >
                        Team
                    </button>  
                </div> 
            </div>
            <hr/>
            <div>
                <div>
                    {showProfile?getProfile():getTeam()}
                </div>
            </div>
        </div>
    );
};

export default OrganizationContent;
