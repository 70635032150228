import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import NodeCategory from '../../botEditor/nodes/NodeCategory';

export interface botEditorState {
    isDraggable: boolean
    successSnackbarMessage: string,
    errorSnackbarMessage: string,
    shouldRecalculateNodesMetaData: number,
    currentlySelectedNode: string,
    selectedCategory?: NodeCategory,
    sidePanelProps?: BotEditorSidePanelProps
}

export type BotEditorSidePanelProps = {
    title: string
    subtitle: string
    panelComponent: JSX.Element
}

const initialState: botEditorState = {
    isDraggable: true,
    successSnackbarMessage: '',
    errorSnackbarMessage: '',
    shouldRecalculateNodesMetaData: 0,
    currentlySelectedNode: '',
    selectedCategory: undefined,
    sidePanelProps: undefined
};

export const botEditorSlice = createSlice({
    name: 'botEditor',
    initialState,
    reducers: {
        setIsDraggable: (state, action: PayloadAction<boolean>) => {
            state.isDraggable = action.payload;
        },
        showSuccessSnackBar: (state, action: PayloadAction<string>) => {
            state.successSnackbarMessage = action.payload;
        },
        hideSuccessSnackBar: (state) => {
            state.successSnackbarMessage = '';
        },
        showErrorSnackBar: (state, action: PayloadAction<string>) => {
            state.errorSnackbarMessage = action.payload;
        },
        hideErrorSnackBar: (state) => {
            state.errorSnackbarMessage = '';
        },
        recalculateNodesMeta: (state) => {
            state.shouldRecalculateNodesMetaData = state.shouldRecalculateNodesMetaData + 1;
        },
        setCurrentlySelectedNode: (state, action: PayloadAction<string>) => {
            state.currentlySelectedNode = action.payload;
        },
        setSelectedCategory: (state, action: PayloadAction<NodeCategory>) => {
            state.selectedCategory = action.payload;
        },
        clearSelectedCategory: (state) => {
            state.selectedCategory = undefined;
        },
        showEditorSidePanel: (state, action: PayloadAction<BotEditorSidePanelProps>) => {
            state.sidePanelProps = action.payload;
        },
        hideEditorSidePanel: (state) => {
            state.sidePanelProps = undefined;
        }
    }
});

export const { setIsDraggable, showSuccessSnackBar, hideSuccessSnackBar, showErrorSnackBar, hideErrorSnackBar, recalculateNodesMeta, setCurrentlySelectedNode, setSelectedCategory, clearSelectedCategory, showEditorSidePanel, hideEditorSidePanel } = botEditorSlice.actions;
export const getIsDraggable = (state: RootState) => state.botEditor.isDraggable;
export const getShouldShowSuccessSnackBar = (state: RootState) => !!state.botEditor.successSnackbarMessage;
export const getShouldShowErrorSnackBar = (state: RootState) => !!state.botEditor.errorSnackbarMessage;
export const getSuccessSnackBarMessage = (state: RootState) => state.botEditor.successSnackbarMessage;
export const getErrorSnackBarMessage = (state: RootState) => state.botEditor.errorSnackbarMessage;
export const getRecalculateNodesMetaTrigger = (state: RootState) => state.botEditor.shouldRecalculateNodesMetaData;
export const getCurrentlySelectedNode = (state: RootState) => state.botEditor.currentlySelectedNode;
export const getSelectedCategory = (state: RootState) => state.botEditor.selectedCategory;
export const getEditorSidePanelProps = (state: RootState) => state.botEditor.sidePanelProps;
export default botEditorSlice.reducer;