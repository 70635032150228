import React, { useState, ChangeEvent } from 'react';
import './CreateWorkspace.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createWorkspace } from '../../services/BSCore';
import { useNavigate } from 'react-router-dom';
import { fetchWorkspaceAsync } from '../../features/workspace/workspaceSlice';
import { useAppDispatch } from '../../app/hooks';

const Dashboard: React.FC = () => {
    const [workspaceName, setWorkspaceName] = useState('');
    const [workspaceDes, setWorkspaceDes] = useState('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const workspaceNameFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setWorkspaceName(event.target.value);
    };

    const workspaceDesFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setWorkspaceDes(event.target.value);
    };

    const handleCreateWorkspace = async () => {
        try {
            await createWorkspace(workspaceName, workspaceDes);
            dispatch(fetchWorkspaceAsync());
            navigate('/?tutorial=true');
        } catch (e) {
            console.error('Failed to create workspace', e);
        }
    };

    return (
        <div className='newworkspace-form-container'>
            <div className='newworkspace-form-wrapper'>
                <h1>Welcome to BotSquare</h1>
                <h4>Let's get started by creating your first workspace</h4>
                <TextField
                    value={workspaceName}
                    className='newworkspace-input'
                    label='Workspace name'
                    multiline
                    onChange={workspaceNameFieldOnChange}
                />
                <TextField
                    value={workspaceDes}
                    className='newworkspace-input'
                    label='Workspace description'
                    multiline
                    rows={3}
                    onChange={workspaceDesFieldOnChange}
                />
                <Button variant='contained' onClick={handleCreateWorkspace} disabled={!workspaceName || !workspaceDes}>Create</Button>
            </div>
        </div>
    );
};

export default Dashboard;