
import React from 'react';
import './SubscriptionChoiceCard.scss';

interface Props {
    current: boolean;
    title: string;
    description: string;
    price?: string;
    middle?: boolean;
    features: string[];
    actionTitle: string;
    action: () => void;
}

const SubscriptionChoiceCard: React.FC<Props> = ({ current, title, description, price, middle, features, action, actionTitle }) => {
    return (
        <div className={`subscription-content-container ${middle ? 'subscription-choice-middle' : 'subscription-choice-side'} ${current ? 'subscription-choice-selected' : 'subscription-choice-unselected'} `}>
            <div className='subscription-content'>
                <div className='subscription-choice-title'>
                    <h1>{title}</h1>
                    {middle &&
                        <div className='most-popular'>
                            Most Popular
                        </div>
                    }
                </div>

                <h2>{description}</h2>

                {price!! && <h3 className={`${current ? 'extra-space' : ''}`}>${price}</h3>}

                {/* {current === false &&
                    <div className='payment-toggle-container'>
                        <button className={`payment-toggle ${priceToggle ? 'on' : 'off'}` } onClick={ togglePrice } >
                            <div className='payment-toggle-inner'></div>
                        </button>
                        <h1 className={`${priceToggle ? 'dimmed' : 'highlighted'}`}>Monthly</h1>
                        <h1>&nbsp;|&nbsp;</h1>
                        <h1 className={`${priceToggle ? 'highlighted' : 'dimmed'}`}>Annually</h1>
                    </div>
                } */}
                <button onClick={action} className={`subscription-detail-button ${current ? 'non-trial' : 'trial'}`}>
                    {current ?
                        <>
                            <i className='bi bi-check-circle' />
                            Current Plan
                        </> :
                        <>
                            {actionTitle}
                        </>
                    }
                </button>
                {
                    features.map(feature =>
                        <div className='subscription-feature'>
                            <i className='bi bi-check-lg' />
                            <h4>{feature}</h4>
                        </div>
                    )
                }

            </div>
            {current &&
                <button className='subscription-cancel-button'>Cancel my plan</button>
            }
        </div>
    );
};

export default SubscriptionChoiceCard;