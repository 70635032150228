import React, { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { uploadIconUrl, Workspace, editWorkspace } from '../../../services/BSCore';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Collapse from '@mui/material/Collapse';
import ImageCropper from '../../imageCrop/ImageCrop';
import { Area } from 'react-easy-crop';
import CircularProgress from '@mui/material/CircularProgress';
import './OrganizationProfile.scss';
import BSToolTip from '../../toolTip/TooTip';

interface Props {
    organizationDetail?: Workspace | null;
    onOrganizationSaved: () => Promise<void>;
    setShowLoading: Dispatch<SetStateAction<boolean>>;
}

const OrganizationProfile: React.FC<Props> = ({ organizationDetail,onOrganizationSaved, setShowLoading }) => {
    const [profileImage, setProfileImage] = useState('');
    const [projectName, setProjectName] = useState('');
    const [description, setDescription] = useState('');
    const [unsaveData, setUnsaveData] = useState(false);
    const [image, setImage] = useState<File>();
    const [imageURL, setImageURL] = useState('');
    const [showCrop, setShowCrop] = useState(false);
    const imageRef = useRef<File>();
    imageRef.current = image;
    
    useEffect(() => {
        if (organizationDetail) {
            console.log('here is a compare',organizationDetail, profileImage);
            setProfileImage(organizationDetail.info?.imageUrl);
            setProjectName(organizationDetail.name);
            setDescription(organizationDetail.description || '');
        }
        return () => {
            setUnsaveData(false);
        };
    }, [organizationDetail]);

    const [imageUploading, setImageUploading] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showImageUploadSuccessAlert, setShowImageUploadSuccessAlert] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const reader = new FileReader();
        const file = event.target.files?.[0];

        if (file) {
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                if (reader.result) {
                    setImageURL(reader.result.toString());
                    setShowCrop(true);
                }
            };
        }
    };

    const onCropDone = async (croppedArea?: Area) => {
        if (croppedArea) {
            const canvasEle = document.createElement('canvas');
            canvasEle.width = croppedArea.width;
            canvasEle.height = croppedArea.height;

            console.log('area', croppedArea);
            const context = canvasEle.getContext('2d');

            let imageObj1 = new Image();
            imageObj1.src = imageURL;
            imageObj1.onload = async () => {
                if (context) {
                    context.drawImage(
                        imageObj1,
                        croppedArea.x,
                        croppedArea.y,
                        croppedArea.width,
                        croppedArea.height,
                        0,
                        0,
                        croppedArea.width,
                        croppedArea.height
                    );

                    const dataURL = canvasEle.toDataURL('image/jpeg');
                    const response = await fetch(dataURL);
                    const blob = await response.blob();
                    const file = new File([blob], 'image');

                    setImage(file);
                    setShowCrop(false);
                    setImageUploading(true);
                    await uploadImage();
                }
            };
        }
    };

    const uploadImage = async () => {
        let counter = 0;

        while(!imageRef.current) {
            if (counter > 10) {
                setShowCrop(false);
                setImage(undefined);
                setImageUploading(false);
                await delay(200);
                alert('Something went wrong, please try again later');
                return;
            }

            await delay(2000);
            counter += 1;
            console.log('counter', counter);
        }

        if (imageRef.current) {
            try {
                setImageUploading(true);
                const imageUrl = await uploadIconUrl(imageRef.current);
                await editWorkspace(organizationDetail?.id || 0, 
                    {
                        info:{
                            imageUrl:imageUrl.url
                        },
                        name: organizationDetail?.name || '',
                 });
                setShowImageUploadSuccessAlert(true);
                setImage(undefined);
                setImageUploading(false);
                setProfileImage(imageUrl.url);
                await onOrganizationSaved();
                setTimeout(() => {
                    setShowImageUploadSuccessAlert(false);
                }, 5000);
            } catch (error) {
                setImageUploading(false);
                console.error('Error uploading image:', error);
            }
        }
    };

    const onCropCancel = () => {
        setShowCrop(false);
        setImage(undefined);
        setImageURL('');
    };

    const handleCancel = () => {
        setProjectName(organizationDetail?.name || '');
        setUnsaveData(false);
    };

    const handleSave = async () => {
        try {
            setShowLoading(true);
            const updatedOrganizationDetail = {
                info:{
                    imageUrl:profileImage
                },
                name: projectName,
                description: description,
            };

            await editWorkspace(organizationDetail?.id || 0, updatedOrganizationDetail);
            setShowSuccessAlert(true);
            await onOrganizationSaved();
            setTimeout(() => {
                setShowSuccessAlert(false);
            }, 5000);
            setShowLoading(false);
        } catch (error) {
            console.error(error);
            setShowLoading(false);
        }
        setUnsaveData(false);
    };

    return (
        <div className='py-4 container-fluid'>
            <Collapse in={showSuccessAlert}>
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                    <i className='bi bi-check-circle pe-2'></i>
                    <div>
                        Organization information saved successfully.
                    </div>
                </div>
            </Collapse>
            <Collapse in={showImageUploadSuccessAlert}>
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                    <i className='bi bi-check-circle pe-2'></i>
                    <div>
                        Upload successful.
                    </div>
                </div>
            </Collapse>
            <Collapse in={unsaveData}>
                <div className='alert alert-warning d-flex align-items-center' role='alert'>
                    <i className='bi bi-exclamation-circle pe-2'></i>
                    <div>
                        You have unsaved changes. Please save or cancel.
                    </div>
                </div>
            </Collapse>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <div>
                        <span className='text-default'>Workspace ID#: </span>
                        <span className='text-main'>{organizationDetail?.id}</span>
                    </div>
                    <div className='py-2 text-default'>Please edit and update the profile about your workspace from here.</div>
                    <div className='text-grey02'>*Required</div>
                    <div className='py-4 d-flex align-items-center'>
                        <div className='bot-image-upload-spinner-container'>
                            <img style={{ objectFit: 'cover', borderRadius: '20%', position: 'relative' }} src={profileImage} width={'100px'} height={'100px'} alt='Avatar' />

                            {imageUploading &&
                            <div className='bot-image-upload-spinner' >
                                <CircularProgress color='inherit' />
                            </div>
                        }
                        </div>
                        <div>
                            <BSToolTip marginTop={1} title={`Setup the app's avatar`}>
                                <button className='btn btn-link text-primary' onClick={() => fileInputRef.current?.click()}>
                                    Upload workspace avatar
                                </button>
                            </BSToolTip>
                            <input
                            type='file'
                            accept='image/*'
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                            <div className='text-secondary ps-3'>
                                Format JPG, PNG or PDF; Less then 2MB
                            </div>
                        </div>
                    </div>
                
                    <div className='py-3 text-main'>
                        <BSToolTip marginTop={6} title={'This name will also be displayed as the app name to end users.'}>
                            <h6 className='organization-profile-work-space-name'>Workspace name*</h6>
                        </BSToolTip>
                        <div className='input-group'>
                            <input
                            type='text'
                            className='form-control rounded text-default'
                            placeholder='Input field'
                            value={projectName}
                            maxLength={16}
                            onChange={(event) => {
                                const inputText = event.target.value;
                                if (inputText.length <= 16) {
                                    setUnsaveData(true);
                                    setProjectName(inputText);
                                }
                            }}
                        />
                            {projectName.length === 16 && (
                            <p className='text-danger mt-1'>
                                You have reached 16 characters maximum
                            </p>
                        )}
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-8'>
                    <div className='py-3'>
                        <BSToolTip marginTop={8} title={'Details visible to workspace members only.'}>
                            <h6 className='text-main organization-profile-description-name'>Description</h6>
                        </BSToolTip>
                        <div className='input-group'>
                            <TextareaAutosize
                            className='form-control rounded text-default'
                            placeholder='Input field'
                            minRows={3}
                            value={description}
                            onChange={(event) => {
                                setUnsaveData(true);
                                setDescription(event.target.value);
                            }}
                        />
                        </div>
                    </div>
                    <div className='row justify-content-end py-4'>
                        <button className='col-md-1 btn btn-link text-main' onClick={handleCancel}>
                            Cancel
                        </button>
                        <button className='col-md-1 btn btn-primary' onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
            
            {showCrop &&
                <ImageCropper image={imageURL} onCropDone={onCropDone} onCropCancel={onCropCancel} />
            }
        </div>
    );
};

export default OrganizationProfile;
