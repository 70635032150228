import React, { useState } from 'react';
import './DataSourcesContent.scss';
import Skeleton from '@mui/material/Skeleton';
import { DataSource, deleteDataSource, fetchDataSources } from '../../../services/BSCore';
import DataSourceForm from '../../dialog/DataSourceForm';
import DataSourceCard from '../../cards/home/dataSourceCard/DataSourceCard';
import DeleteModel from '../../models/DeleteModel';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchWorkspaceAsync, getSelectedWorkspace } from '../../../features/workspace/workspaceSlice';
import DataSourceEmptyCard from '../../cards/home/dataSourceCard/DataSrouceEmptyCard';

interface DataSourceContentProps {
    datasources?: DataSource[]| undefined | null;
    isLoading?: boolean;
}

const DataSourceContent: React.FC<DataSourceContentProps> = ({datasources, isLoading}) => {
    const [showDataSourceForm, setShowDataSourceForm] = useState(false);
    const [deleteDS, setDeleteDS] = useState<DataSource>();
    const [showConfirmation, setShowConfirmation] = useState(false);


    const renderCard = (item: DataSource, index: number) => {
        // Adjust this code to render your datasource cards
        const key = `card-${index}`;
        return <div className='col-12 col-md-4 col-lg-3'><DataSourceCard key = {key} dataSource={item} onDeleteClick = {onDeleteCardClick}/></div>;
    };
    
    const getDataSourceCards = () => {
        const skeletonHeight = '224px';

        if (isLoading) {
            return (
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)', // Adjust as needed
                        gap: '16px',
                        justifyContent: 'center',
                    }}
                >
                    {/* Skeleton loading cards */}
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Skeleton
                            key={`skeleton-${index}`}
                            variant='rectangular'
                            animation='wave'
                            style={{
                                borderRadius: '8px',
                                width: '336px',
                                height: skeletonHeight,
                            }}
                        />
                    ))}
                </div>
            );
        } else {
            // Render your data source cards based on the fetched data
            return (
                <>
                    {datasources?.length === 0
                        ?
                            <div className='col-4'>
                                <DataSourceEmptyCard setShowDataSourceForm = {setShowDataSourceForm}/>
                            </div>
                        :
                            <>
                                {datasources?.map((item, index) => renderCard(item, index))}
                            </>
                }
                </>
            );
           
        }
    };
    const workspace = useAppSelector(getSelectedWorkspace);
    const dispatch = useAppDispatch();

    const onDeleteCardClick = (dataSource:DataSource)=>{
        setShowConfirmation(true);
        setDeleteDS(dataSource);
    };

    const handleDelete = async () => {
        if (deleteDS?.id && workspace) {
            await deleteDataSource(deleteDS?.id?.toString());
            dispatch(fetchWorkspaceAsync());
            await fetchDataSources(workspace.id);
            setShowConfirmation(false);
        }
    };

    return (
        <div className='content'>
            <h4>Data Source</h4>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='text-grey m-0'>Continue the project you left off, or build something new.</p>
                {datasources?.length !== 0&&
                <div>
                    <button className='btn btn-outline-main mx-2' onClick={() => setShowDataSourceForm (true)} >
                        <i className='bi bi-plus-lg px-2'></i>
                        Add data source
                    </button>  
                </div> 
                }
            </div>
            
            {datasources?
                <div className='project row'>
                    {getDataSourceCards()}
                    
                </div>
                :
                <div>
                    <div className='fs-20 empty-color'>
                        <i className='bi bi-database'></i>
                        Please add data source from here. And your all data sources will display here
                    </div>
                </div>
            }       
            
            <DataSourceForm isOpen={showDataSourceForm} onClose={() => setShowDataSourceForm(false)} />
            <DeleteModel
                showDeleteModal={showConfirmation}
                title='Delete Data Source?'
                content={`All the data associated with this data source will be erased forever if you delete it. Are you sure you want to delete data source ${deleteDS?.name}?`}
                onClose={() => setShowConfirmation(false)}
                handleDelete={handleDelete}
            />
        </div>
    );
};

export default DataSourceContent;
