import React, {  useEffect, useState } from 'react';
import './Home.scss';
import HomeContent from '../../components/homePageContent/homeContent/HomeContent';
import NavbarComponent from '../../components/shared/main/Navbar/NavbarComponent';
import LeftPanelComponent from '../../components/shared/main/LeftPanel/LeftPanelComponent';
import HomepageFootnote from '../../components/homepageFootnote/HomepageFootnote';
import MyProjectsContent from '../../components/homePageContent/myProjectsContent/MyProjectsContent';
import DataSourcesContent from '../../components/homePageContent/dataSourcesContent/DataSourcesContent';
import { Box, Fab, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearData, fetchWorkspaceAsync, getBots, getDatasources, getSelectedWorkspace, getStatus } from '../../features/workspace/workspaceSlice';
import OrganizationContent from '../../components/homePageContent/organizationContent/OrganizationContent';
import { fetchMemberData, createMember, fetchTemplates } from '../../services/BSCore';
import authService from '../../services/AuthService';
import { getTemplates, setTemplate } from '../../features/template/templateSlice';
import TemplatesPage from '../templates/Templates';


const Home: React.FC= () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(true);

    const [activeTab, setActiveTab] = useState('home');
    const [showTutorial, setShowTutorial] = useState(false);

    const workspaceId = useAppSelector(getSelectedWorkspace)?.id;
    const bots = useAppSelector(getBots);
    const workspaceDataStatus = useAppSelector(getStatus);
    const datasources = useAppSelector(getDatasources);
    const templates = useAppSelector(getTemplates);


    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then( async result => {
                if (result) {
                    console.log('authenticated');

                    // Create member if necessary
                    try {
                        console.log('fetching member data');
                        const data = await fetchMemberData();
                        console.log('Fetched member data', data);
                    } catch (error) {
                        console.error(error);
                        console.log('member doesn\'t exist, create new member');
                        const { data } = await createMember().catch(async e => {
                            console.error('Failed to create member', e);
                            navigate('/auth');

                            const user = await authService.getCurrentUser().catch(() => {});
                            if (user) alert('This email has been used to sign in with another method. Please sign in with your original method.');

                            authService.signOut();
                            return;
                        });
                        console.log('created member', data);
                    }

                    dispatch(fetchWorkspaceAsync());
                } else {
                    console.log('not authenticated');
                    navigate('/auth');
                }
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
                navigate('/auth');
            });

            fetchTemplates()
                .then(templates =>{
                    dispatch(setTemplate(templates));
                }
                )
                .catch(error => {
                    console.error('Failed to fetch templates', error);
                });

            const tutorialParam = new URLSearchParams(window.location.search).get('tutorial');
            const isTutorialTrue = tutorialParam === 'true';
            setShowTutorial(isTutorialTrue);
    }, []);

    // After data is loaded
    useEffect(() => {
        if (workspaceDataStatus === 'succeed') {
            setIsLoading(false); // Hide skeletons once data is loaded
            
            if (!workspaceId) {
                dispatch(clearData());
                navigate('/workspace/new');
            }
        }
        console.log('here is the template',templates);
    }, [workspaceDataStatus]);

    const TutorialModal= () => {
        return (
            <Modal
                open={showTutorial}
                onClose={()=>{}}
            >
                <Box className='dashboard-tutorial-modal-box'>
                    <div className='dashboard-tutorial-modal-content'>
                        <div className='dashboard-tutorial-modal-heading'>
                            <Typography id='dashboard-tutorial-modal-title' variant='h6' component='h2'>
                                Welcome! This tutorial can help you get started!
                            </Typography>
                            <CloseIcon fontSize='large' className='dashboard-tutorial-modal-close-icon' onClick={()=>{setShowTutorial(false);navigate('/');}}></CloseIcon>
                        </div>
                        <iframe
                            className='dashboard-tutorial-video-container'
                            title='BotSquare Tutorial Video'
                            src='https://www.youtube.com/embed/vNs_oUMY2rI'
                            allowFullScreen
                            loading='lazy'
                        ></iframe>
                    </div>
                   
                </Box>
                
            </Modal>
        );
      };

    return (
        <div className='main'>
            <NavbarComponent/>
            <div className='d-flex'>
                <div className='side-bar'>
                    <LeftPanelComponent activeTab={activeTab} setActiveTab={setActiveTab}></LeftPanelComponent>
                </div>
                <div>
                    <div className='main-content'>
                        {activeTab==='home'&&
                        <HomeContent bots = {bots} isLoading={isLoading} templates = {templates} setActiveTab={setActiveTab}/>}
                        {activeTab==='my-project'&&
                        <MyProjectsContent bots={bots} isLoading={isLoading}/>
                        }
                        {activeTab==='data-source'&&
                        <DataSourcesContent datasources = {datasources} isLoading={isLoading}/>
                        }
                        {activeTab==='templates'&&
                        <TemplatesPage templates = {templates}/>
                        }
                        {activeTab==='organization'&&
                        <OrganizationContent isLoading={isLoading} setActiveTab = {setActiveTab}/>
                        }
                    </div>
                    <Fab className='dashboard-tutorial' variant='extended' size='medium' onClick={() => { setShowTutorial(true); }}>
                        <HelpOutlineIcon sx={{ mr: 1 }} />
                        Watch the Tutorial
                    </Fab>
                    < TutorialModal/>
                    <HomepageFootnote/>
                </div>
            </div>
        </div>
    );
};
export default Home;