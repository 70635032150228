import React, { useEffect, useState } from 'react';
import BSLogo from '../icons/BSLogo';
import './BSNav.scss';
import { Auth } from 'aws-amplify';

import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import Divider from '../divider/Divider';
import { fetchMemberData, MemberObject } from '../../services/BSCore';
import { useDispatch } from 'react-redux';
import { clearData } from '../../features/workspace/workspaceSlice';

interface TypeProps {
    type?: NavType;
}

export enum NavType {
    standard, white
}

export type User = {
    id: string,
    username: string,
    attributes: {
        name: string
    }
};

const BSNav: React.FC<TypeProps> = ({ type }) => {
    // const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [avatar, setAvatar] = useState('');

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(result => {
                if (result) {
                    fetchUser().catch(e => console.log('Fetch user error from nav', e));
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const fetchUser = async () => {
        const user = await fetchMemberData() as MemberObject;
        console.log(user);
        setName(user.name);
        setAvatar(user.info.imageUrl);
    };

    const logout = async () => {
        await AuthService.signOut();
        dispatch(clearData());
        console.log('Logged out');
        navigate('/auth');
    };

    return (
        <div className={`${type === NavType.standard ? '' : 'bs-nav-bg'}`}>
            <div className={`bs-nav-overall-container ${ type === NavType.standard ? 'with-out-logo' : 'with-logo container' }`}>
                { type !== NavType.standard &&
                    <div className='bs-nav'>
                        <div className='bs-nav-container'>
                            <a href='/'>
                                <BSLogo className='bs-nav-logo' />
                            </a>
                        </div>
                    </div>
                }
                <div className='bs-nav-dropdown-menu-container'>
                    <div>
                        <div className={`bs-nav-profile-container`}>
                            <div className='bs-nav-user text-main'>{`Hello! ${name}`}</div>
                            {avatar && <img src={avatar} alt='UserAvatar' />}
                        </div>
                        <div className='bs-nav-dropdown-content'>
                            <button onClick={() => navigate('/profile')}>
                                <i className='bi bi-person-circle' />
                                <h1>Profile</h1>
                            </button>
                            {/* <button onClick={() => navigate('/profile/credits')}>
                                <i className='bi bi-currency-dollar' />
                                <h1>My Plan</h1>
                            </button> */}
                            {/* <button onClick={() => console.log('Settings')}>
                                <i className='bi bi-gear' />
                                <h1>Settings</h1>
                            </button> */}
                            <Divider children={''} />
                            <button onClick={logout}>
                                <i className='bi bi-box-arrow-right' />
                                <h1>Logout</h1>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BSNav;