import React, { useState, useRef } from 'react';
import './FileUploader.scss';

interface Props {
    file: File | null,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    uploadFile: (f: File) => Promise<any>,
    onFileDelete: () => void
}

const FileUploader: React.FC<Props> = ({ file, setFile, uploadFile, onFileDelete }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOver(false);
        const files = event.dataTransfer.files;
        if (files.length) {
            setFile(files[0]);
            uploadFile(files[0]);
        }
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length) {
            setFile(files[0]);
            uploadFile(files[0]);
        }
    };

    return (
        <div className='file-uploader-container'>
            {
                file ?
                    <div className='file-uploader-file-list'>
                        <p>{file.name}</p>
                        <button onClick={() => {setFile(null); onFileDelete();}}>
                            <i className='bi bi-x'></i>
                        </button>
                    </div>
                    :
                    <>
                        <div
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onClick={handleClick}
                            onDragLeave={handleDragLeave}
                            className={`file-uploader-dropzone ${isDraggingOver ? 'file-uploader-dropzone-dragging-over' : ''}`}
                        >
                            <i className='bi bi-cloud-upload' />
                            {
                                isDraggingOver ? <p>Drop here</p>
                                : <p>Click or drag file here</p>
                            }
                            
                        </div>
                        <input
                            ref={fileInputRef}
                            type='file'
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </>
            }

        </div>
    );
};

export default FileUploader;