import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteDataSourceSRecord, DSString } from '../../../services/BSCore';
import './DataSourceStringCard.scss';
import Backdrop from '@mui/material/Backdrop';
import { Checkbox, CircularProgress } from '@mui/material';

interface Props {
    record: DSString;
    isSelected: boolean | undefined;
    refetch: () => Promise<void>;
    select: (string: string) => void;
    unselect: (string: string) => void;
}

const DSYoutubeStringCard: React.FC<Props> = ({ record, isSelected, refetch, select, unselect }) => {
    const { ds_id } = useParams();
    const [showLoading, setShowLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    const copySummary = async () => {
        if (record.payload.summary) {
            navigator.clipboard.writeText(record.payload.summary);
            setCopied(true);
            await delay(3000);
            setCopied(false);
        }
    };

    const deleteString = async () => {
        if (ds_id) {
            setShowLoading(true);

            const result = await deleteDataSourceSRecord(ds_id, record.id);
            console.log('deleted', result);
            await refetch();

            unselect(record.id);

            setShowLoading(false);
        }
    };

    const handleSelect = () => {
        if (!isSelected) {
            select(record.id);
        } else {
            unselect(record.id);
        }
    }; 

    return (
        <div className={`ds-sc-container ${isSelected ? 'selected-border' : 'unselected-border'}`}>
            <div className='ds-sc-top'>
                <div className='ds-sc-top-info'>
                    <Checkbox 
                        checked={ isSelected } 
                        onChange={ handleSelect }
                        sx={{
                            color: '#8390BA',
                            '&.Mui-checked': {
                              color: '#3C49CB',
                            },
                          }}
                    />
                    <h1>String:&nbsp;</h1>
                    <h2>{ record.payload.sentence }</h2>
                </div>
                { !showDelete ?
                    <div className='ds-sc-top-buttons'>
                        { copied &&
                            <div>Copied</div>
                        }
                        <button onClick={ copySummary }>
                            <i className='bi bi-file-earmark' />
                            <i className='bi bi-file-earmark-fill' />
                        </button>
                        <button onClick={ () => setShowDelete(true) } >
                            <i className='bi bi-trash' />
                            <i className='bi bi-trash-fill' />
                        </button>
                    </div> :
                    <>
                        { showDelete &&
                            <div className='ds-sc-top-deletes'>
                                <button onClick={ () => setShowDelete(false) } >Cancel</button>
                                <button className='ds-sc-top-delete-btn' onClick={ deleteString }>Delete</button>
                            </div>
                        }
                    </>
                }
            </div>
            <div className='ds-yt-bottom' onClick={ handleSelect }>
                <div>Title:</div>
                <div>{record.payload.title}</div>
                <div> - </div>
                <div>Summary:</div>
                <div>{record.payload.summary}</div>
                <div> - </div>
                <div>URL:</div>
                <div>{record.payload.url}</div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </div>
    );
};

export default DSYoutubeStringCard;