import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeBlandCall.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { setIsDraggable } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import { NodeDataModal } from '../NodeManifest';

import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';

const NodeCallDefaults = {
    phoneNumber: ''
};

const NodeBlandCall: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [content, setContent] = useState('');
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const [phoneNumber, setPhoneNumber] = React.useState<string>('+1 (000) 000-0000');
    const acceptModal = NodeDataModal.text;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            content: data?.content || '',
            phoneNumber: data?.phoneNumber || NodeCallDefaults.phoneNumber,
            outputModal: NodeDataModal.text
        });

        // Init UI
        setContent(data?.content || '');
        setPhoneNumber(data?.phoneNumber || NodeCallDefaults.phoneNumber);
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setContent(data?.content || '');
        setPhoneNumber(data?.phoneNumber || NodeCallDefaults.phoneNumber);

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.content || data?.phoneNumber?.length === 0) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.content || '', validVariables) || hasInvalidVariables(data?.phoneNumber || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.content, data.phoneNumber, validVariables]);

    const callContentOnChangeTextArea = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { content: value });

        // Update UI
        setContent(value);
    };

    const phoneNumberOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { phoneNumber: value });

        // Update UI
        setPhoneNumber(value);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeTextArea
                        className={`nodeblandcall-number-input-${id}`}
                        title='Phone number'
                        toolTipText='Your phone number for us to call'
                        validVariables={validVariables}
                        oneLine
                        onChange={phoneNumberOnChange}
                        value={phoneNumber}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeTextArea
                        className={`nodeblandcall-data-input-${id}`}
                        title='Content'
                        toolTipText='The content our AI should call about'
                        validVariables={validVariables}
                        onChange={callContentOnChangeTextArea}
                        value={content}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodeblandcall-number-input-${id}`, text: phoneNumber, textOnChangeHandler: phoneNumberOnChange }, { inputElementClass: `nodeblandcall-data-input-${id}`, text: content, textOnChangeHandler: callContentOnChangeTextArea }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'bl-c',
    name: 'Bland Call',
    description: 'Connects users with AI for voice conversations',
    iconFile: 'node-icon-bl-c.svg',
    color: '#F1662C',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/output',
    category: NodeCategory.output
};

export default NodeBlandCall;
export { nodeInfo };